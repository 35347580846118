import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export type TravelFranceModalType = 'TRAVEL_FR_SUBMIT_CLAIM';

export const TravelHealthFranceSubmitClaimModal = (
  props: PolicySingleModalProps
) => {
  const {
    onClose,
    policyData: {
      attributes: { startDate, policyNumber },
    },
  } = props;
  const account = useSelector(getAccountInfo);

  const URLparams = {
    policyNumber,
    startDate,
    email: account?.email,
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="nGlGaO"
      title="Travel France Claim submission"
      URLparams={URLparams}
    />
  );
};
