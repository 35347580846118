import { TFunction } from '@getpopsure/i18n-react';
import { Region } from '@getpopsure/public-models';
import { toast } from '@popsure/dirty-swan';
import { captureException } from '@sentry/browser';
import { Stripe } from '@stripe/stripe-js';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import {
  PaymentMethodsAction,
  RequestAction,
  UserAction,
} from 'constants/actions';
import routes from 'constants/routes';
import { CheckoutDispatch } from 'features/checkout/actions';
import { mapVerticalId } from 'features/checkout/models';
import { redirectSuccessfulCheckout } from 'features/checkout/utils';
import { History } from 'history';
import { InsuranceTypes } from 'models/insurances/types';
import { PaymentMethod } from 'models/paymentMethods';
import { AppState } from 'reducers';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import api from 'shared/api';
import { trackConversions } from 'shared/trackers';

import { PaymentScreenAction } from './actions';
import { confirmCheckout, getCheckoutInfo, processCheckout } from './api';
import { Checkout, CheckoutInfo, CheckoutResult } from './paymentScreen.models';

export type PaymentScreenThunkDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  PaymentScreenAction | RequestAction | UserAction | PaymentMethodsAction
>;

export const pay =
  ({
    checkoutInfo,
    paymentMethod,
    stripeClient,
    region,
    referralCode,
    configurationQueryParam,
  }: {
    checkoutInfo: CheckoutInfo;
    paymentMethod: PaymentMethod;
    stripeClient: Stripe;
    region?: string;
    referralCode?: string;
    configurationQueryParam?: string;
  }): ThunkAction<
    Promise<CheckoutResult>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction | RequestAction
  > =>
  async () => {
    const { id: paymentMethodId } = paymentMethod;
    const { id: checkoutId } = checkoutInfo;

    const { data: processResponse } = await processCheckout(
      api.network,
      checkoutId,
      {
        paymentMethodId,
        localeId: region,
        referralCode,
        configurationQueryParam,
      }
    );

    const { customerActionRequired, clientSecret, confirmationRequired } =
      processResponse;

    if (customerActionRequired) {
      // TODO: the next line assumes there's only one type of action that
      //       can ever be required. This might change when we add more
      //       payment method types, at that point this code needs to be revisited.
      const { error } = await stripeClient.handleCardAction(clientSecret);

      if (error) {
        throw error;
      }
    }

    if (customerActionRequired || confirmationRequired) {
      const { data: confirmResponse } = await confirmCheckout(
        api.network,
        checkoutId,
        {
          paymentMethodId,
          referralCode,
        }
      );

      return confirmResponse;
    }

    return processResponse;
  };

export const getCheckout =
  ({
    checkoutId,
  }: {
    checkoutId: string;
  }): ThunkAction<
    Promise<Checkout>,
    AppState,
    Record<string, unknown>,
    RequestAction
  > =>
  async () => {
    const { data: checkoutInfo } = await getCheckoutInfo(
      api.network,
      checkoutId
    );

    return checkoutInfo;
  };

export const onSuccessfulPayment =
  ({
    history,
    t,
    isDuplicatePaymentAttempt,
    policyId,
    verticalId,
    region,
    redirectAddDependent,
    genericQuestionnaireKey,
  }: {
    history: History;
    t: TFunction;
    policyId: string;
    verticalId: InsuranceTypes;
    region?: string;
    isDuplicatePaymentAttempt: boolean;
    redirectAddDependent?: boolean;
    genericQuestionnaireKey?: keyof GenericQuestionnaireState;
  }): ThunkAction<
    Promise<void>,
    AppState,
    Record<string, unknown>,
    PaymentScreenAction
  > =>
  async (dispatch: CheckoutDispatch) => {
    const redirectToAllPoliciesPage = () => {
      history.replace(routes.me.policies.path);
    };

    if (isDuplicatePaymentAttempt) {
      captureException(
        '[Generic checkouts] Customer sent a duplicate payment request (see details in extra data)',
        {
          level: 'fatal',
          extra: { policyId, verticalId },
          tags: {
            priority: 'investigate-immediately',
            feature: 'PAYMENT_SCREEN',
          },
        }
      );

      // TODO: make sure this doesn't break conversion tracking in case of a concurrent
      //       request.
      // TODO: Confirm the messaging with Andrey and Marius.
      toast(
        t(
          'paymentScreen.errors.duplicatePolicy',
          "It looks like you've already completed this payment earlier"
        ),
        {
          type: 'error',
          description: t(
            'paymentScreen.errors.duplicatePolicyDescription',
            'Please, reach out to customer support if you cannot access your policy.'
          ),
          duration: 5000,
        }
      );

      return redirectToAllPoliciesPage();
    }

    try {
      trackConversions({
        verticalId,
        policyId,
        regionOfPurchase: region as Region,
      });

      const redirectPolicyId = redirectAddDependent ? policyId : undefined;

      const verticalStateId =
        genericQuestionnaireKey ?? mapVerticalId[verticalId];

      if (verticalStateId) {
        dispatch(flushGenericQuestionnaire(verticalStateId));
      }
      redirectSuccessfulCheckout(
        verticalStateId ?? undefined,
        redirectPolicyId
      );
    } catch (error: unknown) {
      // TODO: this request should not happen in the frontend, we will move it out
      //       as soon as the policy creation logic will move to the backend.
      //
      //       For now, in case of an error we redirect the customer to the "all policies" page
      //       with a toast, so if you see this error, we need to create a policy manually
      //       and reach out to the customer.
      captureException(error, {
        level: 'fatal',
        extra: { verticalId, policyId },
        tags: {
          priority: 'investigate-immediately',
          feature: 'PAYMENT_SCREEN',
        },
      });

      toast(
        t(
          'paymentScreen.errors.errorCreatingPolicy',
          'We have processed your payment successfully'
        ),
        {
          type: 'error',
          description: t(
            'paymentScreen.errors.errorCreatingPolicyDescription',
            'We have processed your payment successfully, however there was an error and we are looking into it. Please, reach out to customer support if you cannot access the policy in the next few hours'
          ),
        }
      );

      return redirectToAllPoliciesPage();
    }
  };
