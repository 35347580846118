import { TFunction } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';

import { Pension } from './models';

export type QuestionLookupOptions = {
  question: string;
  description?: string;
  placeholder?: string;
  placeholderPerUnit?: [string, string];
  additionalInfo?: {
    title: string;
    description: string;
  };
  mapValue?: Record<string, string>;
  option?: Record<string, unknown>;
};

export const questionPropLookup = (
  t: TFunction
): Record<keyof Pension, QuestionLookupOptions> => ({
  quote: { question: '' },
  intro: { question: '' },
  investmentSustainability: {
    question: t(
      'pension.qnr.investmentSustainability.title',
      'How sustainable is your investment approach?'
    ),
    additionalInfo: {
      title: t(
        'pension.qnr.investmentSustainability.additionalInfo.title',
        'What are the ESG best practices?'
      ),
      description: t(
        'pension.qnr.investmentSustainability.additionalInfo.description',
        'ESG stands for environmental, social, and governance. We use these practices to guide your investment strategy, evaluating companies on their environmental, social, and governance responsibility to determine their suitability for investment.'
      ),
    },
  },

  investmentStrategy: {
    question: t(
      'pension.qnr.investmentStrategy.title',
      'Which investment strategy would you like to choose?'
    ),
    additionalInfo: {
      title: t(
        'pension.qnr.investmentStrategy.additionalInfo.title',
        'Can I change my investment strategy later?'
      ),
      description: t(
        'pension.qnr.investmentStrategy.additionalInfo.description',
        'Yes, you can change your investment strategy at any time. [This article]({{articleUrl}}) explains in detail how we determine our investment strategy',
        {
          articleUrl: faq.pensionInvestmentStrategies,
        }
      ),
    },
  },
  savingForRetirement: {
    question: t(
      'pension.qnr.savingForRetirement.title',
      'Do you want to have private pension insurance to save for retirement?'
    ),
  },
  privatePensionInfo: { question: '' },
  privatePensionLongerThan5Years: {
    question: t(
      'pension.qnr.privatePensionLongerThan5Years.title',
      'Do you plan to keep private pension insurance more than 5 years?'
    ),
  },
  privatePensionLongerThan5YearsInfo: {
    question: '',
  },
  hasReviewedInvestmentStrategyInfo: {
    question: '',
  },
  name: {
    question: t('pension.qnr.personalInfo.name.title', 'What is your name?'),
  },
  email: {
    question: t('pension.qnr.personalInfo.email.title', 'What is your email?'),
    description: t(
      'pension.qnr.personalInfo.email.description',
      'This policy will be accessible in your Feather account.'
    ),
  },
  dateOfBirth: {
    question: t(
      'pension.qnr.personalInfo.dateOfBirth.title',
      'When were you born?'
    ),
  },
  under18Blocker: {
    question: '',
  },
  over50Blocker: {
    question: '',
  },
  townOfBirth: {
    question: t(
      'pension.qnr.personalInfo.townOfBirth.title',
      'Where were you born?'
    ),
    description: t(
      'pension.qnr.personalInfo.townOfBirth.description',
      'It should match the place of birth shown on your ID card.'
    ),
    placeholder: t(
      'pension.qnr.personalInfo.townOfBirth.placeholder',
      'Town, Country'
    ),
  },
  address: {
    question: t(
      'pension.qnr.personalInfo.address.title',
      'What is your address in Germany?'
    ),
  },
  taxResidency: {
    question: t(
      'pension.qnr.personalInfo.taxResidency.title',
      'In which country do you pay your taxes?'
    ),
    description: t(
      'pension.qnr.personalInfo.taxResidency.description',
      'You can pick more than one.'
    ),
  },
  citizenship: {
    question: t(
      'pension.qnr.personalInfo.citizenship.title',
      'Which country do you hold a passport from?'
    ),
  },
  identificationDocument: {
    question: t(
      'pension.qnr.identificationDocument.title',
      'Provide an identification document'
    ),
  },
  uploadPassport: {
    question: t('pension.qnr.uploadPassport.title', 'Copy of your passport'),
  },
  passportIssueDate: {
    question: t(
      'pension.qnr.personalInfo.passportIssueDate.title',
      'When was your passport issued?'
    ),
  },
  passportExpiryDate: {
    question: t(
      'pension.qnr.personalInfo.passportExpiryDate.title',
      'When does your passport expire?'
    ),
  },
  uploadIdCard: {
    question: t('pension.qnr.uploadIdCard.title', 'Copy of your national ID'),
    description: t(
      'pension.qnr.uploadIdCard.description',
      'Please upload both the front and back sides of your national ID card.'
    ),
  },
  idCardIssueDate: {
    question: t(
      'pension.qnr.personalInfo.idCardIssueDate.title',
      'When was your national ID issued?'
    ),
  },
  idCardExpiryDate: {
    question: t(
      'pension.qnr.personalInfo.idCardExpiryDate.title',
      'When does your national ID expire?'
    ),
  },
  isSmoker: {
    question: t('pension.qnr.isSmoker.question', 'Are you a smoker?'),
    additionalInfo: {
      title: t(
        'pension.qnr.isSmoker.additionalInfo.title',
        'What counts as smoking?'
      ),
      description: t(
        'pension.qnr.isSmoker.additionalInfo.description',
        'Using any of these products: cigarettes, marijuana, e-cigarettes, vaping, hookahs, cigars, chewing tobacco, nicotine replacement therapies (patches, lozenges, gum, inhalers, nose sprays), etc.'
      ),
    },
  },
  hasReviewedPersonalInfo: {
    question: '',
  },
  occupation: {
    question: t(
      'pension.qnr.financialHistory.occupation.title',
      "What's your employment status in Germany?"
    ),
  },
  paysTaxesInGermany: {
    question: t(
      'pension.qnr.paysTaxesInGermany.title',
      'Do you pay taxes in Germany?'
    ),
  },
  paysTaxesInGermanyBlocker: {
    question: '',
  },
  jobTitle: {
    question: t('pension.qnr.jobTitle.title', "What's your job title?"),
    placeholder: t('pension.qnr.jobTitle.input.placeholder', 'Job title'),
  },
  income: {
    question: t(
      'pension.qnr.income.title',
      "What's your yearly income before taxes?"
    ),
  },
  taxId: {
    question: t('pension.qnr.taxId.title', "What's your tax ID?"),
    additionalInfo: {
      title: t(
        'pension.qnr.taxId.additionalInfo.title',
        'Where to find your tax ID?'
      ),
      description: t(
        'pension.qnr.taxId.additionalInfo.description',
        'The tax ID _(Steueridentifikationsnummer or SteuerID)_ consists of 11 digits, you can find it  on your paycheck.'
      ),
    },
    placeholder: t('pension.qnr.taxId.input.placeholder', 'Tax ID'),
  },
  incomeSource: {
    question: t(
      'pension.qnr.incomeSource.title',
      'What is the source of your monthly contributions?'
    ),
    description: t(
      'pension.qnr.incomeSource.description',
      'We need this due to anti-money laundering laws.'
    ),
  },
  sepa: {
    question: t('pension.qnr.paymentDetails.title', 'Payment details'),
  },
  hasReviewedFinancialHistoryInfo: {
    question: '',
  },
  legalDisclaimer: {
    question: '',
  },
  riskConsent: {
    question: t(
      'pension.qnr.legalDisclaimer.riskConsent.checkbox.text',
      'I’m aware and willing to take the risk that I may not get back all the money I invest. There is no guarantee, which is true for almost all investment products.'
    ),
  },
  shortTermLossConsent: {
    question: t(
      'pension.qnr.legalDisclaimer.shortTermLoss.checkbox.text',
      'I acknowledge that I can lose a large part of my investment in the short term by investing my Pension into ETFs, which is true for all stock market ETF-based products.'
    ),
  },
  submitApplication: {
    question: t(
      'pension.qnr.submitApplication.documentsReview.hasReviewedDocuments.checkbox',
      'I confirm to have downloaded and accepted the insurance conditions/IPID, advice record, and licensing information.'
    ),
  },
  reviewCheckout: {
    question: '',
  },
  review: {
    question: '',
  },
  quoteId: {
    question: '',
  },
  questionnaireId: {
    question: '',
  },
});
