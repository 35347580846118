import { Reducer } from 'redux';

export type TranslationSource = 'CODE' | 'PHRASE' | 'OTA';

export type FallbackOption = 'ENGLISH' | 'PLACEHOLDER' | 'KEY';

export type I18nAction =
  | {
      type: 'SET_TRANSLATION_SOURCE';
      translationSource: TranslationSource;
    }
  | {
      type: 'SET_UNTRANSLATED_FALLBACK';
      fallbackOption: FallbackOption;
    };

export type I18nState = {
  translationSource?: TranslationSource;
  fallbackOption?: FallbackOption;
};

export const defaultState = {};

export const i18nReducer: Reducer<I18nState, I18nAction> = (
  state = defaultState,
  action
): I18nState => {
  switch (action.type) {
    case 'SET_TRANSLATION_SOURCE':
      return {
        ...state,
        translationSource: action.translationSource,
      };
    case 'SET_UNTRANSLATED_FALLBACK':
      return {
        ...state,
        fallbackOption: action.fallbackOption,
      };
    default:
      return state;
  }
};
