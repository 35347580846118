import 'headerScripts';

import {
  consentCookieExists,
  getCurrentConsent,
} from '@getpopsure/cookie-consent';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FlagProvider from '@unleash/proxy-client-react';
import { autoPurgeReducerState } from 'autoPurgeReducerState';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { persistor, store } from 'reducers';
import { PersistGate } from 'redux-persist/integration/react';
import browserHistory from 'shared/browserHistory';
import { isMobileApp } from 'shared/util/isMobileApp';

import App from './app';
import { initI18n } from './i18n';
import * as serviceWorker from './registerServiceWorker';
import { initializeTrackers } from './shared/trackers/initializeTrackers';
import { unleashConfig } from './unleash.config';

if (consentCookieExists()) {
  initializeTrackers(getCurrentConsent());
}
const onBeforeLift = async () => {
  autoPurgeReducerState();
  await initI18n();
};

const queryClient = new QueryClient();

const Root = () => (
  <FlagProvider config={unleashConfig}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate
          onBeforeLift={onBeforeLift}
          loading={null}
          persistor={persistor}
        >
          <Router history={browserHistory}>
            <Route component={App} />
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </FlagProvider>
);

if (isMobileApp) {
  // Allow mobile version to access directly react-router to make navigation snappier
  window.navigateTo = (path: string) => {
    browserHistory.push(path);
  };

  // Allow mobile version to get current user ID
  window.getUserId = (): string | null => {
    const storeState = store.getState();

    return storeState?.user?.accountInfo?.id || null;
  };
}

// Expose redux store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default Root;
