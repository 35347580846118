import { trackFaqItemOpened } from '@getpopsure/analytics';
import { useTranslation } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { Accordion, Card, Table, Toggle } from '@popsure/dirty-swan';
import { type BlocksContent } from '@strapi/blocks-react-renderer';
import classNames from 'classnames';
import { ErrorWithAction } from 'components/ErrorWithAction';
import Markdown from 'components/markdown';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import StrapiRichText from 'components/StrapiRichText/StrapiRichText';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { Dispatch, SetStateAction } from 'react';
import { isMobileApp } from 'shared/util/isMobileApp';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { QuoteFooter } from './components/Footer/Footer';
import { QuoteHeader } from './components/Header/Header';
import { OptionCustomization } from './components/OptionsCustomization/OptionsCustomization';
import { QuoteConfig, QuoteCustomization, QuoteResponse } from './Quote.models';
import styles from './Quote.module.scss';

interface QuoteViewProps extends QuoteConfig {
  quotes: QuoteResponse[] | undefined;
  isInitialLoading?: boolean;
  hasMultiplePlans: boolean;
  selectedPlan: string;
  selectedAddons: string[];
  selectedQuoteOptions: Record<string, unknown>;
  selectedQuote: QuoteResponse | undefined;
  setQuoteCustomization: Dispatch<SetStateAction<QuoteCustomization>>;
  region?: Region;
}

export const QuotePageView = <Questionnaire extends QuestionnaireQuestions>({
  addons,
  covered,
  faq,
  footer,
  headerImage,
  headerColor,
  notCovered,
  quoteOptions,
  table,
  title,
  quotes,
  isInitialLoading,
  hasMultiplePlans,
  selectedPlan,
  selectedAddons,
  selectedQuoteOptions,
  selectedQuote,
  setQuoteCustomization,
  onSubmitValue,
  region,
  featureName,
}: QuoteViewProps & {
  featureName: string;
  onSubmitValue: (
    value: QuoteResponse,
    additionalData?: Partial<Questionnaire> | undefined
  ) => void;
}) => {
  const { t } = useTranslation();

  const toggleAddons = (selection: string) => {
    setQuoteCustomization((prev) => ({
      ...prev,
      selectedAddons: prev.selectedAddons.includes(selection)
        ? prev.selectedAddons.filter((currentKey) => currentKey !== selection)
        : [...prev.selectedAddons, selection],
    }));
  };

  const availableAddons = selectedQuote?.availableAddons ?? [];
  const addonPrices = selectedQuote?.addonPrices;

  const headerRow = table?.tableData?.[0]?.rows?.[0];
  const cellReplacements = hasMultiplePlans
    ? quotes?.reduce((acc, quote, index) => {
        const currentHeaderCell = headerRow?.[index + 1];

        if (
          currentHeaderCell?.type !== 'BUTTON' ||
          !currentHeaderCell?.cellId
        ) {
          return acc;
        }

        return {
          ...acc,
          [currentHeaderCell?.cellId]: {
            title: quote.name ?? quote.planId,
            onClick: () =>
              setQuoteCustomization((prev) => ({
                ...prev,
                selectedPlan: quote.planId,
              })),
            isSelected: selectedPlan === quote.planId,
            price: `${englishFormattedEuroCurrency(quote.price)}/mo`,
            disabled: false,
          },
        };
      }, {})
    : {};

  const onHandleSubmit = (quoteSelection: QuoteResponse) => {
    if (quoteSelection) {
      onSubmitValue(quoteSelection);
    }
  };

  if (isInitialLoading) {
    return (
      <TimedLoadSpinner
        title={t(
          'components.quote.processing.loadingText',
          'Processing quote...'
        )}
      />
    );
  }

  if (quotes && selectedQuote) {
    return (
      <div className="w100 bg-white">
        <QuoteHeader
          title={title}
          headerImage={headerImage}
          headerColor={headerColor}
          hasMultiplePlans={hasMultiplePlans}
        />
        {!hasMultiplePlans && (
          <div className="ta-center pt24 mx-auto mb56">
            <div className="p-h1--xl p--serif tc-primary-500">
              {englishFormattedEuroCurrency(selectedQuote.price)}
            </div>
            <div className="p-p tc-grey-600">
              {t('components.quote.perMonth', '/per month')}
            </div>
          </div>
        )}
        <div className="p-body">
          {table && (
            <section className="mb80">
              <Table
                collapsibleSections={table.collapsibleSections}
                hideDetails={table.hideDetails}
                modalContentRenderer={(content) =>
                  typeof content === 'string' ? (
                    <Markdown>{content}</Markdown>
                  ) : (
                    <StrapiRichText richText={content as BlocksContent} />
                  )
                }
                textOverrides={{
                  hideDetails: t(
                    'expatLongTerm.qnr.quote.table.hideDetails',
                    'Hide coverage details'
                  ),
                  showDetails: t(
                    'expatLongTerm.qnr.quote.table.showDetails',
                    'Coverage details'
                  ),
                }}
                title={table.title}
                tableData={table.tableData}
                cellReplacements={cellReplacements}
                onSelectionChanged={(index) =>
                  setQuoteCustomization((prev) => ({
                    ...prev,
                    selectedPlan: quotes[index - 1].planId,
                  }))
                }
                stickyHeaderTopOffset={isMobileApp ? 88 : 0}
              />
            </section>
          )}

          {quoteOptions && (
            <OptionCustomization
              quoteOptions={quoteOptions}
              selectedQuoteOptions={selectedQuoteOptions}
              setQuoteCustomization={setQuoteCustomization}
            />
          )}

          {addons && addons.addonItem.length > 0 && (
            <section className="mb80">
              <SectionHeader
                title={t(
                  'components.quote.extendedCoverage.title',
                  'Extend your coverage'
                )}
              />
              {addons.addonItem.map(
                ({ title: addonTitle, description, addonKey }) => {
                  if (
                    availableAddons.length &&
                    !availableAddons.includes(addonKey)
                  ) {
                    return null;
                  }

                  const addonPrice = addonPrices
                    ? addonPrices[addonKey]
                    : undefined;

                  return (
                    <Card
                      key={addonKey}
                      title={addonTitle}
                      description={description}
                      dropShadow={false}
                      density="spacious"
                      data-testid={`addon-${addonKey}`}
                      classNames={{
                        buttonWrapper: classNames('mt24', styles.card),
                        wrapper: classNames('gap24', styles.cardWrapper),
                        actionIcon: classNames(
                          'jc-center',
                          styles.cardActionIcon
                        ),
                      }}
                      showActionIcon={true}
                      actionIcon={
                        <Toggle
                          bordered={false}
                          options={{
                            [addonKey]: addonPrice
                              ? `+ ${englishFormattedEuroCurrency(addonPrice)}`
                              : '',
                          }}
                          value={
                            selectedAddons.includes(addonKey) ? [addonKey] : []
                          }
                          onChange={() => toggleAddons(addonKey)}
                          classNames={{
                            label: classNames(styles.toggleLabel, 'p-h3'),
                            option: 'd-flex ai-center',
                          }}
                        />
                      }
                    />
                  );
                }
              )}
            </section>
          )}

          {covered && covered.card.length > 0 && (
            <section className="mb80">
              <SectionHeader centeredOnMobile title={covered.title} />

              <div
                className={classNames(
                  styles.coveredCardWrapper,
                  'd-grid gap16 mt24'
                )}
              >
                {covered.card.map(
                  ({ title: coverageTitle, description, icon, id }) => (
                    <Card
                      key={`coverage-card-${id}`}
                      title={coverageTitle}
                      description={
                        <Markdown paragraphClassName="tc-grey-600">
                          {description}
                        </Markdown>
                      }
                      icon={
                        <img
                          src={icon.src}
                          alt={icon.alt}
                          className={classNames(styles.coveredIcon)}
                        />
                      }
                      dropShadow={false}
                      verticalAlignment="top"
                      classNames={{ wrapper: styles.card }}
                    />
                  )
                )}
              </div>
            </section>
          )}

          {notCovered && notCovered.card.length > 0 && (
            <section className="mb80">
              <SectionHeader centeredOnMobile title={notCovered.title} />

              <div
                className={classNames(
                  styles.coveredCardWrapper,
                  'd-grid gap16 mt24'
                )}
              >
                {notCovered.card.map(
                  ({ title: coverageTitle, description, icon, id }) => (
                    <Card
                      key={`coverage-card-${id}`}
                      title={coverageTitle}
                      description={
                        <Markdown paragraphClassName="tc-grey-600">
                          {description}
                        </Markdown>
                      }
                      icon={
                        <img
                          src={icon.src}
                          alt={icon.alt}
                          className={classNames(styles.notCoveredIcon)}
                        />
                      }
                      dropShadow={false}
                      verticalAlignment="top"
                      classNames={{ wrapper: styles.card }}
                    />
                  )
                )}
              </div>
            </section>
          )}

          <section className="p-body mb80">
            <SectionHeader
              centeredOnMobile
              title={t('components.faq.title', 'Frequently asked questions')}
            />
            <div className="mt32">
              <Accordion
                items={faq}
                onClick={({ question }) => trackFaqItemOpened({ question })}
              />
            </div>
          </section>

          <section>
            <ReviewBadge className="w100 mb80 d-flex jc-center" />
          </section>
        </div>
        <QuoteFooter
          links={footer}
          onSubmit={() => onHandleSubmit(selectedQuote)}
          price={selectedQuote?.price ?? 0}
          region={region}
          featureName={featureName}
        />
      </div>
    );
  }

  return (
    <ErrorWithAction
      title={t('components.quote.error.title', 'Something went wrong')}
      description={t(
        'components.quote.error.description',
        'Sorry, there was a problem loading your quote. If the problem persists after starting again, please contact our support team.'
      )}
      cta={{
        title: t('components.quote.error.cta', 'Contact support'),
        onClick: () => {
          window.location.href = website.support;
          return null;
        },
      }}
    />
  );
};
