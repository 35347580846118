import { PrivatePreSignup } from 'features/privateHealthPreSignup/models';
import { TFunction } from 'shared/i18n';
import { RadioOption } from 'shared/models/types';

export type QuestionTextData = {
  question: string;
  description?: string;
  placeholder?: string;
  additionalInfo?: {
    title: string;
    description: string;
  };
  mapValue?: Record<string, RadioOption>;
  options?: Record<string, unknown>;
};

export type QuestionLookupOptions = {
  showDependentVersion?: boolean;
};

export const questionPropLookup = (
  t: TFunction,
  options: QuestionLookupOptions = {}
): Record<keyof PrivatePreSignup, QuestionTextData> => ({
  welcome: {
    question: '',
  },
  birthYear: {
    question: t(
      'private.qnr.birthYear.question',
      'What year were you born in?'
    ),
    placeholder: t('private.qnr.birthYear.placeholder', 'Year of birth'),
  },
  citizenship: {
    question: t(
      'private.qnr.citizenship.question',
      'Which countries do you hold a passport from?'
    ),
  },
  residencyPermitType: {
    question: t(
      'private.qnr.residencyPermitType.question',
      'What type of residence permit do or will you have?'
    ),
    additionalInfo: {
      title: t(
        'private.qnr.residencyPermitType.additionalInfo.title',
        'What’s the difference?'
      ),
      description: t(
        'private.qnr.residencyPermitType.additionalInfo.description',
        'A limited residence permit or Aufenthaltserlaubnis (literally residence permit) is valid for a certain period of time and is issued for a certain purpose.\n\nAn unlimited residence permit or Niederlassungserlaubnis (literally settlement permit) is a permanent residence permit.'
      ),
    },
    mapValue: {
      LIMITED_RESIDENCE_PERMIT: t(
        'private.qnr.residencyPermitType.limited',
        'Limited residence permit'
      ),
      UNLIMITED_RESIDENCE_PERMIT: t(
        'private.qnr.residencyPermitType.unlimited',
        'Unlimited residence permit'
      ),
    },
  },
  relocatedMoreThan4YearsAgo: {
    question: t(
      'private.qnr.relocatedMoreThan4Years.question',
      'Did you relocate to Germany more than 4 years ago?'
    ),
  },
  employmentStatus: {
    question: t(
      'private.qnr.employmentStatus.question',
      'What is your employment status?'
    ),
    additionalInfo: {
      title: t(
        'private.qnr.employmentStatus.additionalInfo.title',
        'Working for an international company?'
      ),
      description: t(
        'private.qnr.employmentStatus.additionalInfo.description',
        'If your company has an office in Germany or has a German tax accountant, you are considered to be employed in Germany.'
      ),
    },
    mapValue: {
      EMPLOYED_IN_GERMANY: t(
        'private.qnr.employmentStatus.employedInGermany',
        'Employed in Germany'
      ),
      EMPLOYED_OUTSIDE_OF_GERMANY: {
        title: t(
          'private.qnr.employmentStatus.employedOutsideGermany',
          'Employed outside of Germany'
        ),
        description: t(
          'private.qnr.employmentStatus.employedOutsideGermany.description',
          'i.e., the company doesn’t have an office in Germany'
        ),
      },
      SELF_EMPLOYED: t(
        'private.qnr.employmentStatus.selfEmployed',
        'Self employed'
      ),
      OTHER: t('private.qnr.employmentStatus.otherStatus', 'Other'),
    },
  },
  eligibleForShortTerm: {
    question: '',
  },
  income: {
    question: t(
      'private.qnr.income.question',
      'What is your annual income in Euros before taxes?'
    ),
    placeholder: t('private.qnr.income.placeholder', 'Annual income'),
  },
  hanseMerkurRedirect: {
    question: '',
  },
  freelancerNotEligibleBlocker: {
    question: '',
  },
  incomeUnderEmployeeThresholdBlocker: {
    question: '',
  },
  familyCoverage: {
    question: t(
      'private.qnr.familyCoverage.question',
      'Do you wish to cover any family members?'
    ),
    mapValue: {
      YES: t('private.qnr.familyCoverage.yes', 'Yes'),
      NOT_NOW: t('private.qnr.familyCoverage.notNow', 'Not right now'),
    },
  },
  dependentTypes: {
    question: t(
      'private.qnr.dependentTypes.question',
      'Who in your family would you like to cover?'
    ),
    mapValue: {
      SPOUSE: t('private.qnr.dependentTypes.spouse', 'My spouse'),
      CHILDREN: t(
        'private.qnr.dependentTypes.children',
        'My child or children'
      ),
    },
  },
  spouseHasOwnIncome: {
    question: t(
      'private.qnr.spouseHasOwnIncome.question',
      'Is your spouse currently earning an income in Germany or abroad?'
    ),
    description: t(
      'private.qnr.spouseHasOwnIncome.description',
      'If your spouse earns **under €520 gross per month** please select "**No**"'
    ),
  },
  spouseHasOwnIncomeInfo: {
    question: '',
  },
  experiencedConditions: {
    question: !options.showDependentVersion
      ? t(
          'private.qnr.experiencedConditions.question',
          'Have you ever experienced any of the following?'
        )
      : t(
          'private.qnr.experiencedConditions.dependentVersion.question',
          'Have you or any of your dependents ever experienced any of the following?'
        ),
    mapValue: {
      MENTAL_ILLNESS: t(
        'private.qnr.experiencedConditions.mentalHealthConditions',
        'Mental health conditions'
      ),
      AUTOIMMUNE_DISORDER: t(
        'private.qnr.experiencedConditions.autoimmuneDisorder',
        'Autoimmune disorder'
      ),
      PSYCHOTHERAPY: t(
        'private.qnr.experiencedConditions.psychotherapy',
        'Psychotherapy'
      ),
      ARTHRITIS: t('private.qnr.experiencedConditions.arthritis', 'Arthritis'),
      DRUG_ADDICTION: t(
        'private.qnr.experiencedConditions.drugAddiction',
        'Drug addiction'
      ),
      ASTHMA: t('private.qnr.experiencedConditions.asthma', 'Asthma'),
      ADHD: t('private.qnr.experiencedConditions.adhd', 'ADHD'),
      CANCER: t('private.qnr.experiencedConditions.cancer', 'Cancer'),
      EPILEPSY: t('private.qnr.experiencedConditions.epilepsy', 'Epilepsy'),
      DIABETES: t('private.qnr.experiencedConditions.diabetes', 'Diabetes'),
      HIV_AIDS: t('private.qnr.experiencedConditions.hiv', 'HIV'),
      HEPATITIS: t('private.qnr.experiencedConditions.hepatitis', 'Hepatitis'),
      PREP: t('private.qnr.experiencedConditions.PrEP', 'Taking PrEP'),
      MULTIPLE_SCLEROSIS: t(
        'private.qnr.experiencedConditions.multipleSclerosis',
        'Multiple sclerosis'
      ),
    },
  },
  experiencedConditionsBlocker: {
    question: '',
  },
  prepOrAsthmaOnlyBlocker: {
    question: '',
  },
  numberOfPsychotherapySessions: {
    question: !options.showDependentVersion
      ? t(
          'private.qnr.numberOfPsychotherapySessions.question',
          'How many therapy sessions did you have?'
        )
      : t(
          'private.qnr.numberOfPsychotherapySessions.dependentVersion.question',
          'How many therapy sessions did you or your dependent have?'
        ),
    mapValue: {
      LESS_THAN_5: t(
        'private.qnr.numberOfPsychotherapySessions.lessThan5',
        'Less than 5 sessions'
      ),
      BETWEEN_5_AND_25: t(
        'private.qnr.numberOfPsychotherapySessions.between5and25',
        'Between 5 and 25 sessions'
      ),
      MORE_THAN_25: t(
        'private.qnr.numberOfPsychotherapySessions.moreThan25',
        'More than 25 sessions'
      ),
    },
  },
  psychotherapyStatus: {
    question: !options.showDependentVersion
      ? t(
          'private.qnr.psychotherapyStatus.question',
          'When did your therapy end?'
        )
      : t(
          'private.qnr.psychotherapyStatus.dependentVersion.question',
          'When did the therapy end?'
        ),
    mapValue: {
      ENDED_MORE_THAN_5_YEARS_AGO: t(
        'private.qnr.psychotherapyStatus.moreThan5YearsAgo',
        'More than 5 years ago'
      ),
      ENDED_LESS_THAN_5_YEARS_AGO: t(
        'private.qnr.psychotherapyStatus.lessThan5YearsAgo',
        'Less than 5 years ago'
      ),
      ONGOING: t(
        'private.qnr.psychotherapyStatus.stillOngoing',
        "It's still ongoing"
      ),
    },
  },
  dependentSignupBlocker: {
    question: '',
  },
  calculatePrice: {
    question: '',
  },
  questionnaireId: {
    question: '',
  },
  hashOfQuestionnaire: {
    question: '',
  },
  showLongTerm: {
    question: '',
  },
  quote: {
    question: '',
  },
  planReview: {
    question: '',
  },
  redirectToPrivateSignup: {
    question: '',
  },
});
