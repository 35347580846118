import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { InsuranceTypes } from 'models/insurances/types';
import { isRegionValid } from 'routeLocales';
import { GenericCheckout } from 'SignupQuestionnaire/components/GenericCheckout';
import { PolicyDetailsConfiguration } from 'SignupQuestionnaire/components/GenericCheckout/utils';

export const GeneralGenericCheckout = <
  Questionnaire extends QuestionnaireQuestions
>(
  props: QuestionnaireFormProps<Questionnaire> & {
    type: InsuranceTypes;
    genericQuestionnaireKey: string;
    hasCheckoutDocuments?: boolean;
    icon?: string;
    changeStartDatePath?: string;
    redirectAddDependent?: keyof Questionnaire | boolean;
    policyDetailsConfiguration?: PolicyDetailsConfiguration;
    mainPolicyId?: string;
  }
) => {
  const { questionnaireAnswers } = props;

  const currentRegion =
    typeof questionnaireAnswers.region === 'string' &&
    isRegionValid(questionnaireAnswers.region)
      ? questionnaireAnswers.region
      : 'de';

  return <GenericCheckout {...props} region={currentRegion} />;
};
