import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  SubmittableTravelHealthV1Questionnaire,
  TravelHealthQuote,
  TravelHealthV1Meta,
  ZSubmittableTravelHealthV1Questionnaire,
  ZTravelHealthQuote,
} from './models';

export const createTravelHealthV1Quote = async (
  answers: TravelHealthQuote,
  questionnaireId: string
) => {
  return endpoint.network.post<TravelHealthQuote>('/signups/travel/quote', {
    answers,
    questionnaireId,
  });
};

export const createTravelHealthV1Questionnaire = (
  answers: SubmittableTravelHealthV1Questionnaire,
  meta: TravelHealthV1Meta
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers: SubmittableTravelHealthV1Questionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType: 'TRAVEL',
    metadata: { source, ...meta },
  });
};

export const createTravelHealthV1PostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<{ status: ActionResponse }>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'travelHealthV1'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'travelHealthV1'>,
    getState: () => AppState
  ): Promise<{ status: ActionResponse }> => {
    const questionnaire =
      getGenericQuestionnaire(getState()).travelHealthV1 || {};

    try {
      if (!questionnaire.quote)
        throw new Error('[TravelHealthV1] Quote not defined');

      const quoteValidation = ZTravelHealthQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success)
        throw new Error(
          `[TravelHealthV1] Quote error: ${quoteValidation.error.toString()}`
        );

      const questionnaireValidation =
        ZSubmittableTravelHealthV1Questionnaire.safeParse(questionnaire);

      if (!questionnaireValidation.success)
        throw new Error(
          `[TravelHealthV1] Questionnaire validation errors: ${questionnaireValidation.error.toString()}`
        );

      // check if account exists, create otherwise
      const {
        personalInfo: {
          name: { firstName, lastName },
          email,
          gender,
          dateOfBirth,
        },
      } = questionnaireValidation.data;
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
            gender,
            dateOfBirth,
          })
        );
      }

      dispatch(setRequestInProcess(true, 'TRAVEL_SUBMIT_INFO'));

      const {
        data: { id: questionnaireId },
      } = await createTravelHealthV1Questionnaire(
        questionnaireValidation.data,
        {
          labels: {
            title: {
              personalInfo: {
                dateOfBirth: 'What is your date of birth?',
                isLivingInGermany: 'Are you currently living in Germany?',
                email: "What's your email address?",
                name: "What's your name?",
                gender: 'Which gender do you identify with?',
              },
              tariffInfo: {
                cover: 'Who would you like to cover for your trip?',
                numberOfCovered:
                  'How many other people would you like to cover?',
                startDate:
                  'What date would you like your travel insurance policy to begin?',
                othersCovered:
                  'What are the names and birthdays of the dependents you would like to cover?',
                planId: '',
              },
            },
            answers: {
              personalInfo: {},
              tariffInfo: {},
            },
          },
          questionOrder: {
            personalInfo: [
              'isLivingInGermany',
              'name',
              'email',
              'dateOfBirth',
              'gender',
            ],
            tariffInfo: [
              'cover',
              'numberOfCovered',
              'startDate',
              'othersCovered',
            ],
          },
        }
      );

      const { data: quote } = await createTravelHealthV1Quote(
        quoteValidation.data,
        questionnaireId
      );

      dispatch(
        storeGenericQuestionnaireAnswer('travelHealthV1', {
          questionnaireId,
          quoteId: quote.id,
          quote: {
            ...quoteValidation.data,
          },
        })
      );

      await generateCheckoutDocuments(questionnaireId, quote.id, 'TRAVEL', t);

      dispatch(setRequestInProcess(false, 'TRAVEL_SUBMIT_INFO'));

      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'TRAVEL_SUBMIT_INFO')
      );
      Sentry.captureException(
        `[TRAVEL Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
