import { TallyModal } from 'components/TallyModal';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';

export const TravelClaimModal = (props: PolicySingleModalProps) => {
  const {
    onClose,
    policyData: {
      id,
      attributes: { insuredPerson },
    },
  } = props;

  const URLparams = {
    policy_id: id,
    customer_first_name: insuredPerson?.name?.firstName,
    customer_last_name: insuredPerson?.name?.lastName,
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="3E5A72"
      title="Travel Claim"
      URLparams={URLparams}
    />
  );
};
