import { insurance } from '@getpopsure/private-constants';
import { paramsSetUrl } from '@getpopsure/public-utility';
import CardWithAnimation from 'components/cardWithAnimation';
import { petHealth2x } from 'models/insurances/assets/img';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export const PetHealthCard = () => {
  const { t } = useSafeTranslation();
  return (
    <a
      href={paramsSetUrl(insurance.petHealth.landingPage, [
        {
          key: 'utm_source',
          value: 'account',
        },
        { key: 'utm_medium', value: 'signup-success-card-pet-health' },
        { key: 'utm_campaign', value: 'dog-liability' },
      ])}
      className={styles.cardLink}
    >
      <CardWithAnimation
        title={t(
          'myPolicies.recommended.petHealth.title',
          'Pet health insurance, for when paws need more than just a pause'
        )}
        leftIcon={{ src: petHealth2x, alt: 'pet health' }}
        rightIcon="arrow"
        className="mt24"
        data-testid="recommended-pet-health-info"
        data-cy="recommended-pet-health-info"
      >
        <span className="p-p--small fw-normal d-block">
          {t(
            'myPolicies.recommended.petHealth.description',
            'Covers veterinarian bills of any licensed clinic around the world. Beyond surgeries, you can also include video consultations, dental care, and preventative treatments.'
          )}
        </span>
      </CardWithAnimation>
    </a>
  );
};
