import {
  ArrowUpRightIcon,
  Card,
  illustrations,
  Link,
} from '@popsure/dirty-swan';
import { fetchAccountInfo } from 'actions/user';
import LoadingSpinner from 'components/loadingSpinner';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getRequestInProcess } from 'selectors/requests';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

const TaxStatements = () => {
  const { t } = useSafeTranslation();
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);

  const isLoadingAccountInfo = useSelector((state: AppState) =>
    getRequestInProcess(state, 'ACCOUNT')
  );

  useEffect(() => {
    if (isFirstFetch) {
      dispatch(fetchAccountInfo());
      setIsFirstFetch(false);
    }
  }, [dispatch, isFirstFetch]);

  if (isFirstFetch || isLoadingAccountInfo) {
    return <LoadingSpinner />;
  }

  return (
    <div className="p-body wmx8">
      <NavButton
        title={t(
          'account.taxStatements.backButton.title',
          'Billing and payment'
        )}
        path={routes.account.billingAndPayment.path}
      />
      <div className={`d-flex jc-between ai-center ${styles.faq}`}>
        <h1 className="p-h1">
          {t('account.taxStatements.title', 'Tax Statements')}
        </h1>
        <Link
          href="https://faq.feather-insurance.com/en/articles/118565-are-my-feather-insurance-policies-tax-deductible"
          target="_blank"
          className="p-h4"
        >
          <div className="d-flex ai-center">
            {t(
              'account.taxStatements.learnMore.label',
              'Learn about tax statements'
            )}
            <ArrowUpRightIcon size={20} />
          </div>
        </Link>
      </div>

      {account &&
      account.taxStatementDocuments &&
      account.taxStatementDocuments.length > 0 ? (
        <div className="flex mt24">
          {account.taxStatementDocuments
            .sort((doc1, doc2) => doc2.year - doc1.year)
            .map((doc) => (
              <Card
                key={doc.year}
                as="a"
                href={doc.publicUrl}
                target="_blank"
                rel="noreferrer noopener"
                classNames={{ buttonWrapper: 'mb16' }}
                title={`Tax statement ${doc.year}`}
                icon={<img src={illustrations.document} alt="" />}
                density="compact"
                titleVariant="small"
                actionIcon={null}
              />
            ))}
        </div>
      ) : (
        <div className="mt24">
          <span className="p-p">
            {t(
              'account.taxStatements.empty.label',
              "You don't have any tax summaries yet."
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default TaxStatements;
