import { faq, insurance, website } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { TFunction } from 'shared/i18n/utils';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import {
  arrivalDateOneYearInTheFutureValidator,
  coverageOneYearInTheFutureValidator,
} from 'SignupQuestionnaire/shared/customValidators';

import { createPostQuote } from '../actions';
import { AddDependents } from '../components/AddDependents';
import { QuoteProcessing } from '../components/Processing/Processing';
import { Quote } from '../components/Quote/Quote';
import {
  ExpatEu,
  ExpatEuGroupIds,
  genderMapping,
  occupationMapping,
} from '../models';

export const DATE_FORMAT = 'DD MMM YYYY';

export const getEarliestStartDate = (arrivalDate?: string) => {
  const tomorrow = dayjs().add(1, 'day');

  const earliestStartDate = dayjs(tomorrow).isAfter(arrivalDate)
    ? tomorrow
    : arrivalDate;

  return dayjs(earliestStartDate).format(DATE_FORMAT);
};

export const ExpatEuComponents = {
  QUOTE_PROCESSING: QuoteProcessing,
  QUOTE_PAGE: Quote,
  ADD_DEPENDENTS: AddDependents,
} as const;

export type ExpatEuQuestionnaire = SignupQuestionnaireType<
  ExpatEu,
  ExpatEuGroupIds,
  typeof ExpatEuComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  region: string,
  regionId: Region,
  continueFromBlocker: () => void,
  continueFromBetweenFourAndFiveYearsBlocker: () => void
): ExpatEuQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'expatEu.qnr.preQuote.intro.title',
        'Hey! Let’s get you a quote'
      ),
      subTitle: t(
        'expatEu.qnr.preQuote.intro.subTitle',
        'It takes less than a minute, no email needed'
      ),
      iconTitle: 'Expat health insurance',
    },
    screen: {
      continueButtonText: 'Get started',
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.preQuote.dateOfBirth.title',
        'When were you born?  '
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Info',
          value: t(
            'expatEu.qnr.dateOfBirth.validation.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'ageIs75AndAboveBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatEu.qnr.preQuote.ageIs75AndAboveBlocker.title',
        'Unfortunately...'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatEu.qnr.preQuote.ageIs75AndAboveBlocker.description',
        'Because you are over 74 years old, you aren’t eligible for expat health insurance.\n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatEu.qnr.preQuote.ageIs75AndAboveBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'arrivalDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: t('expatEu.qnr.preQuote.arrivalDate.title', {
        defaultValue: 'What’s your (planned) arrival date in {{region}}?',
        region,
      }),
      additionalInfo: {
        title: t(
          'expatEu.qnr.preQuote.arrivalDate.additionalInfo.title',
          'Why is the date important?'
        ),
        description: t(
          'expatEu.qnr.preQuote.arrivalDate.additionalInfo.description',
          'The maximum length of coverage on expat health insurance is 5 years.'
        ),
      },
    },
    validations: [arrivalDateOneYearInTheFutureValidator(t)],
    groupId: 'signup',
  },
  {
    id: 'betweenFourAndFiveYearsBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatEu.qnr.preQuote.betweenFourAndFiveYearsBlocker.title',
        'Expat health insurance might not be the best choice for you...'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatEu.qnr.preQuote.betweenFourAndFiveYearsBlocker.description',
        {
          defaultValue:
            'Expat health insurance is only valid for the first 5 years of your stay in {{region}}. Soon, you will no longer be eligible for it.\n\n You can check out long-term expat health insurance as an alternative.',
          region,
        }
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.longTermExpatHealth.landingPage,
          caption: t(
            'expatEu.qnr.preQuote.betweenFourAndFiveYearsBlocker.primaryButton.cta',
            'Explore long-term expat health'
          ),
        },
        {
          variant: 'SECONDARY',
          type: 'button',
          onClick: () => continueFromBetweenFourAndFiveYearsBlocker(),
          caption: t(
            'expatSpain.qnr.preQuote.betweenFourAndFiveYearsBlocker.secondaryButton.cta',
            'Continue application'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'moreThanFiveYearsBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatEu.qnr.preQuote.moreThanFiveYearsBlocker.title',
        'We have another option for you'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatEu.qnr.preQuote.moreThanFiveYearsBlocker.description',
        {
          defaultValue:
            "Since you have been in {{region}} for longer than 5 years, you aren't eligible for expat health insurance.\n\nYou can explore long-term expat health insurance as an alternative.",
          region,
        }
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.longTermExpatHealth.landingPage,
          caption: t(
            'expatEu.qnr.preQuote.moreThanFiveYearsBlocker.exploreLongTermExpatButton.cta',
            'Explore long-term expat health'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'occupation',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: occupationMapping(t),
    },
    screen: {
      question: t('expatEu.qnr.preQuote.occupation.title', {
        defaultValue: 'What’s your main occupation in {{region}}?',
        region,
      }),
    },
    groupId: 'preQuote',
  },
  {
    id: 'regionOfEmployment',
    type: 'RADIO',
    props: {
      mapValue: {
        YES: t('expatEu.qnr.preQuote.regionEmployment.yes', {
          defaultValue: 'Yes, in {{region}}',
          region,
        }),
        NO: t('expatEu.qnr.preQuote.regionEmployment.no', 'No, elsewhere.'),
      },
    },
    screen: {
      question: t('expatEu.qnr.preQuote.regionOfEmployment.title', {
        defaultValue: 'Are you employed in {{region}}?',
        region,
      }),
      additionalInfo: {
        title: t(
          'expatEu.qnr.preQuote.regionOfEmployment.additionalInfo.title',
          'Working for an international company?'
        ),
        description: t(
          'expatEu.qnr.preQuote.regionOfEmployment.additionalInfo.description',
          {
            defaultValue:
              'If the company you work for has an office in {{region}} and you have an employment contract from {{region}}, you are considered to be employed in {{region}}.',
            region,
          }
        ),
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedInRegionBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'expatEu.qnr.preQuote.employedInRegionBlocker.title',
        'Expat health insurance might not be right for you'
      ),
      description: t(
        'expatEu.qnr.preQuote.employedInRegionBlocker.description',
        {
          defaultValue:
            'Expat health insurance is typically not accepted by employers in {{region}}. It is only a temporary option for new arrivals in {{region}}. It does not offer full health insurance coverage of cover pre-existing conditions.',
          region,
        }
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromBlocker,
          caption: t(
            'expatEu.qnr.preQuote.employedInRegionBlocker.button.cta',
            'Continue anyway'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'addFamilyMembers',
    required: false,
    type: 'ADD_DEPENDENTS',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quoteOptions',
    required: true,
    type: 'QUOTE_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTE_PAGE',
    props: {},
    screen: {
      question: '',
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'expatEu.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'expatEu.qnr.signup.signupIntro.subTitle',
        'Just 6 questions, it takes about 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'expatEu.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'expatEu',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'expatEu.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'expatEu.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t('expatEu.qnr.signup.name.title', "What's your name?"),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t('expatEu.qnr.signup.gender.title', 'What is your gender?'),
    },
    groupId: 'signup',
  },
  {
    id: 'citizenship',
    required: true,
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: t(
        'expatEU.qnr.signup.citizenship.title',
        'Which countries do you hold a passport from?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'lastPermanentResidency',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: t('expatEU.qnr.signup.lastPermanentResidency.title', {
        defaultValue:
          'What was your country of residence before arriving in {{ region }}?',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
      additionalInfo: {
        title: t(
          'expatEu.qnr.signup.startDate.additionalInfo.title',
          'Important waiting period'
        ),
        description: t(
          'expatEu.qnr.signup.startDate.additionalInfo.description',
          {
            defaultValue:
              'You must wait 31 days after the cover starts to access most benefits. [Learn more]({{ link }})',
            link: faq.expatEuWaitingPeriod,
          }
        ),
      },
    },
    validations: [
      {
        op: 'Custom',
        fn: (answer, { arrivalDate }: Partial<ExpatEu>) => {
          const startDate = String(answer);
          return (
            dayjs().isBefore(startDate) &&
            (dayjs(arrivalDate).isSame(startDate) ||
              dayjs(arrivalDate).isBefore(startDate))
          );
        },
        msg: (_, answers) => {
          const { arrivalDate } = answers as Partial<ExpatEu>;
          const earliestStartDate = getEarliestStartDate(arrivalDate);

          return {
            type: 'Info',
            msg: t('expatEu.qnr.signup.startDate.errorMessage', {
              defaultValue:
                'Coverage start date should be after their arrival date in {{region}} ({{date}})',
              date: earliestStartDate,
              region,
            }),
          };
        },
      },
      coverageOneYearInTheFutureValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.signup.legalGuardianName.title',
        "What's the name of your parent or legal guardian?"
      ),
      description: t(
        'expatEu.qnr.signup.legalGuardianName.description',
        "As you are under 18 years old, we need your parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Info',
          value: t(
            'expatEu.qnr.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianEmail',
    type: 'EMAIL_BASIC',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.signup.legalGuardianEmail.email.title',
        "What's their email address?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t('expatEu.qnr.signup.review.plan.title', 'Plan'),
          value: {
            type: 'Strings',
            key: 'quote',
            keys: ['planId'],
          },
          path: 'quote',
        },
        {
          title: t('expatEu.qnr.signup.review.email.title', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.name.title',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.dateOfBirth.title',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t('expatEu.qnr.signup.review.gender.title', 'Gender'),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t('expatEu.qnr.signup.review.occupation.title', 'Occupation'),
          value: {
            type: 'String',
            valueMap: occupationMapping(t),
            key: 'occupation',
          },
          path: 'occupation',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.addFamilyMembers.title',
            'Who to cover?'
          ),
          value: {
            type: 'Boolean',
            valueMap: {
              true: t(
                'expatEu.qnr.signup.review.addFamilyMembers.valueMap.yes',
                'Myself and my family'
              ),
              false: t(
                'expatEu.qnr.signup.review.addFamilyMembers.valueMap.no',
                'Only myself'
              ),
            },
            key: 'addFamilyMembers',
          },
          path: 'addFamilyMembers',
        },
        {
          title: t('expatEu.qnr.signup.review.arrivalDate.title', {
            defaultValue: 'Arrival date in {{ region }}',
            region,
          }),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'arrivalDate' },
          path: 'arrivalDate',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.citizenship.title',
            'Citizenship'
          ),
          value: { type: 'Array', pick: ['name'], key: 'citizenship' },
          path: 'citizenship',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.lastPermanentResidency.title',
            'Last permanent residency'
          ),
          value: {
            type: 'Strings',
            key: 'lastPermanentResidency',
            keys: ['name'],
          },
          path: 'lastPermanentResidency',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.startDate.title',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),
          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t(
            'expatEu.qnr.signup.review.legalGuardianEmail',
            "Parent / legal guardian's email"
          ),
          value: { type: 'String', key: 'legalGuardianEmail' },
          path: 'legalGuardianEmail',
        },
      ],
      verticalId: 'expatEu',
      insuranceType: 'INCOMING_EU',
      region: regionId,
      createPostQuote: () => createPostQuote(t),
      confirmationText: t(
        'expatEu.qnr.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'GENERIC_CHECKOUT',
    props: {
      type: 'INCOMING_EU',
      icon: imageTypeMapping.INCOMING_EU,
      region: regionId,
      hasCheckoutDocuments: true,
      redirectAddDependent: 'addFamilyMembers',
      policyInfoKind: 'ExpatEuPolicy',
      policyDetailsConfiguration: {
        policyDetails: [
          {
            title: t('expatEu.qnr.checkout.policyDetails.name', 'Name'),
            questionId: 'name',
          },
          {
            title: t('expatEu.qnr.checkout.policyDetails.plan', 'Plan'),
            questionId: 'planId',
          },
          {
            title: t(
              'expatEu.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'expatEu.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'expatEu.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
          {
            title: t(
              'expatEu.qnr.checkout.policyDetails.dateOfBirth',
              'Date of Birth'
            ),
            questionId: 'dateOfBirth',
          },
          {
            title: t('expatEu.qnr.checkout.policyDetails.email', 'Email'),
            questionId: 'email',
          },
        ],
        otherSections: [
          {
            title: t(
              'expatEu.qnr.checkout.policyDetails.legalGuardian.subTitle',
              'Parent / Legal guardian'
            ),
            rows: [
              {
                title: t(
                  'expatEu.qnr.checkout.policyDetails.legalGuardian.name',
                  'Name'
                ),
                questionId: 'legalGuardianName',
              },
              {
                title: t(
                  'expatEu.qnr.checkout.policyDetails.legalGuardian.dateOfBirth',
                  'Date of Birth'
                ),
                questionId: 'legalGuardianDateOfBirth',
              },
              {
                title: t(
                  'expatEu.qnr.checkout.policyDetails.legalGuardian.email',
                  'Email'
                ),
                questionId: 'legalGuardianEmail',
              },
            ],
          },
        ],
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
