import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { getQuotes } from 'features/generalPolicy/api';
import { useEffect, useState } from 'react';
import { isRegionValid } from 'routeLocales';
import { useDebounce } from 'use-debounce';

import { QuoteConfig, QuoteCustomization, QuoteResponse } from './Quote.models';
import { QuotePageView } from './Quote.view';

const DEBOUNCE_DELAY = 500;
const STALE_TIME = 1000 * 60 * 30;

export const QuotePage = <Questionnaire extends QuestionnaireQuestions>({
  value,
  questionnaireAnswers,
  featureName,
  onSubmitValue,
  ...quoteProps
}: QuestionnaireFormProps<Questionnaire, QuoteResponse> & QuoteConfig) => {
  const [quoteCustomization, setQuoteCustomization] =
    useState<QuoteCustomization>({
      selectedPlan: value?.planId ?? '',
      selectedAddons: value?.addons ?? [],
      selectedQuoteOptions: value?.quoteOptions ?? {},
    });

  const { selectedQuoteOptions, selectedAddons, selectedPlan } =
    quoteCustomization;

  const [debouncedQuoteCustomization] = useDebounce(
    quoteCustomization,
    DEBOUNCE_DELAY
  );
  const {
    selectedAddons: debouncedAddons,
    selectedQuoteOptions: debouncedQuoteOptions,
  } = debouncedQuoteCustomization;

  const {
    data: quotes,
    isError,
    isInitialLoading,
    error,
  } = useQuery(
    [
      'quotes',
      featureName,
      questionnaireAnswers,
      debouncedAddons,
      debouncedQuoteOptions,
    ],
    () =>
      getQuotes({
        insuranceType: featureName,
        answers: questionnaireAnswers,
        addons: debouncedAddons,
        quoteOptions: debouncedQuoteOptions,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: Boolean(featureName),
      staleTime: STALE_TIME,
    }
  );

  const selectedQuote =
    quotes?.find((quote) => quote.planId === selectedPlan) || quotes?.[0];
  const hasMultiplePlans = Array.isArray(quotes) && quotes.length > 1;

  /* Once we fetch the quotes, we need to make sure there's a plan to display in the UI as selected.
  If not, we select the first returned quote. */
  useEffect(() => {
    const hasQuotes = Array.isArray(quotes) && quotes.length > 0;
    const hasNoSelectedPlan =
      !selectedPlan || !quotes?.some((quote) => quote.planId === selectedPlan);

    if (hasQuotes && hasNoSelectedPlan) {
      setQuoteCustomization((prev) => ({
        ...prev,
        selectedPlan: quotes[0]?.planId ?? '',
      }));
    }
  }, [quotes, selectedPlan]);

  if (isError) {
    Sentry.captureException(error, {
      tags: {
        feature: 'QUOTE',
      },
      extra: {
        description: `Error happened while fetching quotes for vertical: ${featureName}`,
      },
    });
  }

  const currentRegion =
    typeof questionnaireAnswers.region === 'string' &&
    isRegionValid(questionnaireAnswers.region)
      ? questionnaireAnswers.region
      : undefined;

  return (
    <QuotePageView
      onSubmitValue={onSubmitValue}
      quotes={quotes}
      selectedAddons={selectedAddons}
      selectedQuoteOptions={selectedQuoteOptions}
      selectedPlan={selectedPlan}
      setQuoteCustomization={setQuoteCustomization}
      hasMultiplePlans={hasMultiplePlans}
      isInitialLoading={isInitialLoading}
      selectedQuote={selectedQuote}
      region={currentRegion}
      featureName={featureName}
      {...quoteProps}
    />
  );
};
