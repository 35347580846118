import routes from 'constants/routes';
import { useSelector } from 'react-redux';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { GenericCheckout } from 'SignupQuestionnaire/components/GenericCheckout';

const LiabilityCheckout = () => {
  const { t } = useSafeTranslation();
  const questionnaireAnswers = useSelector(getGenericQuestionnaire).liability;
  const questionnaireAnswersWithIds = {
    ...questionnaireAnswers,
    questionnaireId: questionnaireAnswers?.quote?.questionnaireId,
    quoteId: questionnaireAnswers?.quote?.quoteId,
  };

  return (
    <GenericCheckout
      onSubmitValue={() => {}}
      setValidForSubmission={() => {}}
      featureName="questionnaire"
      question="reviewCheckout"
      questionnaireAnswers={questionnaireAnswersWithIds}
      type="LIABILITY"
      region="de"
      policyInfoKind="LiabilityPolicy"
      hasCheckoutDocuments={true}
      changeStartDatePath={
        routes.policies.liability.questionnaire.startDate.path
      }
      policyDetailsConfiguration={{
        policyDetails: [
          {
            title: t(
              'page.liability.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'page.liability.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
          {
            title: t(
              'page.liability.qnr.checkout.policydetails.inclusivemembers',
              'Included household members'
            ),
            questionId: 'inclusiveMembers',
          },
        ],
      }}
    />
  );
};

export default LiabilityCheckout;
