/* eslint-disable  @typescript-eslint/no-explicit-any */
import '@popsure/dirty-swan/dist/index.css';
import '@getpopsure/qnr-framework/dist/style.css';
import 'app.scss';
import '@getpopsure/cookie-consent/dist/style.css';

import { trackPageView, useUpdateUserTracker } from '@getpopsure/analytics';
import { ConsentObject, CookieBanner } from '@getpopsure/cookie-consent';
import {
  populateTrackingObjectFromUrl,
  setTrackingObject,
  urlSearchParamToTrackingObject,
} from '@getpopsure/tracker';
import { initialize as initializeUtmTracker } from '@getpopsure/tracker/dist/configuration';
import { Toaster } from '@popsure/dirty-swan';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useUnleashContext } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { DebugLanguageWidget } from 'components/DebugLanguageWidget';
import { ErrorBoundaryFallback } from 'components/errorBoundary';
import { GoogleEnhancedConversionDataObject } from 'features/googleEnhancedConversions';
import { GoogleOAuthProvider } from 'features/GoogleOAuth';
import type { IntercomSettings } from 'features/intercomLauncher';
import NavigationBar from 'features/navigationBar/containers';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getUserId } from 'selectors/user';
import { SafeTranslationProvider } from 'shared/i18n';
import { generatePageTitle } from 'shared/util/generatePageTitle';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './app.module.scss';
import LoadingSpinner from './components/loadingSpinner';
import Routes from './routes';
import { initializeTrackers } from './shared/trackers/initializeTrackers';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

interface LocationWithState {
  from?: {
    main?: boolean;
  };
}

declare global {
  interface Window {
    navigateTo: (path: string) => void;
    getUserId: () => string | null;
    ReactNativeWebView: { postMessage: (message: string) => void };
    webkit?: {
      messageHandlers: {
        setUserId: any;
        toggleIntercomHandler: any;
      };
    };
    Calendly: any;
    Cypress: any;
    store: any;
    apiClient: any;
    Intercom: any;
    intercomSettings?: IntercomSettings;
    setAuthToken: (token: string) => void;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: unknown;
    __REDUX_DEVTOOLS_EXTENSION__?: unknown;
    gecData?: GoogleEnhancedConversionDataObject;
  }
}

/**
 * Initialize the cookie tracker to allow App to store utm params in cookies
 */
const cookieTrackerOptions =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? { cookieAttributes: { expires: 30, domain: 'localhost' } }
    : {};

initializeUtmTracker(cookieTrackerOptions);

const stripePromise = loadStripe(STRIPE_API_KEY || '');

const App = () => {
  const updateContext = useUnleashContext();
  const userId = useSelector(getUserId);
  const updateUserId = useUpdateUserTracker();
  const location = useLocation<LocationWithState>();

  /**
   * Get the tracking object from the URL
   * to allow attribution when using direct links to the app.
   * E.g. for the Recommendation Tool
   */
  useEffect(() => {
    populateTrackingObjectFromUrl();

    if (isMobileApp) {
      document.body.classList.add('mobile-app');

      initializeTrackers({
        functionality_storage: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    }
  }, []);

  useEffect(() => {
    trackPageView();

    if (location.search) {
      const search = new URLSearchParams(location.search);
      const trackingObject = urlSearchParamToTrackingObject(search);

      if (trackingObject) {
        setTrackingObject(trackingObject);
      }
    }

    if (isMobileApp) {
      window.scroll(0, 0);
    }
  }, [location]);

  useEffect(() => {
    updateContext({ userId });

    if (isMobileApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ userId: userId || null })
      );

      window?.webkit?.messageHandlers?.setUserId?.postMessage({
        userId: userId || null,
      });
    }
  }, [updateContext, userId]);

  useEffect(() => {
    updateUserId(userId ?? '');
  }, [updateUserId, userId]);

  const title = generatePageTitle(location.pathname);

  const setTrackersAfterCookieConsent = async (consent: ConsentObject) => {
    await initializeTrackers(consent);
    trackPageView();
  };

  return (
    <div
      className={classNames(styles.root, {
        pt24: isMobileApp,
        [styles.mobileApp]: isMobileApp,
      })}
    >
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog>
        <Helmet titleTemplate="Feather | %s" defaultTitle="Feather">
          <title>{title}</title>
        </Helmet>
        <Elements
          stripe={stripePromise}
          options={{
            mode: 'setup',
            currency: 'eur',
            paymentMethodTypes: ['card', 'sepa_debit'],
          }}
        >
          <>
            <SafeTranslationProvider>
              {!isMobileApp && (
                <>
                  <NavigationBar />
                  <DebugLanguageWidget />
                </>
              )}
              <LiveChatLoaderProvider
                provider="intercom"
                providerKey={process.env.REACT_APP_INTERCOM_WORKSPACE_ID ?? ''}
              >
                <GoogleOAuthProvider>
                  <Routes />
                </GoogleOAuthProvider>
              </LiveChatLoaderProvider>
            </SafeTranslationProvider>
            {/* Hack so that Mobile safari can scroll to the button */}
            <hr style={{ borderColor: 'transparent' }} />
            <Toaster
              classNames={{ wrapper: classNames({ mt32: isMobileApp }) }}
            />
            {!isMobileApp && (
              <CookieBanner onSave={setTrackersAfterCookieConsent} />
            )}
          </>
        </Elements>
      </Sentry.ErrorBoundary>
    </div>
  );
};

const AppWithSuspense = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <App />
    </Suspense>
  );
};

export default AppWithSuspense;
