import { TFunction } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { FAQInfo } from 'components/faq';

const severityFactorLink = `${faq.base}/en/articles/28618-what-does-the-severity-factor-steigerungsfaktor-mean-on-my-dental-bill`;
const coverFamilyMembersLink = `${faq.base}/en/articles/43474-how-do-i-add-family-members-to-my-supplemental-dental-insurance-policy`;
const preexistingTreatmentsLink = `${faq.base}/en/articles/71832-what-is-a-pre-existing-ongoing-issue-in-legal-insurance`;
const waitingPeriodsLink = `${faq.base}/en/articles/182702-are-there-any-waiting-periods-for-dental-insurance`;

export const faqData = (t: TFunction): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'dental.qnr.preQuote.quote.faq.eligible.question',
      'How does dental insurance work?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.eligible.answer', {
      defaultValue:
        "When you receive dental treatments, you'll have to pay for it upfront. Your public insurance might cover some of the treatment, but you’ll only be billed for what’s leftover. You just need to submit the signed bill in your Feather account under the “My policies” page. You’ll be reimbursed directly by us in 3 - 6 weeks depending on the treatment and amount of the claim.",
    }),
  },
  {
    questionId: 2,
    question: t(
      'dental.qnr.preQuote.quote.faq.reimbursement.question',
      'How are dental services reimbursed?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.reimbursement.answer', {
      defaultValue:
        'We reimburse the costs of dental services in line with the service costs outlined in the Fee Schedule for Dentists (Gebührenordnungen für Zahnärzte, GOZ), the standardize framework for how dental services are billed in Germany. We reimburse technical services (e.g. materials and labor costs) at reasonable rates.\n\nPersonal services: Up to 3.5 factor\n\nLaboratory service: Up to 1.3 factor\n\nMedical/Technical services: Up to 2.5 factor\n\nLearn more about how [severity factors]({{severityFactorLink}}) (Steigerungsfaktoren) work.',
      severityFactorLink,
    }),
  },
  {
    questionId: 3,
    question: t(
      'dental.qnr.preQuote.quote.faq.coverFamilyMembers.question',
      'Can I cover other family members as part of a dental insurance policy?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.coverFamilyMembers.answer', {
      defaultValue:
        'If you would like to insure other members of your family, such as your partner or any dependents, then you will need to [purchase an individual policy for each person]({{coverFamilyMembersLink}}). Dependents will need to have a parental guardian listed on the policy, and that will be asked during sign up.\n\nIf you’d like to manage multiple policies within your family under one Feather account, you can easily do so by purchasing each one using the same email address associated with your main Feather account.',
      coverFamilyMembersLink,
    }),
  },
  {
    questionId: 4,
    question: t(
      'dental.qnr.preQuote.quote.faq.preexistingTreatments.question',
      'Does supplemental dental insurance cover pre-existing treatments?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.preexistingTreatments.answer', {
      defaultValue:
        'No, dental insurance [won’t cover pre-existing or ongoing treatments, issues, or complaints]({{preexistingTreatmentsLink}}). Only treatments that are recommended by a dentist after the start of the policy are eligible for coverage.\n\nThe exception to this are dental cleanings. Since professional cleanings are always recommended by dentists as part of routine care, cleanings are never considered ongoing/pre-existing and are always eligible.',
      preexistingTreatmentsLink,
    }),
  },
  {
    questionId: 5,
    question: t(
      'dental.qnr.preQuote.quote.faq.waitingTimes.question',
      'Are there waiting times?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.waitingTimes.answer', {
      defaultValue:
        'Once you sign up for dental insurance, it will take [1 - 3 business days to process and approve your application]({{waitingPeriodsLink}}). We’ll send you an email as soon as your policy is active. Then you can start going to the dentist and receiving full benefits!',
      waitingPeriodsLink,
    }),
  },
  {
    questionId: 6,
    question: t(
      'dental.qnr.preQuote.quote.faq.cancellationPolicy.question',
      'What is your cancellation policy?'
    ),
    answer: t('dental.qnr.preQuote.quote.faq.cancellationPolicy.answer', {
      defaultValue:
        'Each policy period is 1 year from the official start date of your policy.\n\n\n#### Within your first policy period:\n\n* There is a 14-day right of withdrawal period that you may exercise should you wish to cancel your policy after purchase. We will refund the premiums paid into the policy and cancel the contract from the start. When exercising your right to withdrawal, any benefits received during this 14-day period must be returned.\n\n* If you have not received a reimbursement (dental claim payout) and request to cancel your dental plan, your policy will end at the end of the month you asked for its cancellation.\n\n *If you have already received a reimbursement (dental claim payout) and request to cancel within the first policy period, you must wait until the one-year contract period to officially cancel the plan.\n\n *If you move out of Germany and/or no longer hold public health insurance, you can cancel your policy immediately. In this case, you must provide proof such as "Abmeldung" or a termination document from your public health insurer.\n\n#### After the first policy period:\n\n* You can cancel the policy immediately (regardless if you’ve already received a claim reimbursement). The end date will be the final day of the month that you requested the cancellation.',
      waitingPeriodsLink,
    }),
  },
];
