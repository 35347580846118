import { TFunction } from '@getpopsure/i18n-react';
import {
  AlertTriangleIcon,
  ArthritisIcon,
  BriefcaseIcon,
  CalendarIcon,
  CloudLightningIcon,
  DentalToothTeethIcon,
  DoctorStethoscopeIcon,
  HospitalBuildingIcon,
  MedicationPillsIcon,
  PlaneFlightIcon,
  PregnacyIcon,
  SyringeVaccinationIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const travelHealth = (t: TFunction): AdditionalInfoType => ({
  price: '€25/year for individuals, €44/year for families',
  description: t(
    'policyModal.travelHealth.description',
    'This insurance covers medical expenses worldwide for an unlimited number of trips, each up to 56 days in duration. You can upgrade the coverage to include cancellations and luggage. It is visa-compliant and valid for travel to any country outside of Germany.'
  ),
  accordions: [
    {
      title: t('policyModal.travelHealth.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered1.question',
            'Dental care'
          ),
          answer: t(
            'policyModal.travelHealth.covered1.answer',
            'Pain-relieving dental treatment, simple fillings and repair of existing dentures.'
          ),
        },
        {
          id: 'covered-2',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered2.question',
            'Hospital treatments'
          ),
          answer: t(
            'policyModal.travelHealth.covered2.answer',
            'Medical treatment at the hospital in case of illness or accident.'
          ),
        },
        {
          id: 'covered-3',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered3.question',
            'Doctor’s visits'
          ),
          answer: t(
            'policyModal.travelHealth.covered3.answer',
            "Coverage for doctor's visits while traveling abroad in the event of illness or accident."
          ),
        },
        {
          id: 'covered-4',
          icon: <MedicationPillsIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered4.question',
            'Medical prescriptions'
          ),
          answer: t(
            'policyModal.travelHealth.covered4.answer',
            'Drugs, surgical dressings and aids prescribed to you by a doctor.'
          ),
        },
        {
          id: 'covered-5',
          icon: <PlaneFlightIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered5.question',
            'Rescue & repatriation'
          ),
          answer: t(
            'policyModal.travelHealth.covered5.answer',
            'Rescue costs, transport to and from hospital, repatriation of mortal remains or funeral costs.'
          ),
        },
        {
          id: 'covered-6',
          icon: <PregnacyIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered6.question',
            'Pregnancy complications'
          ),
          answer: t(
            'policyModal.travelHealth.covered6.answer',
            "You're covered in case of issues with your pregnancy or premature birth."
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title2', 'Upgrade'),
      items: [
        {
          id: 'addon-1',
          icon: <BriefcaseIcon size={24} />,
          question: t('policyModal.travelHealth.addon1.question', 'Luggage'),
          answer: t(
            'policyModal.travelHealth.addon1.answer',
            'Covers lost, stolen, and damaged luggage during the whole trip, if reported to the relevant authorities (police, airline company, hotel, etc.).'
          ),
        },
        {
          id: 'addon-2',
          icon: <PlaneFlightIcon size={24} />,
          question: t(
            'policyModal.travelHealth.addon2.question',
            'Trip cancellation and holiday guarantee'
          ),
          answer: t(
            'policyModal.travelHealth.addon2.answer',
            'Get additional coverage in case of cancellation, change, or termination of trips due to insured events. Reclaim expenses for canceled vacation days if they have to be terminated or postponed due to sickness or other covered incidents.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title3', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered1.question',
            'Pre-existing conditions'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered1.answer',
            'Treatments required for illness known prior to the start of the journey.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered2.question',
            'Advanced dental care'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered2.answer',
            'Implants, orthodontic treatment, cleaning, crowns, in-lays, splints and functional treatments are not covered.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <SyringeVaccinationIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered3.question',
            'Precautionary measures'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered3.answer',
            'Vaccinations, preventative blood tests and other precautionary treatments are not covered.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <AlertTriangleIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered4.question',
            'Traveling despite official warnings'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered4.answer',
            'Treatment is not covered if a travel warning was issued by the German government before departure.'
          ),
        },
        {
          id: 'not-covered-5',
          icon: <CloudLightningIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered5.question',
            'Cancellations done by third parties'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered5.answer',
            'Cancellations or delays caused by flight companies, tour operators, or hotels fall into their liability and are not covered.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title4', 'Important to know'),
      items: [
        {
          id: 'important-1',
          icon: <CalendarIcon size={24} />,
          question: t(
            'policyModal.travelHealth.important1.question',
            'You must sign up before traveling'
          ),
          answer: t(
            'policyModal.travelHealth.important1.answer',
            "Your policy has to start on the same day or before you start your trip and leave Germany. You won't be covered on the current trip if you have already left the country."
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: PlaneFlightIcon,
      title: t(
        'policyModal.travelHealth.details.title1',
        'Unlimited trips per year'
      ),
      description: t(
        'policyModal.travelHealth.details.description1',
        'You can use this policy for any number of trips that are up to 56 days'
      ),
    },
    {
      icon: WorldGlobeEarthIcon,
      title: t('policyModal.travelHealth.details.title2', 'Worldwide coverage'),
      description: t(
        'policyModal.travelHealth.details.description2',
        'Valid for any country outside Germany'
      ),
    },
  ],
  buttonText: t('policyModal.travelHealth.actionButton', 'Get covered'),
});
