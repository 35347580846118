import { TFunction } from '@getpopsure/i18n-react';
import { app, calendly, faq, insurance } from '@getpopsure/private-constants';
import { getAgeFromBirthYear } from '@getpopsure/private-health-insurance-pricing-engine';
import { Country, isEEACountry } from '@getpopsure/public-models';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Blocker } from 'components/Blocker';
import { Intro } from 'components/Intro';
import {
  PrivatePreSignup,
  PrivatePreSignupQuestionnaire,
} from 'features/privateHealthPreSignup/models';
import { shouldShowDependentVersion } from 'features/privateHealthPreSignup/util';
import { isUnder18 } from 'shared/util/isUnder18';

import { questionPropLookup } from '../lookups';
import {
  FREELANCER_UPPER_INCOME_THRESHOLD,
  getIncomeFollowUpScreen,
  OTHER_UPPER_INCOME_THRESHOLD,
} from './content';
import { SpouseInfo } from './SpouseInfo';

const containsEEACountry = (citizenshipCountries: Country[] = []) =>
  citizenshipCountries.some((country) => isEEACountry(country));

const birthYearToAgeGroupStr = (birthYear: number) => {
  const age = getAgeFromBirthYear(birthYear);
  if (age < 25) {
    return 'Under 25';
  }
  if (age > 35) {
    return 'Under 35';
  }

  return String(age);
};

export const preQuoteQNR = (
  t: TFunction,
  questionnaireAnswers: Partial<PrivatePreSignup>
): PrivatePreSignupQuestionnaire => ({
  welcome: {
    required: true,
    type: 'CUSTOM',
    groupId: 'signup',
    component: (props) =>
      Intro({
        ...props,
        title: t('private.qnr.welcome.title', 'Let’s get you covered'),
        description: t(
          'private.qnr.welcome.description',
          '100% digital signup. No medical exams needed in most cases 🎉'
        ),
      }),
    retrieveAnswerObject: (
      welcome: PrivatePreSignup['welcome']
    ): Partial<PrivatePreSignup> => ({
      welcome,
    }),
  },
  birthYear: {
    required: true,
    type: 'NUMBER',
    groupId: 'signup',
    props: {
      ...questionPropLookup(t).birthYear,
    },
    validateAnswer: (birthYear: number) => {
      if (isUnder18(birthYear)) {
        return t(
          'private.qnr.birthYear.error.under18',
          'You must be at least 18 years old in order to sign up for private health insurance.'
        );
      }
      if (birthYear < new Date().getFullYear() - 101) {
        return t(
          'private.qnr.birthYear.error.tooSmall',
          'Your year of birth lies outside of the allowed range.'
        );
      }
      return null;
    },
    retrieveAnswerObject: (
      birthYear: PrivatePreSignup['birthYear']
    ): Partial<PrivatePreSignup> => ({
      birthYear,
    }),
  },
  citizenship: {
    required: true,
    type: 'COUNTRY_MULTI',
    groupId: 'signup',
    props: {
      ...questionPropLookup(t).citizenship,
    },
    retrieveAnswerObject: (
      citizenship: PrivatePreSignup['citizenship']
    ): Partial<PrivatePreSignup> => ({
      citizenship,
    }),
    retrieveNextPageId: (citizenship: Country[]) =>
      containsEEACountry(citizenship)
        ? 'employmentStatus'
        : 'residencyPermitType',
    retrieveQuestionsToRemove: () => [
      'residencyPermitType',
      'relocatedMoreThan4YearsAgo',
    ],
  },
  residencyPermitType: {
    required: ({ citizenship }: Partial<PrivatePreSignup>) =>
      !containsEEACountry(citizenship),
    type: 'RADIO',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).residencyPermitType,
    },
    retrieveAnswerObject: (
      residencyPermitType: PrivatePreSignup['residencyPermitType']
    ): Partial<PrivatePreSignup> => ({
      residencyPermitType,
    }),
    retrieveNextPageId: (
      residencyPermitType: PrivatePreSignup['residencyPermitType']
    ) =>
      residencyPermitType === 'LIMITED_RESIDENCE_PERMIT'
        ? 'relocatedMoreThan4YearsAgo'
        : 'employmentStatus',
    retrieveQuestionsToRemove: () => ['relocatedMoreThan4YearsAgo'],
  },
  relocatedMoreThan4YearsAgo: {
    required: ({
      citizenship,
      residencyPermitType,
    }: Partial<PrivatePreSignup>) =>
      !containsEEACountry(citizenship) &&
      residencyPermitType === 'LIMITED_RESIDENCE_PERMIT',
    type: 'BOOLEAN',
    groupId: 'signup',
    props: {
      ...questionPropLookup(t).relocatedMoreThan4YearsAgo,
    },
    retrieveAnswerObject: (
      relocatedMoreThan4YearsAgo: PrivatePreSignup['relocatedMoreThan4YearsAgo']
    ): Partial<PrivatePreSignup> => ({
      relocatedMoreThan4YearsAgo,
    }),
    retrieveQuestionsToRemove: () => ['calculatePrice', 'quote'],
  },
  employmentStatus: {
    required: true,
    type: 'RADIO',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).employmentStatus,
    },
    retrieveAnswerObject: (
      employmentStatus: PrivatePreSignup['employmentStatus']
    ): Partial<PrivatePreSignup> => ({
      employmentStatus,
      eligibleForShortTerm:
        !containsEEACountry(questionnaireAnswers.citizenship) &&
        questionnaireAnswers.residencyPermitType ===
          'LIMITED_RESIDENCE_PERMIT' &&
        !questionnaireAnswers.relocatedMoreThan4YearsAgo,
    }),
    retrieveQuestionsToRemove: (
      newEmploymentStatus: PrivatePreSignup['employmentStatus']
    ) =>
      // Make sure to only remove answers when employmentStatus changed, not when eligibleForShortTerm changed
      newEmploymentStatus !== questionnaireAnswers.employmentStatus
        ? ['calculatePrice', 'quote']
        : [],
  },
  income: {
    required: true,
    type: 'CURRENCY',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).income,
    },
    retrieveAnswerObject: (
      income: PrivatePreSignup['income']
    ): Partial<PrivatePreSignup> => ({
      income,
    }),
    retrieveNextPageId: (income: PrivatePreSignup['income'], answers) =>
      getIncomeFollowUpScreen({
        income,
        employmentStatus: answers?.employmentStatus,
        birthYear: answers?.birthYear,
      }),
  },
  hanseMerkurRedirect: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'private.qnr.hanseMerkurRedirect.title',
          'We’ve got better options'
        ),
        description: t('private.qnr.hanseMerkurRedirect.description', {
          defaultValue:
            "Given that you're earning less than {{threshold}}, we have a slightly different coverage and tariff for you.",
          threshold: englishFormattedEuroCurrency(
            questionnaireAnswers.employmentStatus === 'OTHER'
              ? OTHER_UPPER_INCOME_THRESHOLD
              : FREELANCER_UPPER_INCOME_THRESHOLD,
            true
          ),
        }),
        buttonProps: [
          {
            type: 'href',
            href: `https://tally.so/r/mDzpBZ?ageGroup=${birthYearToAgeGroupStr(
              questionnaireAnswers.birthYear ?? 99
            )}`,
            caption: t(
              'private.qnr.hanseMerkurRedirect.button.1.caption',
              'Continue'
            ),
            size: 'WIDE',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  freelancerNotEligibleBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'private.qnr.freelancerNotEligibleBlocker.title',
          'You’re currently not eligible'
        ),
        description: t('private.qnr.freelancerNotEligibleBlocker.description', {
          defaultValue:
            'We are working hard to make sure everyone can be covered, but at the moment, you are not eligible for private health insurance.\n\nWe suggest using our [recommendation tool]({{recToolLink}}) to find out which health insurance you’re eligible for and what your best option is.',
          recToolLink: app.recommendationTool,
        }),
        buttonProps: [
          {
            type: 'href',
            href: app.recommendationTool,
            caption: t(
              'private.qnr.freelancerNotEligibleBlocker.button.1.caption',
              'Get a recommendation'
            ),
            size: 'WIDE',
          },
        ],
        iconType: 'PERSON',
        ...props,
      }),
    groupId: 'signup',
  },
  incomeUnderEmployeeThresholdBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'private.qnr.incomeUnderEmployeeThresholdBlocker.blockerTitle',
          "We've got better options!"
        ),
        description: t(
          'private.qnr.incomeUnderEmployeeThresholdBlocker.description',
          {
            defaultValue:
              'As an employed person, you need to earn more than {{yearlyEmployeeIncomeThreshold}} yearly to be able to sign up to private insurance. Instead you are eligible for [public health insurance]({{publicHealthLPLink}}).\n\nIf you are already on public health insurance and are looking to upgrade, we recommend [dental insurance]({{dentalLPLink}}) to top up your cover.',
            yearlyEmployeeIncomeThreshold: englishFormattedEuroCurrency(
              insurance.privateHealth.employeeThreshold,
              true
            ),
            publicHealthLPLink: insurance.publicHealth.landingPage,
            dentalLPLink: insurance.dental.landingPage,
          }
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.dental.landingPage,
            caption: t(
              'private.qnr.incomeUnderEmployeeThresholdBlocker.button.1.caption',
              'Check out dental insurance'
            ),
            size: 'WIDE',
          },
          {
            type: 'href',
            href: insurance.publicHealth.landingPage,
            caption: t(
              'private.qnr.incomeUnderEmployeeThresholdBlocker.button.2.caption',
              'See public health insurance'
            ),
            variant: 'SECONDARY',
            size: 'WIDE',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  familyCoverage: {
    required: true,
    type: 'RADIO',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).familyCoverage,
    },
    retrieveAnswerObject: (
      familyCoverage: PrivatePreSignup['familyCoverage']
    ): Partial<PrivatePreSignup> => ({
      familyCoverage,
    }),
    retrieveQuestionsToRemove: () => [
      'dependentTypes',
      'spouseHasOwnIncome',
      'spouseHasOwnIncomeInfo',
      'experiencedConditions',
      'numberOfPsychotherapySessions',
      'psychotherapyStatus',
      'calculatePrice',
      'quote',
    ],
  },
  dependentTypes: {
    required: ({ familyCoverage }: Partial<PrivatePreSignup>) =>
      familyCoverage === 'YES',
    type: 'CHECKBOX',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).dependentTypes,
    },
    retrieveAnswerObject: (
      dependentTypes: PrivatePreSignup['dependentTypes']
    ): Partial<PrivatePreSignup> => ({
      dependentTypes,
    }),
    retrieveQuestionsToRemove: () => [
      'spouseHasOwnIncome',
      'spouseHasOwnIncomeInfo',
      'experiencedConditions',
      'numberOfPsychotherapySessions',
      'psychotherapyStatus',
      'calculatePrice',
      'quote',
    ],
  },
  spouseHasOwnIncome: {
    required: ({ familyCoverage, dependentTypes }: Partial<PrivatePreSignup>) =>
      familyCoverage === 'YES' && !!dependentTypes?.includes('SPOUSE'),
    type: 'BOOLEAN',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t).spouseHasOwnIncome,
    },
    retrieveAnswerObject: (
      spouseHasOwnIncome: PrivatePreSignup['spouseHasOwnIncome']
    ): Partial<PrivatePreSignup> => ({
      spouseHasOwnIncome,
    }),
    retrieveNextPageId: (
      spouseHasOwnIncome: PrivatePreSignup['spouseHasOwnIncome']
    ) =>
      spouseHasOwnIncome &&
      !questionnaireAnswers.dependentTypes?.includes('CHILDREN')
        ? 'spouseHasOwnIncomeInfo'
        : 'experiencedConditions',
    retrieveQuestionsToRemove: () => [
      'spouseHasOwnIncomeInfo',
      'experiencedConditions',
      'numberOfPsychotherapySessions',
      'psychotherapyStatus',
      'calculatePrice',
      'quote',
    ],
  },
  spouseHasOwnIncomeInfo: {
    type: 'CUSTOM',
    groupId: 'signup',
    component: SpouseInfo,
  },
  experiencedConditions: {
    required: true,
    type: 'CHECKBOX',
    groupId: 'signup',
    props: {
      twoColumnLayout: true,
      noneCTACaption: 'None of these',
      mapValue: {},
      ...questionPropLookup(t, {
        showDependentVersion: shouldShowDependentVersion(questionnaireAnswers),
      }).experiencedConditions,
    },
    retrieveAnswerObject: (
      experiencedConditions: PrivatePreSignup['experiencedConditions']
    ): Partial<PrivatePreSignup> => ({
      experiencedConditions,
    }),
    retrieveNextPageId: (
      experiencedConditions: PrivatePreSignup['experiencedConditions']
    ) => {
      if (
        experiencedConditions.length === 1 &&
        experiencedConditions.includes('PSYCHOTHERAPY')
      ) {
        return 'numberOfPsychotherapySessions';
      }
      if (
        experiencedConditions.length === 1 &&
        (experiencedConditions.includes('PREP') ||
          experiencedConditions.includes('ASTHMA'))
      ) {
        return 'prepOrAsthmaOnlyBlocker';
      }
      if (
        experiencedConditions.length === 1 &&
        experiencedConditions.includes('NONE')
      ) {
        return 'calculatePrice';
      }

      return 'experiencedConditionsBlocker';
    },
    retrieveQuestionsToRemove: () => [
      'numberOfPsychotherapySessions',
      'psychotherapyStatus',
    ],
  },
  numberOfPsychotherapySessions: {
    required: ({ experiencedConditions = [] }: Partial<PrivatePreSignup>) =>
      experiencedConditions.length === 1 &&
      experiencedConditions.includes('PSYCHOTHERAPY'),
    type: 'RADIO',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t, {
        showDependentVersion: shouldShowDependentVersion(questionnaireAnswers),
      }).numberOfPsychotherapySessions,
    },
    retrieveAnswerObject: (
      numberOfPsychotherapySessions: PrivatePreSignup['numberOfPsychotherapySessions']
    ): Partial<PrivatePreSignup> => ({
      numberOfPsychotherapySessions,
    }),
    retrieveNextPageId: (
      numberOfPsychotherapySessions: PrivatePreSignup['numberOfPsychotherapySessions']
    ) => {
      switch (numberOfPsychotherapySessions) {
        case 'LESS_THAN_5':
          return 'calculatePrice';
        case 'BETWEEN_5_AND_25':
          return 'psychotherapyStatus';
        case 'MORE_THAN_25':
        default:
          return 'experiencedConditionsBlocker';
      }
    },
    retrieveQuestionsToRemove: () => ['psychotherapyStatus'],
  },
  psychotherapyStatus: {
    required: ({ numberOfPsychotherapySessions }: Partial<PrivatePreSignup>) =>
      numberOfPsychotherapySessions === 'BETWEEN_5_AND_25',
    type: 'RADIO',
    groupId: 'signup',
    props: {
      mapValue: {},
      ...questionPropLookup(t, {
        showDependentVersion: shouldShowDependentVersion(questionnaireAnswers),
      }).psychotherapyStatus,
    },
    retrieveAnswerObject: (
      psychotherapyStatus: PrivatePreSignup['psychotherapyStatus']
    ): Partial<PrivatePreSignup> => ({
      psychotherapyStatus,
    }),
    retrieveNextPageId: (
      psychotherapyStatus: PrivatePreSignup['psychotherapyStatus']
    ) =>
      psychotherapyStatus === 'ENDED_MORE_THAN_5_YEARS_AGO'
        ? 'calculatePrice'
        : 'experiencedConditionsBlocker',
  },
  experiencedConditionsBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'private.qnr.experiencedConditionsBlocker.heading',
          'You’re not eligible for private health insurance'
        ),
        description: t('private.qnr.experiencedConditionsBlocker.description', {
          defaultValue:
            'All German private insurance providers admit or deny coverage based on previous medical conditions and treatments. With the information you’ve provided, this coverage is not an option. We [wrote an article]({{articleLink}}) where you can learn more about this system and why the medical information must be disclosed.\n\nIf you have any ongoing health issues, we strongly recommend signing up for [public health insurance]({{publicHealthLPLink}}) instead. If you are already on public health insurance and are looking for a better dental coverage, we recommend [dental insurance]({{dentalLPLink}}) to top up your cover.',
          articleLink: faq.privateHealthPreExistingConditions,
          publicHealthLPLink: insurance.publicHealth.landingPage,
          dentalLPLink: insurance.dental.landingPage,
        }),
        buttonProps: [
          {
            type: 'href',
            href: app.recommendationTool,
            caption: t(
              'private.qnr.experiencedConditionsBlocker.button.2.caption',
              'Use the recommendation tool'
            ),
            size: 'WIDE',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
  prepOrAsthmaOnlyBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'private.qnr.prepOrAsthmaOnlyBlocker.heading',
          "Let's have a chat!"
        ),
        description: t('private.qnr.prepOrAsthmaOnlyBlocker.description', {
          defaultValue:
            "With certain health conditions, getting private health insurance can be difficult.\n\nWe are here to help. Based on the health conditions you've shared, we would like to talk to you to assess your eligibility. You're welcome to book a call with our expert to receive advice tailored to your needs",
        }),
        buttonProps: [
          {
            type: 'href',
            href: `${calendly.base}/conditions`,
            caption: t(
              'private.qnr.prepOrAsthmaOnlyBlocker.button.2.caption',
              'Book a call'
            ),
            size: 'WIDE',
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'signup',
  },
});
