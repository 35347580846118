import { Region } from '@getpopsure/public-models';
import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import { flushGenericQuestionnaire } from 'actions/genericQuestionnaire';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { InsuranceTypes } from 'models/insurances/types';
import { Policy } from 'models/policies';
import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

import { PreviewCheckout } from './components/Checkout/PreviewCheckout';
import { GeneralPreviewGenericCheckout } from './components/GenericCheckout/GeneralPreviewGenericCheckout';
import { PreviewQuote } from './components/Quote/PreviewQuote';
import { RegionManager } from './components/RegionManager';
import { PreviewReview } from './components/Review/PreviewReview';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';
import { interpolateData } from './functions';
import { QuestionnaireJSON } from './model';

const basePath = routes.policies.general.preview.path;
const questionnairePath = routes.policies.general.preview.questionnaire.path;
const noRegionPath = routes.policies.general.preview.noRegion.path;

const strapiHeaders = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
};

export const PreviewSignupPage = () => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();

  const dispatch = useDispatch();

  const [answers, setAnswers] = useState<QuestionnaireQuestions>({});
  const [json, setJson] = useState<QuestionnaireJSON | false>();
  const [sharedConfig, setSharedConfig] = useState<Record<string, unknown>>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const fetchSharedConfig = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/shared-config?populate=deep`,
        strapiHeaders
      );

      if (!response.ok) {
        const erroredResponse = await response.json();
        throw new Error(
          erroredResponse.error.message ||
            'Failed to fetch shared config from Strapi'
        );
      }

      const sharedConfigData = await response.json();

      if (!sharedConfigData) {
        setSharedConfig({});
        throw new Error('Failed to get shared config from Strapi');
      }

      setSharedConfig(sharedConfigData);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setSharedConfig({});
    }
  }, []);

  const fetchQuestionnaire = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/questionnaires/${verticalId}?populate=deep`,
        strapiHeaders
      );

      if (!response.ok) {
        const erroredResponse = await response.json();
        throw new Error(
          erroredResponse.error.message ||
            'Failed to fetch questionnaire from Strapi'
        );
      }

      const questionnaire = await response.json();

      if (!questionnaire.meta || !questionnaire.questions) {
        setJson(false);
        throw new Error(
          'Fetched Strapi questionnaire is missing "meta" or "questions" field'
        );
      }

      setJson({
        ...questionnaire,
        removeAnswersLogic: {},
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setJson(false);
    }
  }, [verticalId]);

  useEffect(() => {
    fetchSharedConfig();
    fetchQuestionnaire();
  }, [fetchQuestionnaire, fetchSharedConfig, verticalId]);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (errorMessage || !json) {
    return (
      <ErrorWithAction
        title="Questionnaire error"
        description={errorMessage ?? ''}
      />
    );
  }

  const mainPolicy: Policy = {
    id: '123',
    type: json.meta.insuranceType as InsuranceTypes,
    applicationId: '12345',
    providerId: 'ProviderId',
    providerName: 'AOK',
    status: 'ACTIVE',
    userUploads: [],
    attributes: {
      policyNumber: '1234567890',
      SVNR: '1234567890',
      insuredPerson: {
        name: { firstName: 'InsuredFirstName', lastName: 'InsuredLastName' },
      },
    },
    documents: [
      {
        title: 'Important document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
      {
        title: 'My other document',
        downloadURL: 'https://cdn.getpopsure.com/document.pdf',
      },
    ],
    claims: [],
  };

  const interpolatedJSON = interpolateData<QuestionnaireJSON>(json, {
    answers,
    constants: {
      ...sharedConfig,
      ...json.meta.constants,
    },
    mainPolicy: json.meta.isDependent ? mainPolicy : undefined,
  });

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={questionnairePath}
      questionnaireMeta={json.meta}
    >
      <ErrorBoundary
        fallbackRender={() => (
          <ErrorWithAction
            title="Questionnaire data error"
            description="The questionnaire answers do not match the questions. Some questions have changed"
            cta={{
              title: 'Reset data',
              onClick: () => {
                dispatch(
                  flushGenericQuestionnaire(
                    verticalId as keyof GenericQuestionnaireState
                  )
                );

                window.location.reload();
              },
            }}
          />
        )}
      >
        <SignupQuestionnaire
          basePath={basePath}
          questionnairePath={questionnairePath}
          data={interpolatedJSON}
          components={{
            REVIEW: PreviewReview,
            CHECKOUT: PreviewCheckout,
            QUOTE: PreviewQuote,
            GENERIC_CHECKOUT: GeneralPreviewGenericCheckout,
          }}
          regionId={regionId}
          verticalId={verticalId}
          onQuestionnaireAnswersChanged={setAnswers}
        />
      </ErrorBoundary>
    </RegionManager>
  );
};
