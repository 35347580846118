import { allLocales, detectLocale, init } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { cookieDomainOptions, getI18nOptions, getTranslations } from 'locales';
import { isMobileApp } from 'shared/util/isMobileApp';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import pkg from '../package.json';

export const initI18n = async () => {
  const translations = await getTranslations();
  const isOtaEnabled = Cookies.get('otaEnabled') === 'true';

  init({
    debug: isStagingOrDev,
    partialBundledLanguages: true,
    ns: [],
    phrase: {
      projectId: 'd65143b60b56ebec0b18595d2957fc8b',
      accountId: 'a3bede45c3de7842738aad10ad418b4c',
      allowInContextEditor: isStagingOrDev,
      inContextEditorOptions: {
        autoLowercase: false,
      },
    },
    supportedLngs: isMobileApp
      ? ['en']
      : [...allLocales, ...(isStagingOrDev ? ['xx', 'cimode'] : [])],
    fallbackLng: 'en',
    resources: isOtaEnabled ? {} : translations,
    saveMissing: true,
    lng: detectLocale({
      allowDebugLanguages: isStagingOrDev,
      ...cookieDomainOptions,
    }),
    ...(isStagingOrDev
      ? {
          backend: {
            distribution: process.env.REACT_APP_PHRASE_OTA_DISTRIBUTION_ID,
            secret: process.env.REACT_APP_PHRASE_OTA_DISTRIBUTION_SECRET,
            appVersion: pkg.version,
            cacheExpirationTime: 30,
          },
        }
      : {}),
    interpolation: {
      escapeValue: false,
    },
    missingKeyHandler: (_lng, _ns, key) => {
      const { translationSource } = getI18nOptions();

      if (translationSource !== 'CODE') {
        return;
      }

      if (isStagingOrDev) {
        // eslint-disable-next-line no-console
        console.info(`[Translation Log] Missing key: ${key}`);
      }

      if (process.env.NODE_ENV !== 'development') {
        Sentry.captureException(
          `[Translation Sentry] Missing translation keys`,
          {
            tags: { feature: 'i18n' },
            extra: { missingKey: key },
          }
        );
      }
    },
  });
};
