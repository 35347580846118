import { TFunction } from '@getpopsure/i18n-react/node_modules/react-i18next';
import {
  AddOns,
  EmploymentStatus,
  Insurer,
  insurers,
  NumberOfPreviousClaims,
  Plan,
  TerminationParty,
  YearsSinceLastClaim,
} from '@getpopsure/legal-insurance-pricing-engine';
import { Address, Gender } from '@getpopsure/public-models';
import type { RequestAction } from 'constants/actions';
import { CheckoutInfo } from 'features/checkout/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { Name } from 'models/user';
import type { AppState } from 'reducers';
import type { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkDispatch } from 'redux-thunk';
import {
  ZAddressSchema,
  ZGenderSchema,
  ZNameSchema,
} from 'shared/models/validations';
import type { BLOCKER } from 'SignupQuestionnaire';
import { z } from 'zod';

import { preQuoteQuestions } from './questionnaire/translations';

export type InsurerDetails = {
  value: Insurer;
  label: Insurer;
};

export type QuoteInfo = {
  questionnaireId: string;
  quoteId: string;
  plan: Plan;
  addons: AddOns[];
  price?: number;
  basePremium?: number;
  deductible?: number;
  addonsPrice?: Record<string, number>;
};

export type Legal = {
  intro: boolean;
  dateOfBirth: string;
  under18Blocker?: BLOCKER;
  postcode: string;
  includeSpouse: boolean;
  employmentStatus: EmploymentStatus;
  selfEmployedNotice?: boolean;
  hadGermanLegalInsurance: boolean;
  insurer?: InsurerDetails;
  numberOfPreviousClaims?: NumberOfPreviousClaims;
  numberOfPreviousClaimsBlocker?: BLOCKER;
  yearsSinceLastClaim: YearsSinceLastClaim;
  terminationParty?: TerminationParty;
  terminationPartyBlocker?: BLOCKER;
  activeInsuranceBlocker?: BLOCKER;
  endDateOfPreviousInsurance?: string;
  preQuoteReview: boolean;
  processing: boolean;
  quote: Partial<QuoteInfo>;
  signupIntro: boolean;
  hasCriminalConvictions?: boolean;
  hasCriminalConvictionsBlocker?: BLOCKER;
  hasPendingCriminalInvestigation?: boolean;
  hasPendingCriminalInvestigationBlocker?: BLOCKER;
  email: string;
  name: Name;
  gender: Gender;
  address: Address;
  partnerName: Name;
  startDate: string;
  review: boolean;
  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  quoteId: string;
  questionnaireId: string;
};

export type Questionnaire = PartialQuestionnaire<Legal, GroupId>;
export type QuestionId = keyof Legal;

export type GroupId = 'preQuote' | 'signup';

export type CheckoutPolicyDetails = {
  deductible: number;
  provider: string;
  type: string;
  partnerName?: Name;
  basePremium: number;
  addons: Record<Plan, number>;
};

export const employmentStatuses: EmploymentStatus[] = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'LOOKING_FOR_JOB',
  'CIVIL_PUBLIC_SERVANT',
  'RETIRED',
];

export const employmentStatusMapping = (
  t: TFunction
): Record<
  Exclude<
    EmploymentStatus,
    'UNKNOWN_PROFESSION' | 'JOB_SEARCHING' | 'FREELANCER'
  >,
  string
> => ({
  SELF_EMPLOYED: t(
    'legal.qnr.preQuote.employmentStatus.answer.selfEmployed',
    'Self-employed'
  ),
  EMPLOYED: t(
    'legal.qnr.preQuote.employmentStatus.answer.employed',
    'Employed'
  ),
  STUDENT: t('legal.qnr.preQuote.employmentStatus.answer.student', 'Student'),
  CIVIL_PUBLIC_SERVANT: t(
    'legal.qnr.preQuote.employmentStatus.answer.civilPublicServant',
    'Civil / Public Servant'
  ),
  LOOKING_FOR_JOB: t(
    'legal.qnr.preQuote.employmentStatus.answer.jobSearching',
    'Looking for a job'
  ),
  RETIRED: t('legal.qnr.preQuote.employmentStatus.answer.retired', 'Retired'),
});

export const numberOfPreviousClaims: NumberOfPreviousClaims[] = [
  'NONE',
  'ONE',
  'TWO',
  'THREE',
  'MORE_THAN_THREE',
];

export const numberOfPreviousClaimsMapping = (
  t: TFunction
): {
  [k in NumberOfPreviousClaims]: string;
} => ({
  NONE: t('legal.qnr.preQuote.numberOfPreviousClaims.answer.none', 'None'),
  ONE: t('legal.qnr.preQuote.numberOfPreviousClaims.answer.1claim', '1 claim'),
  TWO: t(
    'legal.qnr.preQuote.numberOfPreviousClaims.answer.2claims',
    '2 claims'
  ),
  THREE: t(
    'legal.qnr.preQuote.numberOfPreviousClaims.answer.3claims',
    '3 claims'
  ),
  MORE_THAN_THREE: t(
    'legal.qnr.preQuote.numberOfPreviousClaims.answer.moreThanThree',
    'More than 3 claims'
  ),
});

export const yearsSinceLastClaimMapping = (
  t: TFunction
): Record<YearsSinceLastClaim, string> => ({
  LESS_THAN_ONE_YEAR: t(
    'legal.qnr.preQuote..yearsSinceLastClaim.answer.lessThanOneYear',
    'Less than a year'
  ),
  ONE_YEAR: t(
    'legal.qnr.preQuote..yearsSinceLastClaim.answer.oneYear',
    '1 year'
  ),
  TWO_YEARS: t(
    'legal.qnr.preQuote..yearsSinceLastClaim.answer.twoYears',
    '2 years'
  ),
  THREE_OR_MORE_YEARS: t(
    'legal.qnr.preQuote..yearsSinceLastClaim.answer.threeOrMoreYears',
    '3 or more years'
  ),
});

export const terminationParty: TerminationParty[] = [
  'MYSELF',
  'INSURER',
  'STILL_ACTIVE',
];

export const terminationPartyMapping = (
  t: TFunction
): {
  [k in TerminationParty]: string;
} => ({
  MYSELF: t('legal.qnr.preQuote.terminationParty.answer.myself', 'I did'),
  INSURER: t(
    'legal.qnr.preQuote.terminationParty.answer.insurer',
    'My insurer did'
  ),
  STILL_ACTIVE: t(
    'legal.qnr.preQuote.terminationParty.answer.stillActive',
    'My contract is still active'
  ),
});

export const genderMapping = (t: TFunction): { [k in Gender]: string } => ({
  MALE: t('models.gender.answer.male', 'Male'),
  FEMALE: t('models.gender.answer.female', 'Female'),
  OTHER: t('models.gender.answer.genderNonConforming', 'Gender non-conforming'),
});

export const insuranceHistoryReviewQuestions: QuestionId[] = [
  'dateOfBirth',
  'postcode',
  'includeSpouse',
  'employmentStatus',
  'hadGermanLegalInsurance',
  'insurer',
  'numberOfPreviousClaims',
  'terminationParty',
  'endDateOfPreviousInsurance',
];

export type Quote = {
  planId: Plan;
  price: number;
};

export type Coverage = 'PRIVATE_LIFE' | 'ON_THE_MOVE' | 'WORK_LIFE' | 'AT_HOME';

export const personalInfoReviewQuestions: QuestionId[] = [
  'email',
  'name',
  'dateOfBirth',
  'gender',
  'address',
  'partnerName',
  'startDate',
];

export const reviewLabelMapping = (t: TFunction) => ({
  email: t('legal.qnr.signup.review.email', 'Email address'),
  name: t('legal.qnr.signup.review.name', 'Policy holder'),
  dateOfBirth: t('legal.qnr.signup.review.dateOfBirth', 'Date of birth'),
  gender: t('legal.qnr.signup.review.gender', 'Gender'),
  address: t('legal.qnr.signup.review.address', 'Address in Germany'),
  partnerName: t(
    'legal.qnr.signup.review.partnerName',
    "Spouse or partner's name"
  ),
  startDate: t('legal.qnr.signup.review.startDate', 'Coverage start date'),
});

export const ZSubmittableLegalQuestionnaire = z
  .object({
    name: ZNameSchema,
    email: z.string(),
    gender: ZGenderSchema,
    dateOfBirth: z.string(),
    address: ZAddressSchema,
    startDate: z.string(),
    postcode: z.string(),
    includeSpouse: z.boolean(),
    employmentStatus: z.enum([
      'EMPLOYED',
      'SELF_EMPLOYED',
      'STUDENT',
      'LOOKING_FOR_JOB',
      'CIVIL_PUBLIC_SERVANT',
      'RETIRED',
    ]),
    hadGermanLegalInsurance: z.boolean(),
    numberOfPreviousClaims: z.optional(
      z.enum(['NONE', 'ONE', 'TWO', 'THREE', 'MORE_THAN_THREE'])
    ),
    yearsSinceLastClaim: z.optional(
      z.enum([
        'LESS_THAN_ONE_YEAR',
        'ONE_YEAR',
        'TWO_YEARS',
        'THREE_OR_MORE_YEARS',
      ])
    ),
    insurer: z.optional(z.enum(insurers as [string, ...string[]])),
    endDateOfPreviousInsurance: z.optional(z.string()),
    terminationParty: z.optional(z.enum(['MYSELF', 'INSURER', 'STILL_ACTIVE'])),
    partnerName: z.optional(ZNameSchema),
  })
  .transform(
    ({
      name,
      email,
      gender,
      dateOfBirth,
      address,
      startDate,
      postcode,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      numberOfPreviousClaims,
      yearsSinceLastClaim,
      includeSpouse,
      hadGermanLegalInsurance,
      insurer,
      endDateOfPreviousInsurance,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      terminationParty,
      partnerName,
      employmentStatus,
    }) => ({
      personalInfo: {
        name,
        email,
        gender,
        dateOfBirth,
        address,
        startDate,
      },
      tariffInfo: {
        postcode,
        includeSpouse,
        employmentStatus,
        hadGermanLegalInsurance,
        insurer,
        numberOfPreviousClaims,
        yearsSinceLastClaim,
        terminationParty,
        endDateOfPreviousInsurance,
        partnerName,
      },
    })
  );

export type SubmittableLegalQuestionnaire = z.TypeOf<
  typeof ZSubmittableLegalQuestionnaire
>;

export type SubmitQuestionnaireLabels = {
  labels: {
    title: {
      personalInfo: {
        name: string;
        email: string;
        gender: string;
        address: string;
        startDate: string;
        dateOfBirth: string;
      };
      tariffInfo: {
        postcode: string;
        includeSpouse: string;
        employmentStatus: string;
        hadGermanLegalInsurance: string;
        insurer: string;
        numberOfPreviousClaims: string;
        terminationParty: string;
        endDateOfPreviousInsurance: string;
        partnerName: string;
        propertyOwnership: string;
        yearsSinceLastClaim: string;
      };
    };
    answers: {
      personalInfo: {
        gender: Record<Gender, string>;
      };
      tariffInfo: {
        employmentStatus: Record<
          Exclude<
            EmploymentStatus,
            'UNKNOWN_PROFESSION' | 'JOB_SEARCHING' | 'FREELANCER'
          >,
          string
        >;
        numberOfPreviousClaims: Record<NumberOfPreviousClaims, string>;
        yearsSinceLastClaim: Record<YearsSinceLastClaim, string>;
        terminationParty: Record<TerminationParty, string>;
      };
    };
  };
  questionOrder: {
    personalInfo: QuestionId[];
    tariffInfo: QuestionId[];
  };
};

export const getLabels = (t: TFunction): SubmitQuestionnaireLabels => ({
  labels: {
    title: {
      personalInfo: {
        address: preQuoteQuestions(t).address,
        name: preQuoteQuestions(t).name,
        email: preQuoteQuestions(t).email,
        gender: preQuoteQuestions(t).gender,
        dateOfBirth: preQuoteQuestions(t).dateOfBirth,
        startDate: preQuoteQuestions(t).startDate,
      },
      tariffInfo: {
        employmentStatus: preQuoteQuestions(t).employmentStatus,
        endDateOfPreviousInsurance:
          preQuoteQuestions(t).endDateOfPreviousInsurance,
        hadGermanLegalInsurance: preQuoteQuestions(t).hadGermanLegalInsurance,
        includeSpouse: preQuoteQuestions(t).includeSpouse,
        insurer: preQuoteQuestions(t).insurer,
        numberOfPreviousClaims: preQuoteQuestions(t).numberOfPreviousClaims,
        partnerName: preQuoteQuestions(t).partnerName,
        postcode: preQuoteQuestions(t).postcode,
        terminationParty: preQuoteQuestions(t).terminationParty,
        yearsSinceLastClaim: preQuoteQuestions(t).yearsSinceLastClaim,
        propertyOwnership: preQuoteQuestions(t).propertyOwnership,
      },
    },
    answers: {
      personalInfo: {
        gender: genderMapping(t),
      },
      tariffInfo: {
        employmentStatus: employmentStatusMapping(t),
        numberOfPreviousClaims: numberOfPreviousClaimsMapping(t),
        yearsSinceLastClaim: yearsSinceLastClaimMapping(t),
        terminationParty: terminationPartyMapping(t),
      },
    },
  },
  questionOrder: {
    personalInfo: personalInfoReviewQuestions,
    tariffInfo: insuranceHistoryReviewQuestions,
  },
});

export const ZSubmittableLegalQuote = z
  .object({
    dateOfBirth: z.string(),
    startDate: z.string(),
    postcode: z.string(),
    includeSpouse: z.boolean(),
    employmentStatus: z.enum([
      'EMPLOYED',
      'SELF_EMPLOYED',
      'STUDENT',
      'LOOKING_FOR_JOB',
      'CIVIL_PUBLIC_SERVANT',
      'RETIRED',
    ]),
    hadGermanLegalInsurance: z.boolean(),
    numberOfPreviousClaims: z.optional(
      z.enum(['NONE', 'ONE', 'TWO', 'THREE', 'MORE_THAN_THREE'])
    ),
    insurer: z.optional(z.enum(insurers as [string, ...string[]])),
    endDateOfPreviousInsurance: z.optional(z.string()),
    terminationParty: z.optional(z.enum(['MYSELF', 'INSURER', 'STILL_ACTIVE'])),
    partnerName: z.optional(ZNameSchema),
    plan: z.enum(['BASIC', 'ADVANCED']),
    addons: z.array(z.enum(['PROPERTY', 'CRIMINAL'])),
    price: z.number().optional(),
    basePremium: z.number().optional(),
    deductible: z.number().optional(),
    addonsPrice: z.record(z.string(), z.number()).optional(),
  })
  .transform(({ includeSpouse, employmentStatus, ...props }) => ({
    ...props,
    familyStatus: includeSpouse ? 'FAMILY' : 'SINGLE',
    profession: employmentStatus,
  }));

export type SubmittableLegalQuote = z.TypeOf<typeof ZSubmittableLegalQuote>;

export type LegalSubmissionDispatch = ThunkDispatch<
  AppState,
  unknown,
  RequestAction | GenericQuestionnaireAction<'legal'>
>;
