import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { CurrencyInput, IbanInput } from '@popsure/dirty-swan';
import { PayoutDetails as PayoutDetailsType } from 'features/expatClaims/models';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

const MIN_AMOUNT = 0.01;

const validate = (input: {
  amount?: number;
  iban?: string;
}): input is PayoutDetailsType => {
  return (
    Boolean(input.amount) &&
    Number(input.amount) >= MIN_AMOUNT &&
    Boolean(input.iban)
  );
};

export const PayoutDetails = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
  value,
}: QuestionnaireFormProps<Questionnaire, PayoutDetailsType>) => {
  const [validationError, setValidationError] = useState('');

  const [payoutDetails, setPayoutDetails] = useState<PayoutDetailsType>(
    (value as PayoutDetailsType) ?? { amount: '', iban: '' }
  );

  const { t } = useSafeTranslation();
  const isValid = validate(payoutDetails);

  useEffect(() => {
    setValidForSubmission(isValid);
  }, [isValid, setValidForSubmission, payoutDetails]);

  const handleSubmit = () => {
    if (!isValidIban(payoutDetails.iban)) {
      setValidationError('Enter a valid IBAN');
      return;
    }
    if (!isValid) {
      setValidationError(
        'Amount and/or IBAN values are missing for submission'
      );
      return;
    }
    onSubmitValue(payoutDetails);
  };

  return (
    <Header onSubmit={handleSubmit}>
      <div>
        <h1 className="p-h1 mb16">
          {t('claims.expatEu.payoutDetails.title', 'Payout details')}
        </h1>
        <h4 className="p-h4 mb8">
          {t('claims.expatEu.payoutDetails.amount.title', 'Claim amount')}
        </h4>
        <CurrencyInput
          placeholder={t(
            'claims.expatEu.payoutDetails.amount.placeholder',
            'Amount'
          )}
          value={payoutDetails.amount}
          onChange={(newValue: number) => {
            setPayoutDetails({ ...payoutDetails, amount: newValue });
          }}
          min={MIN_AMOUNT}
          data-cy="input-currency"
        />
      </div>

      <div className="mt24">
        <h4 className="p-h4 mb8">
          {t('claims.expatEu.payoutDetails.iban.title', 'Your IBAN')}
        </h4>
        <p className="p-p--small tc-grey-600 mb8">
          {t(
            'claims.expatEu.payoutDetails.iban.description',
            "The IBAN of your account where you'll receive the payout if approved"
          )}
        </p>
        <IbanInput
          placeholder={t(
            'claims.expatEu.payoutDetails.iban.placeholder',
            'IBAN'
          )}
          value={payoutDetails.iban}
          onChange={(newValue) => {
            setPayoutDetails({
              ...payoutDetails,
              iban: newValue,
            });
          }}
          data-cy="input-iban"
        />
      </div>
      <AnimateHeight duration={300} height={validationError ? 'auto' : 0}>
        <div className="p-notice p-notice--warning mt16">{validationError}</div>
      </AnimateHeight>
    </Header>
  );
};
