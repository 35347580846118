import {
  allCountryCodes,
  Country,
  Gender,
  universities,
} from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n';
import { z } from 'zod';

const Occupations = [
  'UNEMPLOYED',
  'SELF_EMPLOYED',
  'STUDENT',
  'HAVE_A_JOB_OFFER',
  'EMPLOYED',
  'PROFESSIONAL_ATHLETE',
  'OTHER',
] as const;

export type Occupation = typeof Occupations[number];

const StudentTypes = [
  'RECOGNIZED_UNIVERSITY',
  'LANGUAGE_STUDENT',
  'OTHER',
] as const;

export type StudentType = typeof StudentTypes[number];

export const assertedUniversities = [...universities] as const;

export type University = typeof assertedUniversities[number];

export type Quote = {
  id: string;
  price: number;
  planId: QuotePlan;
};

export type QuotePlan = 'PREMIUM' | 'BASIC';

export type QuestionnaireQuote = {
  options: Quote[];
  selectedQuote?: Quote;
};

export type ExpatQuestionnaire = {
  welcome: boolean;
  isPolicyHolder: boolean;
  arrivalDate: string;
  dateOfBirth: string;
  occupation: Occupation;
  name: Name;
  employedAnnualIncome: number;
  selfEmployedAnnualIncome: number;
  employmentStartDate: Date;
  typeOfStudent: StudentType;
  universityName: string;
  seventyFiveAndOverBlocker: boolean;
  moreThanFiveYearsInGermanyBlocker: boolean;
  betweenFourAndFiveYearsInGermanyBlocker: boolean;
  aboveThresholdSelfEmployedBlocker: boolean;
  withinThresholdEmployedBlocker: boolean;
  aboveThresholdEmployedBlocker: boolean;
  aboveThresholdOrOver55EmployedBlocker: boolean;
  under30StudentBlocker: boolean;
  studentBlocker: boolean;
  isProfessionalAthleteBlocker: boolean;
  isProfessionalDancerBlocker: boolean;
  dependent: Partial<ExpatDependentQuestionnaire>;
  isProfessionalDancer: boolean;

  processing: boolean;
  quote: QuestionnaireQuote;

  account: boolean;
  email: string;
  gender: Gender;
  hasPublicInsurance: boolean;
  germanCitizenInsuredInGermanyBeforeBlocker: boolean;
  citizenship: Country[];
  lastPermanentResidency: Country;
  startDate: string;
  addDependents: boolean;

  questionnaireId: string;
  quoteId?: string;
  review: boolean;

  checkoutInfo: CheckoutInfo;
  reviewCheckout: boolean;
  checkout: boolean;

  parentGuardianName: Name;
  parentGuardianDateOfBirth: string;
  parentGuardianEmail: string;
};

export type ExpatDependentQuestionnaire = Omit<
  ExpatQuestionnaire,
  'dependents' | 'email' | 'addDependents'
> & {
  mainPolicyId: string;
  isMainPolicyTheLegalGuardian: boolean;
};

export interface PreQuoteQuestionnaire {
  dateOfBirth: string;
}

export const occupationMapping = (
  t: TFunction
): Record<Occupation, string | { title: string; description: string }> => ({
  UNEMPLOYED: t('expat.qnr.occupationMapping.unemployed', 'Unemployed'),
  SELF_EMPLOYED: {
    title: t('expat.qnr.occupationMapping.selfEmployed.title', 'Self-employed'),
    description: t(
      'expat.qnr.occupationMapping.selfEmployed.description',
      'or employed by a company outside Germany'
    ),
  },
  EMPLOYED: t('expat.qnr.occupationMapping.employed', 'Employed in Germany'),
  HAVE_A_JOB_OFFER: t(
    'expat.qnr.occupationMapping.jobOffer',
    'I have a job offer'
  ),
  STUDENT: t('expat.qnr.occupationMapping.student', 'Student'),
  PROFESSIONAL_ATHLETE: {
    title: t(
      'expat.qnr.occupationMapping.professionalAthlete.title',
      'Professional athlete'
    ),
    description: t(
      'expat.qnr.occupationMapping.professionalAthlete.description',
      'also includes professional dancers'
    ),
  },
  OTHER: t('expat.qnr.occupationMapping.otherOption', 'Other'),
});

export const dependentOccupationMapping = (
  t: TFunction
): Record<Occupation, string | { title: string; description: string }> => ({
  UNEMPLOYED: t('expat.qnr.occupationMapping.unemployed', 'Unemployed'),
  SELF_EMPLOYED: {
    title: t('expat.qnr.occupationMapping.selfEmployed.title', 'Self-employed'),
    description: t(
      'expat.qnr.occupationMapping.selfEmployed.description',
      'or employed by a company outside Germany'
    ),
  },
  EMPLOYED: t('expat.qnr.occupationMapping.employed', 'Employed in Germany'),
  HAVE_A_JOB_OFFER: t(
    'expat.qnr.dependentPreQuote.occupationMapping.jobOffer',
    'They have a job offer'
  ),
  STUDENT: t('expat.qnr.occupationMapping.student', 'Student'),
  PROFESSIONAL_ATHLETE: {
    title: t(
      'expat.qnr.occupationMapping.professionalAthlete.title',
      'Professional athlete'
    ),
    description: t(
      'expat.qnr.occupationMapping.professionalAthlete.description',
      'also includes professional dancers'
    ),
  },
  OTHER: t('expat.qnr.occupationMapping.otherOption', 'Other'),
});

export const studentMapping = (t: TFunction): Record<StudentType, string> => ({
  RECOGNIZED_UNIVERSITY: t(
    'expat.qnr.studentMapping.recognizedUniversity',
    'At a university in Germany'
  ),
  LANGUAGE_STUDENT: t(
    'expat.qnr.studentMapping.languageStudent',
    "I'm a language student"
  ),
  OTHER: t('expat.qnr.studentMapping.otherOption', 'Somewhere else'),
});

export const dependentStudentMapping = (
  t: TFunction
): Record<StudentType, string> => ({
  RECOGNIZED_UNIVERSITY: t(
    'expat.qnr.studentMapping.recognizedUniversity',
    'At a university in Germany'
  ),
  LANGUAGE_STUDENT: t(
    'expat.qnr.dependentPreQuote.studentType.languageStudent',
    "They're a language student"
  ),
  OTHER: t('expat.qnr.studentMapping.otherOption', 'Somewhere else'),
});

export const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('expat.qnr.signup.gender.male', 'Male'),
  FEMALE: t('expat.qnr.signup.gender.female', 'Female'),
  OTHER: t('expat.qnr.signup.gender.nonconforming', 'Gender non-conforming'),
});

export const contentMapping: Record<QuotePlan, { id: number; name: string }> = {
  BASIC: {
    id: 0,
    name: 'Basic',
  },
  PREMIUM: {
    id: 1,
    name: 'Premium',
  },
};

const ZCountrySchema = z.object({
  code: z.enum(allCountryCodes),
  name: z.string(),
});

const ZNameSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

const ZLegalGuardian = z.object({
  name: ZNameSchema,
  email: z.string().email(),
  dateOfBirth: z.string(),
});

const ZTariffInfo = z.object({
  startDate: z.string(),
  arrivalDate: z.string(),
  addDependents: z.boolean(),
  parentGuardianName: z.optional(ZNameSchema),
  parentGuardianDateOfBirth: z.optional(z.string()),
  parentGuardianEmail: z.optional(z.string()),
});

const ZTariffDependentInfo = z.object({
  startDate: z.string(),
  arrivalDate: z.string(),
  isMainPolicyTheLegalGuardian: z.optional(z.boolean()),
  parentGuardianName: z.optional(ZNameSchema),
  parentGuardianDateOfBirth: z.optional(z.string()),
  parentGuardianEmail: z.optional(z.string()),
});

const ZQuoteSchema = z.object({
  id: z.string(),
  price: z.number(),
  planId: z.enum(['PREMIUM', 'BASIC']),
});

const ZQuote = z.object({
  quote: z.object({
    options: z.array(ZQuoteSchema),
    selectedQuote: ZQuoteSchema,
  }),
});

const ZMDependentMainPolicy = z.object({
  mainPolicyId: z.string(),
});

export const ZPreQuoteBasic = z.object({
  name: ZNameSchema,
  dateOfBirth: z.string(),
  gender: z.enum(['MALE', 'FEMALE', 'OTHER']),
  occupation: z.enum(Occupations),
  employedAnnualIncome: z.optional(z.number()),
  selfEmployedAnnualIncome: z.optional(z.number()),
  typeOfStudent: z.optional(z.enum(StudentTypes)),
  universityName: z.optional(z.string()),
  employmentStartDate: z.optional(z.string()),
  hasPublicInsurance: z.optional(z.boolean()),
  lastPermanentResidency: ZCountrySchema,
  citizenship: z.array(ZCountrySchema),
});

export const ZExpatProfileInfo = ZPreQuoteBasic.merge(
  z.object({
    email: z.string().email(),
  })
);

export type ExpatProfileInfo = z.TypeOf<typeof ZExpatProfileInfo>;
export type ExpatDependentProfileInfo = z.TypeOf<typeof ZPreQuoteBasic>;

const ZTariffSubmitInfo = z.object({
  addDependents: z.optional(z.boolean()),
  startDate: z.string(),
  arrivalDate: z.string(),
  isMainPolicyTheLegalGuardian: z.optional(z.boolean()),
  legalGuardian: z.optional(ZLegalGuardian),
});

type TariffSubmitInfo = z.TypeOf<typeof ZTariffSubmitInfo>;

export type SubmittableQuestionnaire = {
  personalInfo: ExpatProfileInfo | ExpatDependentProfileInfo;
  tariffInfo: TariffSubmitInfo;
  mainPolicyId: string;
};

export const ZExpatRequiredAnswersForReviewSchema =
  ZExpatProfileInfo.merge(ZTariffInfo).merge(ZQuote);

export type ExpatRequiredAnswersForReviewSchema = z.TypeOf<
  typeof ZExpatRequiredAnswersForReviewSchema
>;

export const ZExpatDependentRequiredAnswersForReviewSchema =
  ZPreQuoteBasic.merge(ZTariffDependentInfo)
    .merge(ZQuote)
    .merge(ZMDependentMainPolicy);

export type ExpatDependentRequiredAnswersForReviewSchema = z.TypeOf<
  typeof ZExpatDependentRequiredAnswersForReviewSchema
>;

export type ExpatQuestionnaireType = 'EXPAT_V2' | 'EXPAT_V2_DEPENDENT';
