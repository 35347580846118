import { website } from '@getpopsure/private-constants';
import { InformationBox } from '@popsure/dirty-swan';
import { useFlag } from '@unleash/proxy-client-react';
import { setAccountDataInQuestionnaire } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import classnames from 'classnames';
import { EmailCheckerInput } from 'components/EmailCheckerInput';
import LoginModal from 'components/signupAuthentication/email/components/loginModal';
import { GoogleLoginButton } from 'features/GoogleOAuth';
import { APIResponseError } from 'models/error';
import { ChangeEvent, FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { getAccountInfo } from 'selectors/user';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  redirectPath: string;
  loading?: boolean;
  error?: APIResponseError;
  handleValidateAccount: (
    email: string,
    onUserExists: (userExists: boolean) => void
  ) => void;
  verticalId: keyof GenericQuestionnaireState;
  redirectExistingUserPath?: string;
  storedAnswer?: string;
}

const Email = ({
  redirectPath,
  redirectExistingUserPath,
  loading,
  error,
  handleValidateAccount,
  verticalId,
  storedAnswer = '',
}: Props) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch();
  const accountInfo = useSelector(getAccountInfo);
  const [email, setEmail] = useState(storedAnswer);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [requiresLoginCode, setRequiresLoginCode] = useState(false);
  const [googleLoginError, setGoogleLoginError] = useState(false);
  const valid = email.length > 0 && termsAccepted;
  const hasGoogleLogin = useFlag('app_google_login');

  const handleModalClose = () => {
    setRequiresLoginCode(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSetTermsAccepted = () => {
    setTermsAccepted((value) => !value);
  };

  const handleOnLogin = () => {
    if (accountInfo?.email === email) {
      dispatch(
        storeGenericQuestionnaireAnswer(verticalId, { account: accountInfo })
      );
    }
  };

  const handleOnGoogleLogin = () => {
    dispatch(
      setAccountDataInQuestionnaire(
        redirectExistingUserPath || redirectPath,
        verticalId
      )
    );

    handleOnLogin();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (valid) {
      handleValidateAccount(email, (userExists) => {
        if (!userExists) {
          dispatch(
            storeGenericQuestionnaireAnswer(verticalId, { account: null })
          );
        }

        setRequiresLoginCode(userExists);
      });
    }
  };

  return (
    <>
      <LoginModal
        email={email}
        isOpen={requiresLoginCode}
        onClose={handleModalClose}
        onLogin={handleOnLogin}
        redirectPath={redirectExistingUserPath || redirectPath}
        verticalId={verticalId}
      />
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className="p-body">
          <h1 className="p-h1">
            {t('components.email.title', "What's your email address?")}
          </h1>
          <p className="p-p mt8">
            {t(
              'components.email.description',
              'With your email address, we can create a Feather account for you to access your policies at any time.'
            )}
          </p>

          <EmailCheckerInput
            className="mt16 wmx6"
            type="email"
            autoComplete="email"
            value={email}
            placeholder={t('components.email.placeholder', 'Email address')}
            onChange={handleInputChange}
            required
            data-cy="input-email"
          />

          <div className="mt16 p-label-container">
            <input
              id="terms"
              className="p-checkbox"
              type="checkbox"
              data-testid="email-tnc"
              onChange={handleSetTermsAccepted}
            />
            <label
              htmlFor="terms"
              className={`p-label ${styles.label}`}
              data-cy="email-tnc"
            >
              <div className="p-p">
                <Trans i18nKey="components.email.agreement">
                  I have read and understood the{' '}
                  <a
                    className="p-a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={website.privacyPolicy}
                  >
                    privacy policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="p-a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={website.termsAndConditions}
                  >
                    T&Cs
                  </a>
                  .
                </Trans>
              </div>
            </label>
          </div>

          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-p--small p-notice--warning mt16">
              {error?.message}
            </div>
          </AnimateHeight>

          {googleLoginError && (
            <InformationBox className="mt16 wmx6" variant="error">
              {t(
                'components.signupAuthentication.googleLoginError',
                "Your account isn't linked to Google yet - please log in with your email and sign-in code first, then link your Google account in Account settings."
              )}
            </InformationBox>
          )}

          <div className="d-flex gap8 mt24">
            <button
              className={classnames(
                `p-btn p-btn--primary wmn3 ${styles.button}`,
                {
                  'p-btn--loading': loading,
                }
              )}
              disabled={!valid}
              type="submit"
              data-cy="button-continue"
            >
              {hasGoogleLogin
                ? t('components.email.buttonCaptionV2', 'Continue with email')
                : t('components.email.buttonCaption', 'Continue')}
            </button>

            <GoogleLoginButton
              onSuccess={handleOnGoogleLogin}
              onLoginError={() => setGoogleLoginError(true)}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Email;
