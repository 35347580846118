import { TFunction } from '@getpopsure/i18n-react';
import { Address, Country, isEEACountry } from '@getpopsure/public-models';
import { ageByDate } from '@getpopsure/public-utility';
import type { RequestAction } from 'constants/actions';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import type { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { ActionResponse, UploadedFile } from 'shared/models/types';
import {
  ZAddressSchema,
  ZCountrySchema,
  ZNameSchema,
} from 'shared/models/validations';
import type { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

export type Beneficiary = {
  id: string;
  name: Name;
  email?: string;
  payoutPercentage?: number;
  dateOfBirth?: string;
  coverage?: number;
  uploads?: UploadedFile[];
  uploadTokens?: string[];
  userUploads?: {
    id: string;
    filename: string;
    token: string;
    publicUrl: string;
    normalizedExtension: string;
  }[];
};

export type QuestionnaireType =
  | 'LIFE_PRE_QUOTE'
  | 'LIFE_POST_QUOTE'
  | 'LIFE_SCREENED_OUT';

export const ZCoverageInfo = z.object({
  duration: z.number(),
  coverage: z.number(),
});

export type CoverageInfo = z.TypeOf<typeof ZCoverageInfo>;

export const ZQuote = z.object({
  id: z.string(),
  questionnaireId: z.string(),
  price: z.number(),
  planId: z.string(),
  duration: z.number().optional(),
  coverage: z.number().optional(),
  termStartDate: z.string().optional(),
  termEndDate: z.string().optional(),
  coverageInfo: ZCoverageInfo,
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type CheckoutPolicyDetails = {
  duration: number;
  coverage: number;
  beneficiaries: Array<{
    name: Name;
    email: string;
    coverage: number;
    payoutPercentage: number;
  }>;
  endDate: string;
  address: Address;
};

export const degree = [
  'NO_DEGREE',
  'HIGH_SCHOOL',
  'UNIVERSITY_SHORT_TERM',
  'UNIVERSITY_LONG_TERM',
] as const;

export type Degree = typeof degree[number];

export const DegreeMap: Record<Degree, string> = {
  NO_DEGREE: 'No degree',
  HIGH_SCHOOL: 'High-school diploma',
  UNIVERSITY_SHORT_TERM: 'University (1-2 years)',
  UNIVERSITY_LONG_TERM: 'University (3+ years)',
};

export const Occupations = {
  EMPLOYED: 'EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED',
  STUDENT: 'STUDENT',
  RETIRED: 'RETIRED',
  OTHER: 'OTHER',
} as const;

const occupation = [
  'EMPLOYED',
  'SELF_EMPLOYED',
  'UNEMPLOYED',
  'STUDENT',
  'RETIRED',
  'OTHER',
] as const;

export type Occupation = typeof occupation[number];

export const occupationMapping = (
  t: TFunction
): Record<Occupation, string | { title: string; description: string }> => ({
  EMPLOYED: t('life.qnr.occupationMapping.employed', 'Employed'),
  SELF_EMPLOYED: {
    title: t('life.qnr.occupationMapping.selfEmployed.title', 'Self-employed'),
    description: t(
      'life.qnr.occupationMapping.selfEmployed.description',
      'or employed by a company outside Germany'
    ),
  },
  UNEMPLOYED: t('life.qnr.occupationMapping.unemployed', 'Unemployed'),
  STUDENT: t('life.qnr.occupationMapping.student', 'Student'),
  RETIRED: t('life.qnr.occupationMapping.retired', 'Retired'),
  OTHER: t('life.qnr.occupationMapping.otherOption', 'Other'),
});

type Review = boolean;
type Date = string;
type Text = string;

export type Life = {
  intro: SCREEN;
  dateOfBirth: string;
  under18Blocker?: BLOCKER;
  over65Blocker?: BLOCKER;
  height: number;
  weight: number;
  bmiBlocker: BLOCKER;
  isManualLabor: boolean;
  degree?: Degree;
  tobaccoUse: boolean;
  tobaccoUseRecent?: boolean;

  introSteps: SCREEN;

  // personal information
  occupation: Occupation;
  occupationBlocker?: BLOCKER;
  countryOfResidence: Country;
  countryBlocker?: BLOCKER;
  address?: Address;
  travelPlansLength: boolean;
  travelPlansCountry?: Country[];
  hasReviewedPersonalInfo: Review;

  // insurance history
  previousApplications: boolean;
  previousApplicationsRefused?: boolean;
  otherActiveInsurance: boolean;
  otherActiveInsuranceCoverage?: number;
  income?: number;
  hasReviewedInsuranceHistory: Review;

  // medical history
  medicationUse: boolean;
  medicationUseHealthStatus?: boolean;
  medicationUseDetail?: string;
  inabilityToWork: boolean;
  inabilityToWorkReason?: boolean;
  inabilityToWorkHealthStatus?: boolean;
  inabilityToWorkDetail?: string;
  healthConditions: HealthConditions[];
  bloodPressureTreatment: boolean;
  bloodPressureMeasures?: boolean;
  bloodPressure?: BloodPressure;
  bloodPressureDate?: Date;
  hospitalStay: boolean;
  hospitalStayHealthStatus?: boolean;
  hospitalStayDetails?: Text;
  medicalCheckups: boolean;
  medicalCheckupsDetails?: Text;
  alcoholDrugsDoctor: boolean;
  alcoholDrugsDetails?: Text;
  drugsUsage: boolean;
  drugsUsageDetails?: Text;
  hiv: boolean;
  hivDetails?: Text;
  hasReviewedMedicalHistory: Review;

  // lifestyle
  sportsParticipation: RiskySports[];
  climbing?: ClimbingSituations[];
  bouldering?: boolean;
  martialArts?: boolean;
  extremeSports?: string;
  scubaDiving?: ScubaDivingSituations[];
  weapons: boolean;
  fireArmsUse?: boolean;
  dangerousActivities: boolean;
  dangerousActivitiesDetails?: string;
  hasReviewedLifestyle: Review;

  hasReviewedEligibility: boolean;
  eligibilityBlocker: BLOCKER;

  // account creation
  name: Name;
  email: string;

  review: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quote: Quote;
  quoteId: string;
  questionnaireId: string;
};

// TODO: define the required  quote schema
export type CreateQuoteReturnType = {
  status: ActionResponse;
  result?: {
    quote: Quote;
    quoteId: string;
    questionnaireId: string;
  };
};

export type LifePreQuoteDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  RequestAction
>;

const ZHealthConditions = z.enum([
  'HEART_RELATED',
  'STROKE',
  'DIABETES',
  'CANCER',
  'EPILEPSY',
  'KIDNEY_DISEASE',
  'MULTIPLE_SCLEROSIS',
  'MENTAL_HEALTH',
  'NONE',
]);

export const healthConditions = {
  HEART_RELATED: 'HEART_RELATED',
  STROKE: 'STROKE',
  DIABETES: 'DIABETES',
  CANCER: 'CANCER',
  EPILEPSY: 'EPILEPSY',
  KIDNEY_DISEASE: 'KIDNEY_DISEASE',
  MULTIPLE_SCLEROSIS: 'MULTIPLE_SCLEROSIS',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  NONE: 'NONE',
} as const;

export type HealthConditions = keyof typeof healthConditions;

export const healthConditionsMapping: Record<HealthConditions, string> = {
  HEART_RELATED: 'Heart disease or heart attack',
  STROKE: 'Stroke',
  DIABETES: 'Diabetes',
  CANCER: 'Cancer',
  EPILEPSY: 'Epilepsy',
  KIDNEY_DISEASE: 'Kidney disease',
  MULTIPLE_SCLEROSIS: 'Multiple sclerosis',
  MENTAL_HEALTH: 'Mental health disorder or illness',
  NONE: 'None',
};

export const bloodPressure = z.object({
  systolic: z.number(),
  diastolic: z.number(),
});

export type BloodPressure = z.TypeOf<typeof bloodPressure>;

// lifestyle

const ZRiskySports = z.enum([
  'RACING',
  'CLIMBING',
  'BOULDERING',
  'MARTIAL_ARTS',
  'EXTREME_SPORTS',
  'SCUBA_DIVING',
  'NONE',
]);

export const riskySports = {
  RACING: 'RACING',
  CLIMBING: 'CLIMBING',
  BOULDERING: 'BOULDERING',
  MARTIAL_ARTS: 'MARTIAL_ARTS',
  EXTREME_SPORTS: 'EXTREME_SPORTS',
  SCUBA_DIVING: 'SCUBA_DIVING',
  NONE: 'NONE',
} as const;

export type RiskySports = z.TypeOf<typeof ZRiskySports>;

export const riskySportsMapping: Record<RiskySports, string> = {
  RACING: 'Competitive racing (e.g. motorsport, downhill biking)',
  CLIMBING: 'Mountain or rock climbing',
  BOULDERING: 'Bouldering',
  MARTIAL_ARTS: 'Martial arts',
  EXTREME_SPORTS: 'Extreme sports (e.g. bungee jumping, skydiving)',
  SCUBA_DIVING: 'Scuba diving',
  NONE: 'None of the above',
};

export const climbingSituations = {
  HIGH_ALTITUDE: 'HIGH_ALTITUDE',
  NO_SAFETY_EQUIPMENT: 'NO_SAFETY_EQUIPMENT',
  ICE_CLIMBING: 'ICE_CLIMBING',
  NONE: 'NONE',
} as const;

const ZClimbingSituations = z.enum([
  'HIGH_ALTITUDE',
  'NO_SAFETY_EQUIPMENT',
  'ICE_CLIMBING',
  'NONE',
]);

export type ClimbingSituations = keyof typeof climbingSituations;

export const climbingSituationsMap: Record<ClimbingSituations, string> = {
  HIGH_ALTITUDE: 'I go over 4000m of altitude',
  NO_SAFETY_EQUIPMENT: 'I do not use the recommended safety equipment',
  ICE_CLIMBING: 'I go ice climbing or climb glaciers',
  NONE: 'None of the above',
};

const ZScubaDiving = z.enum(['DEEP_DIVING', 'CAVE_ICE_DIVING', 'NONE']);

export const scubaDivingSituations = [
  'DEEP_DIVING',
  'CAVE_ICE_DIVING',
  'NONE',
] as const;

export type ScubaDivingSituations = typeof scubaDivingSituations[number];

export const scubaDivingSituationsMap: Record<ScubaDivingSituations, string> = {
  DEEP_DIVING: 'I go deeper than 30m',
  CAVE_ICE_DIVING: 'I go diving in caves or under ice',
  NONE: 'None of the above',
};

export const ZPreQuoteLifeQuestionnaire = z
  .object({
    // pre-quote
    dateOfBirth: z.string(),
    height: z.number(),
    weight: z.number(),
    isManualLabor: z.boolean(),
    degree: z.optional(z.enum(degree)),
    tobaccoUse: z.boolean(),
    tobaccoUseRecent: z.optional(z.boolean()),
    coverage: z.number(),
    duration: z.number(),
  })
  .and(ZCoverageInfo)
  .transform((props) => ({
    ...props,
    officeWork: !props.isManualLabor,
    degree: props.degree ? props.degree : 'NO_DEGREE',
  }));

export type SubmittablePreQuoteLifeQuestionnaire = z.infer<
  typeof ZPreQuoteLifeQuestionnaire
>;

export const ZBasicSubmittableLifeQuestionnaire = z.object({
  // pre-quote
  dateOfBirth: z.string(),
  height: z.number(),
  weight: z.number(),
  isManualLabor: z.boolean(),
  degree: z.optional(z.enum(degree)),
  tobaccoUse: z.boolean(),
  tobaccoUseRecent: z.optional(z.boolean()),
  // personal information
  occupation: z.enum(occupation),
  countryOfResidence: ZCountrySchema,
  address: z.optional(ZAddressSchema),
  travelPlansLength: z.boolean(),
  travelPlansCountry: z.optional(z.array(ZCountrySchema)),
  hasReviewedPersonalInfo: z.boolean(),
  // insurance history
  previousApplications: z.boolean(),
  previousApplicationsRefused: z.optional(z.boolean()),
  otherActiveInsurance: z.boolean(),
  otherActiveInsuranceCoverage: z.optional(z.number()),
  income: z.optional(z.number()),
  hasReviewedInsuranceHistory: z.boolean(),
  // medical history
  medicationUse: z.boolean(),
  medicationUseHealthStatus: z.optional(z.boolean()),
  medicationUseDetail: z.optional(z.string()),
  inabilityToWork: z.boolean(),
  inabilityToWorkReason: z.optional(z.boolean()),
  inabilityToWorkHealthStatus: z.optional(z.boolean()),
  inabilityToWorkDetail: z.optional(z.string()),
  healthConditions: z.array(ZHealthConditions),
  bloodPressureTreatment: z.boolean(),
  bloodPressureMeasures: z.optional(z.boolean()),
  bloodPressure: z.optional(bloodPressure),
  bloodPressureDate: z.optional(z.string()),
  hospitalStay: z.boolean(),
  hospitalStayHealthStatus: z.optional(z.boolean()),
  hospitalStayDetails: z.optional(z.string()),
  medicalCheckups: z.boolean(),
  medicalCheckupsDetails: z.optional(z.string()),
  alcoholDrugsDoctor: z.boolean(),
  alcoholDrugsDetails: z.optional(z.string()),
  drugsUsage: z.boolean(),
  drugsUsageDetails: z.optional(z.string()),
  hiv: z.boolean(),
  hivDetails: z.optional(z.string()),
  hasReviewedMedicalHistory: z.boolean(),
  // lifestyle
  sportsParticipation: z.array(ZRiskySports),
  climbing: z.optional(z.array(ZClimbingSituations)),
  bouldering: z.optional(z.boolean()),
  martialArts: z.optional(z.boolean()),
  extremeSports: z.optional(z.string()),
  scubaDiving: z.optional(z.array(z.enum(scubaDivingSituations))),
  weapons: z.boolean(),
  fireArmsUse: z.optional(z.boolean()),
  dangerousActivities: z.boolean(),
  dangerousActivitiesDetails: z.optional(z.string()),

  hasReviewedLifestyle: z.boolean(),
  hasReviewedEligibility: z.boolean(),
  quote: ZQuote,
});

export const ZAccountInformation = z.object({
  name: ZNameSchema,
  email: z.string(),
});

export const ZIneligibleSubmittableLifeQuestionnaire =
  ZBasicSubmittableLifeQuestionnaire.and(ZCoverageInfo).transform((data) => ({
    ...data,
    hasGermanAddress: data.countryOfResidence.code === 'DE',
    degree: data.degree ? data.degree : 'NO_DEGREE',
    type: 'v2',
  }));

export const ZSubmittableLifeQuestionnaire =
  ZBasicSubmittableLifeQuestionnaire.and(ZAccountInformation)
    .and(ZCoverageInfo)
    .transform((data) => ({
      ...data,
      hasGermanAddress: data.countryOfResidence.code === 'DE',
      degree: data.degree ? data.degree : 'NO_DEGREE',
      type: 'v2',
    }));

export type SubmittableLifeQuestionnaire = z.infer<
  typeof ZSubmittableLifeQuestionnaire
>;

export type LifeGroupIds = 'preQuote' | 'signup';

export type QuestionnaireLabels = {
  labels: {
    title: {
      personalInfo: Partial<Record<keyof SubmittableLifeQuestionnaire, string>>;
      insuranceHistory: Partial<
        Record<keyof SubmittableLifeQuestionnaire, string>
      >;
      medicalHistory: Partial<
        Record<keyof SubmittableLifeQuestionnaire, string>
      >;
      lifeStyle: Partial<Record<keyof SubmittableLifeQuestionnaire, string>>;
    };
    answers: {
      personalInfo: {
        degree: Record<Degree, string>;
        occupation: Record<Occupation, string>;
      };
      medicalHistory: {
        healthConditions: Record<HealthConditions, string>;
      };
      lifeStyle: {
        sportsParticipation: Record<RiskySports, string>;
        climbing: Record<ClimbingSituations, string>;
        scubaDiving: Record<ScubaDivingSituations, string>;
      };
    };
  };
  questionOrder: {
    personalInfo: typeof questionOrderPersonalInfo;
    insuranceHistory: typeof questionOrderInsuranceHistory;
    medicalHistory: typeof questionOrderMedicalHistory;
    lifeStyle: typeof questionOrderLifestyle;
  };
};

export const MIN_ELIGIBLE_AGE = 18;
export const MAX_ELIGIBLE_AGE = 64;

export const MAX_FULL_COVERAGE_AGE = 50;

export const MAX_POLICY_AGE = 74;

export const MIN_TERM = 5;
export const MAX_TERM = 30;

export const MIN_COVERAGE = 50_000;
export const MAX_COVERAGE_DEFAULT = 600_000;
export const MAX_COVERAGE_OVER_AGE = 250_000;

export const MIN_BMI_LIMIT = 19;
export const MAX_BMI_LIMIT = 41;

export const getMaxCoverage = (questionnaire: Partial<Life>): number => {
  let maxCoverage = MAX_COVERAGE_DEFAULT;

  if (
    questionnaire?.dateOfBirth &&
    ageByDate(questionnaire?.dateOfBirth) > MAX_FULL_COVERAGE_AGE
  ) {
    maxCoverage = MAX_COVERAGE_OVER_AGE;
  }

  if (questionnaire?.income && questionnaire?.otherActiveInsuranceCoverage) {
    const { income, otherActiveInsuranceCoverage } = questionnaire;
    const coverage = income * 10 - otherActiveInsuranceCoverage;

    return Math.min(coverage, maxCoverage);
  }

  return maxCoverage;
};

export const getMaxTerm = (questionnaire: Partial<Life>): number => {
  const { dateOfBirth } = questionnaire;
  let maxTerm = MAX_TERM;

  if (dateOfBirth) {
    const age = ageByDate(dateOfBirth);
    maxTerm = Math.min(MAX_POLICY_AGE - age, maxTerm);
  }

  return maxTerm;
};

export const isEligibleCountry = (input: Country): boolean =>
  isEEACountry(input) || ['GB', 'US', 'CA', 'AU', 'NZ'].includes(input.code);

const isBlockedOccupation = (occupationType: Occupation) =>
  [
    Occupations.OTHER,
    Occupations.STUDENT,
    Occupations.RETIRED,
    Occupations.OTHER,
  ].some((blockedType) => blockedType === occupationType);

export const isEligibleBMI = ({
  weight,
  height,
}: {
  weight: number;
  height: number;
}): boolean => {
  const heightMeters: number = height / 100;
  const heightMetersPerSquared: number = heightMeters * heightMeters;
  const bmi: number = weight / heightMetersPerSquared;

  return Boolean(bmi >= MIN_BMI_LIMIT && bmi <= MAX_BMI_LIMIT);
};

const checkForIneligibility = z.object({
  dateOfBirth: z
    .string()
    .refine((result) => ageByDate(result) >= MIN_ELIGIBLE_AGE)
    .refine((result) => ageByDate(result) <= MAX_ELIGIBLE_AGE),
  occupation: z
    .enum(occupation)
    .refine((result) => !isBlockedOccupation(result)),
  countryOfResidence: ZCountrySchema.refine((country) => country.code === 'DE'),
  travelsPlansCountry: z.optional(
    z
      .array(ZCountrySchema)
      .refine(
        (countries) =>
          countries.filter((result) => !isEligibleCountry(result)).length === 0
      )
  ),
  previousApplicationsRefused: z.optional(
    z.boolean().refine((result) => result === false)
  ),
  medicationUseHealthStatus: z.optional(
    z.boolean().refine((result) => result === true)
  ),
  inabilityToWorkHealthStatus: z.optional(
    z.boolean().refine((result) => result === true)
  ),
  healthConditions: z
    .array(ZHealthConditions)
    .refine((conditions) => conditions.includes('NONE')),
  bloodPressureMeasures: z.optional(
    z.boolean().refine((result) => result === true)
  ),
  hospitalStayHealthStatus: z.optional(
    z.boolean().refine((result) => result === true)
  ),
  medicalCheckups: z.boolean().refine((result) => result === false),
  alcoholDrugsDoctor: z.boolean().refine((result) => result === false),
  drugsUsage: z.boolean().refine((result) => result === false),
  hiv: z.boolean().refine((result) => result === false),
  sportsParticipation: z
    .array(ZRiskySports)
    .refine(
      (result) =>
        !result.includes(riskySports.RACING) &&
        !result.includes(riskySports.EXTREME_SPORTS)
    ),
  climbing: z.optional(
    z
      .array(ZClimbingSituations)
      .refine((result) => result.includes(climbingSituations.NONE))
  ),
  martialArts: z.optional(z.boolean().refine((result) => result === false)),
  scubaDiving: z.optional(
    z.array(ZScubaDiving).refine((result) => result.includes('NONE'))
  ),
  bouldering: z.optional(z.boolean().refine((result) => result === false)),
  fireArmsUse: z.optional(z.boolean().refine((result) => result === true)),
  dangerousActivities: z.optional(
    z.boolean().refine((result) => result === false)
  ),
});

export const isEligible = (questionnaire: Partial<Life>): boolean => {
  const hasCoverageBaseline = getMaxCoverage(questionnaire) >= 1;

  const validationResult = checkForIneligibility.safeParse(questionnaire);

  if (validationResult.success) {
    return hasCoverageBaseline;
  }

  return false;
};

type FlagKeys = (
  | keyof Life
  | 'healthCondition:HEART_RELATED'
  | 'healthCondition:DIABETES'
  | 'healthCondition:STROKE'
  | 'healthCondition:DIABETES'
  | 'healthCondition:CANCER'
  | 'healthCondition:EPILEPSY'
  | 'healthCondition:KIDNEY_DISEASE'
  | 'healthCondition:MULTIPLE_SCLEROSIS'
  | 'healthCondition:MENTAL_HEALTH'
  | 'sportsParticipation:RACING'
  | 'sportsParticipation:CLIMBING'
  | 'sportsParticipation:BOULDERING'
  | 'sportsParticipation:MARTIAL_ARTS'
  | 'sportsParticipation:EXTREME_SPORTS'
  | 'sportsParticipation:SCUBA_DIVING'
)[];

export type FlaggedAnswers = {
  yellow: FlagKeys;
  red: FlagKeys;
};

export const getFlaggedAnswers = (questionnaire: Partial<Life>) => {
  const flags: FlaggedAnswers = {
    yellow: [],
    red: [],
  };
  const validationResult = checkForIneligibility.safeParse(questionnaire);

  if (validationResult.success) {
    return flags;
  }

  const invalidKeys = validationResult.error.errors.flatMap((e) => e.path);

  for (const key of invalidKeys as (keyof Life)[]) {
    const answer = questionnaire[key];
    switch (key) {
      case 'countryOfResidence':
      case 'hiv':
      case 'fireArmsUse':
        flags.red.push(key);
        break;

      case 'occupation':
      case 'travelPlansCountry':
      case 'previousApplicationsRefused':
      case 'inabilityToWorkHealthStatus':
      case 'medicationUseHealthStatus':
      case 'bloodPressureMeasures':
      case 'hospitalStayHealthStatus':
      case 'medicalCheckups':
      case 'alcoholDrugsDoctor':
      case 'drugsUsage':
      case 'dangerousActivities':
        flags.yellow.push(key);
        break;

      case 'climbing':
        flags.red.push('sportsParticipation:CLIMBING');
        break;
      case 'martialArts':
        flags.red.push('sportsParticipation:MARTIAL_ARTS');
        break;
      case 'scubaDiving':
        flags.red.push('sportsParticipation:SCUBA_DIVING');
        break;
      case 'bouldering':
        flags.red.push('sportsParticipation:BOULDERING');
        break;

      case 'healthConditions':
        // collect all health conditions specifically
        for (const healthCondition of answer as Life['healthConditions']) {
          if (healthCondition === 'DIABETES') {
            flags.yellow.push('healthCondition:DIABETES');
          } else if (healthCondition !== 'NONE') {
            flags.red.push(`healthCondition:${healthCondition}`);
          }
        }
        break;

      case 'sportsParticipation':
        if (
          (answer as Life['sportsParticipation']).includes(
            riskySports.EXTREME_SPORTS
          )
        ) {
          flags.yellow.push('sportsParticipation:EXTREME_SPORTS');
        }
        if (
          (answer as Life['sportsParticipation']).includes(riskySports.RACING)
        ) {
          flags.red.push('sportsParticipation:RACING');
        }
    }
  }

  return flags;
};

type QuestionId = keyof SubmittableLifeQuestionnaire;

export const questionOrderPreQuote: QuestionId[] = [
  'dateOfBirth',
  'height',
  'weight',
  'isManualLabor',
  'degree',
  'tobaccoUse',
  'tobaccoUseRecent',
];

export const questionOrderPersonalInfo: QuestionId[] = [
  'occupation',
  'countryOfResidence',
  'address',
  'travelPlansLength',
  'travelPlansCountry',
  'hasReviewedPersonalInfo',
];

export const questionOrderInsuranceHistory: QuestionId[] = [
  'previousApplications',
  'previousApplicationsRefused',
  'otherActiveInsurance',
  'otherActiveInsuranceCoverage',
  'income',
  'hasReviewedInsuranceHistory',
];

export const questionOrderMedicalHistory: QuestionId[] = [
  'medicationUse',
  'medicationUseHealthStatus',
  'medicationUseDetail',
  'inabilityToWork',
  'inabilityToWorkReason',
  'inabilityToWorkHealthStatus',
  'inabilityToWorkDetail',
  'healthConditions',
  'bloodPressureTreatment',
  'bloodPressureMeasures',
  'bloodPressure',
  'bloodPressureDate',
  'hospitalStay',
  'hospitalStayHealthStatus',
  'hospitalStayDetails',
  'medicalCheckups',
  'medicalCheckupsDetails',
  'alcoholDrugsDoctor',
  'alcoholDrugsDetails',
  'drugsUsage',
  'drugsUsageDetails',
  'hiv',
  'hivDetails',
  'hasReviewedMedicalHistory',
];

export const questionOrderLifestyle: QuestionId[] = [
  'sportsParticipation',
  'climbing',
  'bouldering',
  'martialArts',
  'extremeSports',
  'scubaDiving',
  'weapons',
  'fireArmsUse',
  'dangerousActivities',
  'dangerousActivitiesDetails',
  'hasReviewedLifestyle',
];

export const questionGroupsMapping: Partial<Record<QuestionId, QuestionId[]>> =
  {
    hasReviewedPersonalInfo: questionOrderPersonalInfo,
    hasReviewedInsuranceHistory: questionOrderInsuranceHistory,
    hasReviewedMedicalHistory: questionOrderMedicalHistory,
    hasReviewedLifestyle: questionOrderLifestyle,
  };

export const questionnaireIdMapping: Partial<Record<QuestionId, string>> = {
  hasReviewedPersonalInfo: 'Personal Info',
  hasReviewedInsuranceHistory: 'Insurance History',
  hasReviewedMedicalHistory: 'Medical History',
  hasReviewedLifestyle: 'Lifestyle',
};

export const questionsMapping: Record<
  keyof SubmittableLifeQuestionnaire,
  string
> = {
  // pre quote
  dateOfBirth: 'When were you born?',
  height: 'How tall are you?',
  weight: 'How much do you weigh?',
  isManualLabor: 'Does your profession mainly involve manual labor?',
  degree: 'What is your highest level of education?',
  tobaccoUse: 'Have you ever smoked or used nicotine products?',
  tobaccoUseRecent:
    'Have you smoked or used nicotine products in the last 12 months?',

  // personal
  occupation: "What's your main occupation in Germany?",
  countryOfResidence: 'In which country are you currently living?',
  hasGermanAddress: 'Where are you currently living?',
  address: "What's your address in Germany?",
  travelPlansLength:
    'Are you planning to stay abroad for more than 12 weeks within the next year?',
  travelPlansCountry: 'Which country will you be staying in?',
  hasReviewedPersonalInfo: '',

  // insurance history
  previousApplications:
    'Have you applied for life insurance within the past 5 years?',
  previousApplicationsRefused:
    'Have any previous life insurance applications been refused, postponed or only accepted on special terms (surcharge or extra clause)?',
  otherActiveInsurance: 'Do you have any other active life insurance plan(s)?',
  otherActiveInsuranceCoverage:
    'What sum is currently covered by your current life insurance plan(s)?',
  income: "What's your annual income in Euros before taxes?",
  hasReviewedInsuranceHistory: '',

  // medical history
  medicationUse:
    'In the last 5 years, have you taken any medication for more than 21 days at a time?',
  medicationUseHealthStatus: 'Is the underlying illness completely healed?',
  medicationUseDetail:
    'Could you please explain this to us in a little more detail?',
  inabilityToWork:
    'Have you been unable to work for more than 21 days in a row in the last 5 years (not including pregnancies)?',
  inabilityToWorkReason: 'Was this due to illness, pain or health disorders?',
  inabilityToWorkHealthStatus:
    'Could you please explain this to us in a little more detail?',
  inabilityToWorkDetail:
    'Could you please explain this to us in a little more detail?',
  healthConditions:
    'Have you suffered from any of the following conditions within the last 10 years?',
  bloodPressureTreatment:
    'Were you treated for high blood pressure within the past 5 years?',
  bloodPressureMeasures:
    'Was your blood pressure in the normal range the last it was measured by a medical professional?',
  bloodPressure: 'What was the measured blood pressure?',
  bloodPressureDate: 'When was this measurement recorded?',
  hospitalStay:
    'Did you have any in-hospital treatment that required you to stay overnight within the last 5 years?',
  hospitalStayHealthStatus: 'Is the underlying illness completely healed?',
  hospitalStayDetails:
    'Could you please explain this to us in a little more detail?',
  medicalCheckups:
    'Are you expecting medical results or have been recommended for check-ups (apart from preventative care)?',
  medicalCheckupsDetails:
    'Could you please explain this to us in a little more detail?',
  alcoholDrugsDoctor:
    'Have you seen a doctor about alcohol, drug or medication abuse within the past 5 years?',
  alcoholDrugsDetails:
    'Could you please explain this to us in a little more detail?',
  drugsUsage: 'Were you given or have taken any narcotics in the past 5 years?',
  drugsUsageDetails:
    'Could you please explain this to us in a little more detail?',
  hiv: 'Have you ever been diagnosed, treated or tested positive for HIV (Human Immunodeficiency Virus) or AIDS (Acquired Immunodeficiency Syndrome)?',
  hivDetails: 'Could you please explain this to us in a little more detail?',
  hasReviewedMedicalHistory: '',

  // lifestyle
  sportsParticipation: 'Do you participate in any of the following sports?',
  climbing: 'Do you participate in any of the following sports?',
  bouldering: 'Do you practice outdoor bouldering (natural walls)?',
  martialArts: 'Do you practice martial arts as a job?',
  extremeSports: 'Which extreme sports do you practice?',
  scubaDiving: 'Which situations apply to you when you go scuba diving?',
  weapons:
    'Do you use explosives or weapons while working or in your free time?',
  fireArmsUse: 'Do you go hunting, shooting or work as a forester?',
  dangerousActivities:
    'Are you exposed to any other dangerous activities (other than what you already told us)?',
  dangerousActivitiesDetails:
    'What kind of other dangerous activities are you exposed to?',
  hasReviewedLifestyle: '',

  hasReviewedEligibility: '',
  quote: '',
  email: "What's your email address?",
  name: "What's your name?",

  coverage: '',
  duration: '',
  type: 'v2',
};

const createLabels = (
  questionIds: (keyof SubmittableLifeQuestionnaire)[]
): Partial<Record<keyof SubmittableLifeQuestionnaire, string>> =>
  questionIds.reduce((obj, item) => {
    if (questionsMapping[item]) {
      return {
        ...obj,
        [item]: questionsMapping[item],
      };
    }
    return obj;
  }, {});

export const questionnaireLabelsInfo = (
  questionnaireAnswers: SubmittableLifeQuestionnaire
): QuestionnaireLabels => {
  const personalInfoLabels = createLabels([
    ...questionOrderPersonalInfo,
    ...questionOrderPreQuote,
    'name',
    'email',
  ]);
  const insuranceHistoryLabels = createLabels(questionOrderInsuranceHistory);
  const medicalHistoryLabels = createLabels(questionOrderMedicalHistory);
  const lifestyleLabels = createLabels(questionOrderLifestyle);

  return {
    labels: {
      title: {
        personalInfo: personalInfoLabels,
        insuranceHistory: insuranceHistoryLabels,
        medicalHistory: medicalHistoryLabels,
        lifeStyle: lifestyleLabels,
      },
      answers: {
        personalInfo: {
          degree: DegreeMap,
          occupation: {
            EMPLOYED: 'Employed',
            SELF_EMPLOYED: 'Self-employed',
            UNEMPLOYED: 'Unemployed',
            STUDENT: 'Student',
            RETIRED: 'Retired',
            OTHER: 'Other',
          },
        },
        medicalHistory: {
          healthConditions: healthConditionsMapping,
        },
        lifeStyle: {
          sportsParticipation: riskySportsMapping,
          climbing: climbingSituationsMap,
          scubaDiving: scubaDivingSituationsMap,
        },
      },
    },
    questionOrder: {
      personalInfo: [
        ...(questionnaireAnswers.isManualLabor
          ? questionOrderPreQuote.filter((question) => question !== 'degree')
          : questionOrderPreQuote),
        ...questionOrderPersonalInfo,
        'name',
        'email',
      ],
      insuranceHistory: questionOrderInsuranceHistory,
      medicalHistory: questionOrderMedicalHistory,
      lifeStyle: questionOrderLifestyle,
    },
  };
};
