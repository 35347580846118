import { ClaimsAction } from 'constants/actions';
import { LiabilityClaims } from 'features/liabilityClaims/models';

export const storeLiabilityClaimsAnswers = (
  answer: Partial<LiabilityClaims>
): ClaimsAction => ({
  type: 'STORE_LIABILITY_CLAIM',
  liability: answer,
});

export const flushLiabilityClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LIABILITY_CLAIM',
});
