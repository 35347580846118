import { TFunction } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import { QuestionnaireJSON } from '../model';

const sessionKey = 'questionnaires';

export const writeSession = (data: unknown) => {
  localStorage.setItem(sessionKey, JSON.stringify(data));
};

export const readSession = (): unknown => {
  try {
    const session = localStorage.getItem(sessionKey);
    return JSON.parse(session as string);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return {};
  }
};

export const useLoadQuestionnaire = (
  verticalId: string,
  isDependent = false
) => {
  const [json, setJson] = useState<
    | ((
        t: TFunction,
        interpolationData: Record<string, unknown>
      ) => QuestionnaireJSON)
    | false
  >();

  const getData = useCallback(async () => {
    try {
      const questionnaire = (
        await import(
          `../../../syncedData/questionnaires/${
            isDependent ? 'dependents/' : ''
          }${verticalId}.ts`
        )
      ).default;

      setJson(() => questionnaire);
    } catch (error) {
      setJson(false);
    }
  }, [isDependent, verticalId]);

  useEffect(() => {
    getData();
  }, [getData, verticalId]);

  return json;
};

export function interpolateData<T>(
  obj: unknown,
  data: Record<string, unknown>
): T {
  const resolveValue = (
    key: string,
    newData: Record<string, unknown>
  ): unknown => {
    const keys = key.split('.');
    let value: unknown = newData;

    for (const part of keys) {
      if (value && typeof value === 'object' && part in value) {
        value = (value as Record<string, unknown>)[part];
      } else {
        return `{{${key}}}`; // Return the original placeholder if not found
      }
    }

    return value;
  };

  const process = (input: unknown): unknown => {
    if (typeof input === 'string' && /{{\s*([^{}\s]+)\s*}}/.test(input)) {
      const match = input.match(/^{{\s*([^{}\s]+)\s*}}$/);
      if (match) {
        const value = resolveValue(match[1], data);
        return value !== undefined ? value : input; // Return the value directly if resolved
      }

      // Replace placeholders in strings that are part of a larger string
      return input.replace(/{{\s*([^{}\s]+)\s*}}/g, (_, key) => {
        const value = resolveValue(key, data);
        return value !== undefined ? String(value) : `{{${key}}}`;
      });
    }

    if (Array.isArray(input)) {
      return input.map((item) => process(item));
    }

    if (typeof input === 'object' && input !== null) {
      const result: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(input)) {
        result[key] = process(value);
      }
      return result;
    }

    // Return other types (numbers, booleans, etc.) as-is
    return input;
  };

  return process(obj) as T;
}
