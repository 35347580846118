import { Household } from 'features/household/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';
import { GenericCheckout } from 'SignupQuestionnaire/components/GenericCheckout';

export const ReviewCheckout = ({
  questionnaireAnswers,
  routes,
}: CustomComponentProps<Household>) => {
  const { t } = useSafeTranslation();

  const hasSecurityDevice =
    questionnaireAnswers.securityDevices?.hasSecurityDevice;
  const basicPremium =
    questionnaireAnswers.quote?.priceDetails?.basicPremium ?? 0;

  const basicPrice = hasSecurityDevice ? basicPremium / 0.95 : basicPremium;
  const securityDeviceDiscount = hasSecurityDevice ? basicPrice * 0.05 : 0;
  const extendedCoveragePrice =
    (questionnaireAnswers.quote?.priceDetails?.extendedPremium ?? 0) -
    basicPremium;

  const hasExtendedCoverage =
    questionnaireAnswers.addonOptions?.selectedAddons.includes(
      'EXTENDED_COVERAGE'
    );
  const hasBikeCoverage =
    hasExtendedCoverage &&
    questionnaireAnswers.addonOptions?.selectedAddons.includes(
      'ADDITIONAL_BIKE_COVERAGE'
    );
  const hasGlassCoverage =
    questionnaireAnswers.addonOptions?.selectedAddons.includes(
      'BROKEN_GLASS_COVERAGE'
    );

  const questionnaireAnswersWithPriceDetails = {
    ...questionnaireAnswers,
    quote: {
      ...questionnaireAnswers.quote,
      price: questionnaireAnswers.quote?.totalPrice,
      basicPrice,
      ...(hasSecurityDevice && { securityDeviceDiscount }),
      ...(hasExtendedCoverage && { extendedCoveragePrice }),
      bikePrice: hasBikeCoverage
        ? questionnaireAnswers.quote?.priceDetails?.bikePrice
        : undefined,
      glassPrice: hasGlassCoverage
        ? questionnaireAnswers.quote?.priceDetails?.glassPrice
        : undefined,
    },
    questionnaireId: questionnaireAnswers.quote?.questionnaireId,
    quoteId: questionnaireAnswers.quote?.quoteId,
    ...questionnaireAnswers.addonOptions,
    bikeInsuredSum: hasBikeCoverage
      ? questionnaireAnswers.addonOptions?.bikeInsuredSum
      : undefined,
    ...questionnaireAnswers.securityDevices,
  };

  return (
    <GenericCheckout
      changeStartDatePath={routes.startDate.path}
      onSubmitValue={() => {}}
      setValidForSubmission={() => {}}
      featureName="questionnaire"
      question="review-checkout"
      questionnaireAnswers={questionnaireAnswersWithPriceDetails}
      type="HOUSEHOLD"
      region="de"
      policyInfoKind="HouseholdPolicy"
      hasCheckoutDocuments={true}
      policyDetailsConfiguration={{
        policyDetails: [
          {
            title: t(
              'page.household.questionnaire.checkout.policydetails.insuredsum',
              'Insured sum'
            ),
            questionId: 'insuredSum',
            dataType: 'CURRENCY',
          },
          {
            title: t(
              'page.household.questionnaire.checkout.policydetails.bikeinsuredsum',
              'Bike insured sum'
            ),
            questionId: 'bikeInsuredSum',
            dataType: 'CURRENCY',
          },
          {
            title: t(
              'page.household.questionnaire.checkout.policydetails.address',
              'Address'
            ),
            questionId: 'address',
          },
          {
            title: t(
              'page.household.questionnaire.checkout.policydetails.homesecuritydevice',
              'Home security device'
            ),
            questionId: 'hasSecurityDevice',
          },
          {
            title: t(
              'page.household.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'page.household.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'page.household.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
        ],
        otherSections: [
          {
            title: t(
              'page.household.questionnaire.checkout.policydetails.priceBreakdown',
              'Price breakdown'
            ),
            rows: [
              {
                title: t(
                  'page.household.questionnaire.checkout.policydetails.basiccoverage',
                  'Basic coverage'
                ),
                questionId: 'basicPrice',
                dataType: 'CURRENCY',
                noBreak: true,
              },
              {
                title: t(
                  'page.household.questionnaire.checkout.policydetails.discount',
                  '5% home security devices discount'
                ),
                questionId: 'securityDeviceDiscount',
                dataType: 'NEGATIVE_CURRENCY',
                noBreak: true,
              },
              {
                title: t(
                  'page.household.questionnaire.checkout.policydetails.extendendcoverage',
                  'Extended coverage add-on'
                ),
                questionId: 'extendedCoveragePrice',
                dataType: 'CURRENCY',
                noBreak: true,
              },
              {
                title: t(
                  'page.household.questionnaire.checkout.policydetails.brokenglasscoverage',
                  'Broken glass coverage add-on'
                ),
                questionId: 'glassPrice',
                dataType: 'CURRENCY',
                noBreak: true,
              },
              {
                title: t(
                  'page.household.questionnaire.checkout.policydetails.additionalbikecoverage',
                  'Additional bike coverage add-on'
                ),
                questionId: 'bikePrice',
                dataType: 'CURRENCY',
                noBreak: true,
              },
            ],
          },
        ],
      }}
    />
  );
};
