import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import LoadingSpinner from 'components/loadingSpinner';
import {
  basics,
  health,
  life,
} from 'features/policiesDashboard/assets/listGroup';
import { useRegion } from 'hooks/useRegion';
import { TFunction, useSafeTranslation } from 'shared/i18n';

import { PoliciesListView, ProductGroup } from './PoliciesList.view';
import { isInsuranceTypeAvailable } from './utils/isInsuranceTypeAvailable';

export const getTranslatedProductGroupKeys = (t: TFunction): ProductGroup[] => [
  {
    key: 'health',
    title: t('policies.list.group.health', 'Health'),
    icon: health,
    policies: [
      'PUBLIC_HEALTH',
      'PRIVATE_HEALTH',
      'INCOMING',
      'INCOMING_EU',
      'INCOMING_ES',
      'INCOMING_LT',
      'DENTAL',
    ],
  },
  {
    key: 'basics',
    title: t('policies.list.group.basics', 'Basics'),
    icon: basics,
    policies: [
      'LIABILITY',
      'HOUSEHOLD',
      'BIKE',
      'DOG_LIABILITY',
      'PET_HEALTH',
      'TRAVEL',
    ],
  },
  {
    key: 'life',
    icon: life,
    title: t('policies.list.group.life', 'Life'),
    policies: ['LIFE', 'DISABILITY', 'LEGAL', 'PENSION'],
  },
];

export const PoliciesList = () => {
  const { t } = useSafeTranslation();
  const { loading, region } = useRegion();
  const { flagsReady } = useFlagsStatus();
  const isExpatEu = useFlag('app_expat_eu');
  const isExpatLongTerm = useFlag('app_expat_lt');

  if (!flagsReady || loading) {
    return <LoadingSpinner />;
  }

  const productsList = getTranslatedProductGroupKeys(t)
    .map((group) => ({
      ...group,
      policies: group.policies.filter((insuranceType) =>
        isInsuranceTypeAvailable(
          insuranceType,
          region,
          isExpatLongTerm,
          isExpatEu
        )
      ),
    }))
    .filter((group) => group.policies.length > 0);

  return <PoliciesListView region={region} productsList={productsList} />;
};
