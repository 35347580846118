import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';
import View from 'features/paymentScreen/containers/PaymentScreen/PaymentScreen.view';
import { PriceInfo } from 'features/paymentScreen/paymentScreen.models';
import {
  PolicyDetailsConfiguration,
  processPolicyDetailsData,
} from 'SignupQuestionnaire/components/GenericCheckout/utils';

import { getCheckoutInfo } from '../Checkout/data';

export const GeneralPreviewGenericCheckout = <
  Questionnaire extends QuestionnaireQuestions
>(
  props: QuestionnaireFormProps<Questionnaire> & {
    hasCheckoutDocuments?: boolean;
    icon?: string;
    changeStartDatePath?: string;
    redirectAddDependent?: keyof Questionnaire | boolean;
    policyDetailsConfiguration?: PolicyDetailsConfiguration;
    mainPolicyId?: string;
  }
) => {
  const priceInfo: PriceInfo = {
    totalMonthlyPrice: 100,
    totalProratedPrice: 300,
    proratedPeriod: {
      start: dayjs().format('YYYY-MM-DD'),
      end: dayjs().add(1, 'year').format('YYYY-MM-DD'),
    },
  };

  const { questionnaireAnswers, policyDetailsConfiguration } = props;

  const checkoutInfo = getCheckoutInfo(questionnaireAnswers ?? {});

  const configuration = policyDetailsConfiguration
    ? processPolicyDetailsData(questionnaireAnswers, policyDetailsConfiguration)
    : undefined;

  return (
    <View
      policyDetailsProps={{
        checkoutInfo,
        configuration,
      }}
      isLoading={false}
      isSubmitButtonEnabled={false}
      priceInfo={priceInfo}
      isBeforePaypalRedirect={false}
      handleCheckout={() => {}}
      setPaymentOption={() => {}}
      onStripePaymentMethodTypeChange={() => {}}
      isValidStartDate={true}
      {...props}
    />
  );
};
