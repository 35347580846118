import { IbanInput, Input } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import QuestionnaireWrapper from 'components/questionnaireForms/QuestionnaireWrapper';
import { useSubmitLiabilityClaim } from 'features/liabilityClaims/hooks/useSubmitLiabilityClaim';
import { LiabilityClaims } from 'features/liabilityClaims/models';
import {
  QuestionnaireFormProps,
  QuestionnaireWrapperProps,
} from 'models/questionnaire';
import { FormEvent, useState } from 'react';
import { PayoutDetails as PayoutDetailsType } from 'shared/models/types';
import { isMobileApp } from 'shared/util/isMobileApp';
import { isValidIban } from 'shared/util/isValidIban';

const MIN_AMOUNT = 0.01;

const validate = (input: {
  amount?: number;
  iban?: string;
}): input is PayoutDetailsType => {
  return (
    (input.amount === undefined || input.amount >= MIN_AMOUNT) &&
    input.iban !== undefined &&
    isValidIban(input.iban) &&
    Boolean(input.iban)
  );
};

interface Props
  extends QuestionnaireFormProps<{
      amount?: number;
      iban?: string;
    }>,
    QuestionnaireWrapperProps {
  text: {
    amount: {
      title: string;
      description?: string;
      placeholder: string;
    };
    iban: {
      title: string;
      description: string;
      placeholder: string;
    };
  };
  questionnaireAnswers: Partial<LiabilityClaims>;
  metaData: { policyId: string };
}

export const PayoutDetails = ({
  value,
  onSubmitValue,
  header,
  question,
  description,
  error,
  loading,
  additionalInfo,
  buttonText,
  text,
  questionnaireAnswers,
  metaData: { policyId },
}: Props) => {
  const [validationError, setValidationError] = useState('');
  const [values, setValues] = useState(
    value ?? { amount: undefined, iban: undefined }
  );
  const isValid = validate(values);

  const {
    mutation: { mutate: submitLiabilityClaim },
    getErrorMessage: getClaimSubmissionError,
  } = useSubmitLiabilityClaim();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate(values)) {
      setValidationError(
        'Amount and/or IBAN values are missing for submission'
      );
      return;
    }

    submitLiabilityClaim(
      { answers: { ...questionnaireAnswers, payoutDetails: values }, policyId },
      {
        onSuccess: () => onSubmitValue(values),
      }
    );
  };

  return (
    <QuestionnaireWrapper
      onSubmit={handleSubmit}
      header={header}
      question={question}
      description={description}
      error={getClaimSubmissionError() || error || validationError}
      loading={loading}
      buttonText={buttonText}
      validForSubmission={!!isValid}
      additionalInfo={additionalInfo}
    >
      <div>
        {isMobileApp && <NavButton title="Previous question" renderInPortal />}

        <div className="p-h4 mb8">{text.amount.title}</div>
        {text.amount.description && (
          <p className="p-p--small tc-grey-600 mb8">
            {text.amount.description}
          </p>
        )}
        <Input
          placeholder={text.amount.placeholder}
          value={String(values?.amount)}
          onChange={(e) => {
            setValues((previousValues) => ({
              ...previousValues,
              amount: e.target.value ? parseFloat(e.target.value) : undefined,
            }));
          }}
          prefix="€"
          type="number"
          pattern="[0-9]*"
          data-cy="policies-liability-claim-payoutdetails-amount"
        />
      </div>

      <div className="mt24">
        <div className="p-h4 mb8">{text.iban.title}</div>
        <p className="p-p--small tc-grey-600 mb8">{text.iban.description}</p>
        <IbanInput
          placeholder={text.iban.placeholder}
          value={values?.iban}
          onChange={(iban) => {
            setValues((previousValues) => ({
              ...previousValues,
              iban,
            }));
          }}
          data-cy="policies-liability-claim-payoutdetails-iban"
        />
      </div>
    </QuestionnaireWrapper>
  );
};
