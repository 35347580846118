import { TFunction } from '@getpopsure/i18n-react';
import { calendly, faq, website } from '@getpopsure/private-constants';
import { images } from '@popsure/dirty-swan';
import {
  centimetersToFeet,
  feetToCentimeters,
  kgToLbs,
  lbsToKg,
} from 'shared/util/converters';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createPostQuote, reviewAction } from '../actions';
import { EligibilityCheck } from '../components/eligibilityCheck/EligibilityCheck';
import { Intro } from '../components/intro';
import { QuotePage } from '../components/quotePage/QuotePage';
import {
  climbingSituationsMap,
  DegreeMap,
  healthConditionsMapping,
  Life,
  LifeGroupIds,
  occupationMapping,
  questionsMapping,
  riskySportsMapping,
  scubaDivingSituationsMap,
} from '../models';

export const LifeComponents = {
  LIFE_INTRO: Intro,
  ELIGIBILITY_CHECK: EligibilityCheck,
  CUSTOM_QUOTE_PAGE: QuotePage,
} as const;

export type LifeQuestionnaire = SignupQuestionnaireType<
  Life,
  LifeGroupIds,
  typeof LifeComponents
>;

export const getTranslatedQuestionnaire = (t: TFunction): LifeQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'preQuote',
    props: {
      title: "Hey! Let's get you a quote in minutes",
      subTitle:
        'We need a few pieces of information about you and we can figure out the rest.',
    },
    screen: {
      continueButtonText: 'Get started',
    },
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: questionsMapping.dateOfBirth,
    },
    groupId: 'signup',
  },
  {
    id: 'under18Blocker',
    type: 'BLOCKER',
    props: {
      title: 'Unfortunately...',
      iconType: 'SHIELD',
      description:
        'We currently only cover people who are over 18 years old.\n\nYou can speak to one of our insurance experts if you have any questions.',
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'over65Blocker',
    type: 'BLOCKER',
    props: {
      title: 'Unfortunately...',
      iconType: 'SHIELD',
      description:
        'We currently only cover people who are under 65 years old.\n\nYou can speak to one of our insurance experts if you have any questions.',
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'height',
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      conversion: {
        type: 'Double',
        fns: [centimetersToFeet, feetToCentimeters],
      },
      unitLabels: ['cm', 'feet'],
    },
    screen: {
      question: questionsMapping.height,
    },
    groupId: 'preQuote',
  },
  {
    id: 'weight',
    required: true,
    type: 'NUMERIC_CONVERSION',
    props: {
      conversion: { type: 'Single', fns: [kgToLbs, lbsToKg] },
      unitLabels: ['kg', 'lbs'],
    },
    screen: {
      question: questionsMapping.weight,
    },
    groupId: 'preQuote',
  },
  {
    id: 'bmiBlocker',
    type: 'BLOCKER',
    props: {
      title: "We can't sign you up...",
      iconType: 'SHIELD',
      description:
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nYou can speak to one of our insurance experts if you have any questions.",
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
          variant: 'PRIMARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'isManualLabor',
    required: true,
    type: 'BOOLEAN',
    props: {},
    groupId: 'preQuote',
    screen: {
      question: questionsMapping.isManualLabor,
      additionalInfo: {
        title: 'What is considered as manual labor?',
        description:
          "If more than 80% of your time at work requires physical activity (like moving boxes, stocking shelves, operating machinery, doing construction work etc.), you're considered to be working in manual labor.",
      },
    },
  },
  {
    id: 'degree',
    type: 'RADIO',
    props: {
      mapValue: DegreeMap,
    },
    screen: {
      question: questionsMapping.degree,
    },
    groupId: 'preQuote',
  },
  {
    id: 'tobaccoUse',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.tobaccoUse,
      additionalInfo: {
        title: 'What counts as smoking?',
        description:
          'Using any of these products: cigarettes, marijuana, e-cigarettes, vaping, hookahs, cigars, chewing tobacco, nicotine replacement therapies (patches, lozenges, gum, inhalers, nose sprays), etc.',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'tobaccoUseRecent',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question:
        'Have you smoked or used nicotine products in the last 12 months?',
      additionalInfo: {
        title: 'What counts as smoking?',
        description:
          'Using any of these products: cigarettes, marijuana, e-cigarettes, vaping, hookahs, cigars, chewing tobacco, nicotine replacement therapies (patches, lozenges, gum, inhalers, nose sprays), etc.',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'CUSTOM_QUOTE_PAGE',
    groupId: 'signup',
    props: {
      config: {
        title: 'Life insurance',
        headerImage: {
          src: images.verticalIcons.lifeIcon,
          alt: 'life',
        },
        actions: {
          valuesMap: {
            0: '129',
            1: '140',
          },
          items: [
            {
              text: 'this is a placeholder for now',
              type: 'Description',
            },
          ],
          button: {
            text: 'Continue',
          },
          submitMap: [],
        },
        coveredSection: {
          title: "What's covered?",
          items: [
            {
              title: 'Debt and Mortgage',
              description:
                "Don't let your financial obligations get passed on to your family. Life insurance can help cover them.",
              icon: {
                src: images.mortgage,
                alt: 'Mortgage',
              },
            },
            {
              title: 'Lost Income',
              description:
                "Are you the primary source of income for your family? Life insurance can provide for them when you're gone, so they can get back on their feet.",
              icon: {
                src: images.bill,
                alt: 'Bill',
              },
            },
            {
              title: 'Childcare and Education',
              description:
                "Education, childcare and security. Life insurance can help ensure a future for your loved ones when you're no longer with them.",
              icon: {
                src: images.books,
                alt: 'Books',
              },
            },
            {
              title: 'Final Expenses',
              description:
                'No one wants to plan a funeral and the costs can get pretty expensive. Let life insurance cover it while your family mourns.',
              icon: {
                src: images.finalExpenses,
                alt: 'Final Expenses',
              },
            },
          ],
        },
        reviewBadge: true,
        faq: {
          title: 'Frequently asked questions',
          data: [
            {
              questionId: 1,
              question: 'Who needs term life insurance?',
              answer:
                '• Anyone that is responsible not just for themselves but responsible for someone else. This can be a partner, a child, or a relative that is financially dependent on you.\n\n• If you have a mortgage, it also makes sense to get life insurance for the primary earner. If two people contribute equally to a policy, it makes sense to get life insurance for both people.\n\n• If you have a business with a business partner, you should consider life insurance. They can also be included as "dependent" on you.',
            },
            {
              questionId: 2,
              question: 'How expensive is it?',
              answer:
                "In the cases where you need it most, it is the cheapest. If you're a young family, for example, it's much cheaper than if you're already much older - but in this situation, you likely have more savings to rely on and don't need as high a cover sum.",
            },
            {
              questionId: 3,
              question: 'How much cover should I get?',
              answer:
                "Work out for each of these how much you need and add them up:\n\n- In general, we recommend around 10k Euros per child per year until he or she is 18 (or 23 if you want to finance university)\n\n- Additionally, if you have a mortgage, you should add the total cost of the mortgage\n\n- If your partner is reliant on you, then 3-5 years of all expenses paid is recommended\n\n \n\nIf that's too complicated, you can get 3-5x your gross yearly salary, and it will be a good approximation",
            },
            {
              questionId: 4,
              question: 'For how many years should I get the cover?',
              answer:
                'We recommend getting the cover until your mortgage is paid off or your youngest child has completed school or university - whichever is later. When some risk is gone, you can adjust your cover.',
            },
            {
              questionId: 5,
              question: 'Can I adjust my cover?',
              answer:
                'Yes. You can adjust your cover sum down with our policy at the end of each year, so if you have paid off your mortgage but still have children you want cover for, you can adjust the covered sum accordingly. You can also cancel the policy at any time with no additional fees.',
            },
            {
              questionId: 6,
              question: 'When does the policy pay out?',
              answer: 'The policy pays out when the insured person dies.',
            },
          ],
          footer: `See our [FAQ page](${faq.base}) for more answers.`,
        },
        moreQuestionsSection: {
          bookACallGACategory: 'Life',
          title: 'Have more questions?',
          bookACallUrl: calendly.lifeInsurance,
          secondaryActionLink: faq.base,
          secondaryAction: 'Visit our FAQ',
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  // personal information
  {
    id: 'introSteps',
    required: true,
    type: 'LIFE_INTRO',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },

  {
    id: 'occupation',
    type: 'RADIO',
    required: true,
    props: {
      mapValue: occupationMapping(t),
    },
    screen: {
      question: questionsMapping.occupation,
      additionalInfo: {
        title: 'Working for an international company?',
        description:
          'If your company has an office in Germany or has a German tax accountant, you are considered to be employed in Germany.',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'occupationBlocker',
    type: 'BLOCKER',
    props: {
      title: "We can't sign you up...",
      iconType: 'SHIELD',
      description:
        "We're constantly working on expanding our coverage, but there are still cases that we’re not able to cover at this moment.\n\nIf you have any questions, you can reach out to one of our insurance experts.",
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Contact us',
          variant: 'PRIMARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'countryOfResidence',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: questionsMapping.countryOfResidence,
    },
    groupId: 'signup',
  },
  {
    id: 'countryBlocker',
    type: 'BLOCKER',
    props: {
      title: "We can't sign you up...",
      iconType: 'SHIELD',
      description:
        'This life insurance policy is only available for the residents of Germany.\n\nWe’re constantly working on expanding our coverage, but there are still cases that we’re not able to cover at this moment. If you have any questions, you can reach out to one of our insurance experts.',
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Contact us',
          variant: 'PRIMARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    type: 'ADDRESS',
    required: true,
    props: {},
    screen: {
      question: questionsMapping.address,
      noMaxWidth: true,
    },
    groupId: 'signup',
  },
  {
    id: 'travelPlansLength',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.travelPlansLength,
    },
    groupId: 'signup',
  },
  {
    id: 'travelPlansCountry',
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: questionsMapping.travelPlansCountry,
    },
    groupId: 'signup',
  },
  {
    id: 'hasReviewedPersonalInfo',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: 'Employment status',
          value: {
            type: 'String',
            key: 'occupation',
            valueMap: {
              EMPLOYED: 'Employed',
              SELF_EMPLOYED: 'Self-employed',
              UNEMPLOYED: 'Unemployed',
              STUDENT: 'Student',
              RETIRED: 'Retired',
              OTHER: 'Other',
            },
          },
          path: 'occupation',
        },
        {
          title: 'Country of residence',
          value: {
            type: 'Strings',
            key: 'countryOfResidence',
            keys: ['name'],
          },
          path: 'countryOfResidence',
        },
        {
          title: 'Address',
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: 'Abroad stay within the next year',
          value: { type: 'Boolean', key: 'travelPlansLength' },
          path: 'travelPlansLength',
        },
        {
          title: 'Countries for abroad stay',
          value: { type: 'Array', pick: ['name'], key: 'travelPlansCountry' },
          path: 'travelPlansCountry',
        },
      ],
      verticalId: 'life',
      insuranceType: 'LIFE_V2',
      createPostQuote: reviewAction,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  // insurance history
  {
    id: 'previousApplications',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.previousApplications,
    },
    groupId: 'signup',
  },
  {
    id: 'previousApplicationsRefused',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.previousApplicationsRefused,
    },
    groupId: 'signup',
  },
  {
    id: 'otherActiveInsurance',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.otherActiveInsurance,
    },
    groupId: 'signup',
  },
  {
    id: 'otherActiveInsuranceCoverage',
    type: 'NUMBER',
    props: {
      prefix: '€',
      placeholder: 'Amount',
    },
    screen: {
      question: questionsMapping.otherActiveInsuranceCoverage,
    },
    groupId: 'signup',
  },
  {
    id: 'income',
    type: 'NUMBER',
    props: {
      prefix: '€',
      placeholder: 'Amount',
    },
    screen: {
      question: questionsMapping.income,
    },
    groupId: 'signup',
  },
  {
    id: 'hasReviewedInsuranceHistory',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: 'Previous life insurance application',
          value: { type: 'Boolean', key: 'previousApplications' },
          path: 'previousApplications',
        },
        {
          title:
            'Previous application refused, postponed or accepted on special terms',
          value: { type: 'Boolean', key: 'previousApplicationsRefused' },
          path: 'previousApplicationsRefused',
        },
        {
          title: 'Other active insurance plan(s)',
          value: {
            type: 'Boolean',
            key: 'otherActiveInsurance',
          },
          path: 'otherActiveInsurance',
        },
        {
          title: 'Current coverage (€)',
          value: { type: 'String', key: 'otherActiveInsuranceCoverage' },
          path: 'otherActiveInsuranceCoverages',
        },
        {
          title: 'Annual income (€)',
          value: { type: 'String', key: 'income' },
          path: 'income',
        },
      ],
      verticalId: 'life',
      insuranceType: 'LIFE_V2',
      createPostQuote: reviewAction,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  // medical history
  {
    id: 'medicationUse',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.medicationUse,
    },
    groupId: 'signup',
  },
  {
    id: 'medicationUseHealthStatus',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.medicationUseHealthStatus,
      additionalInfo: {
        title: 'What does completely healed mean?',
        description:
          "You're considered completely healed when you have not had any symptoms and have not taken any medication for this illness in the last two years.",
      },
    },
    groupId: 'signup',
  },
  {
    id: 'medicationUseDetail',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.medicationUseDetail,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What type of medication did you use?\n\n• How often did you consume them? ',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'inabilityToWork',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.inabilityToWork,
    },
    groupId: 'signup',
  },
  {
    id: 'inabilityToWorkReason',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.inabilityToWorkReason,
    },
    groupId: 'signup',
  },
  {
    id: 'inabilityToWorkHealthStatus',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.inabilityToWorkHealthStatus,
      additionalInfo: {
        title: 'What does completely healed mean?',
        description:
          "You're considered completely healed when you have not had any symptoms and have not taken any medication for this illness in the last two years.",
      },
    },
    groupId: 'signup',
  },
  {
    id: 'inabilityToWorkDetail',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.inabilityToWorkDetail,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What treatments did you receive for this underlying illness?\n\n• How often did the illness occur?\n\n• How was your quality of life impacted?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'healthConditions',
    required: true,
    type: 'CHECKBOX',
    props: {
      mapValue: healthConditionsMapping,
    },
    screen: {
      question: questionsMapping.healthConditions,
    },
    groupId: 'signup',
  },

  {
    id: 'bloodPressureTreatment',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.bloodPressureTreatment,
    },
    groupId: 'signup',
  },
  {
    id: 'bloodPressureMeasures',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.bloodPressureMeasures,
    },
    groupId: 'signup',
  },
  {
    id: 'bloodPressure',
    type: 'MULTI_NUMBER_INPUT',
    props: {
      data: {
        systolic: { placeholder: 'Systolic value (mm Hg)' },
        diastolic: { placeholder: 'Diastolic value (mm Hg)' },
      },
    },
    screen: {
      question: questionsMapping.bloodPressure,
    },
    groupId: 'signup',
  },
  {
    id: 'bloodPressureDate',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 20 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: { type: 'Info', value: 'Date can not be in the future' },
      },
    ],
    screen: {
      question: questionsMapping.bloodPressureDate,
    },
    groupId: 'signup',
  },
  {
    id: 'hospitalStay',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.hospitalStay,
    },
    groupId: 'signup',
  },
  {
    id: 'hospitalStayHealthStatus',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.hospitalStayHealthStatus,
      additionalInfo: {
        title: 'What does completely healed mean?',
        description:
          "You're considered completely healed when you have not had any symptoms and have not taken any medication for this illness in the last two years.",
      },
    },
    groupId: 'signup',
  },
  {
    id: 'hospitalStayDetails',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.hospitalStayDetails,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What treatments did you receive for this underlying illness?\n\n• How often did the illness occur?\n\n• How was your quality of life impacted?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'medicalCheckups',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.medicalCheckups,
    },
    groupId: 'signup',
  },
  {
    id: 'medicalCheckupsDetails',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.medicalCheckupsDetails,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What treatments did you receive for this underlying illness?\n\n• How often did the illness occur?\n\n• How was your quality of life impacted?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'alcoholDrugsDoctor',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.alcoholDrugsDoctor,
    },
    groupId: 'signup',
  },
  {
    id: 'alcoholDrugsDetails',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.alcoholDrugsDetails,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What treatments did you receive for this underlying illness?\n\n• How often did the illness occur?\n\n• How was your quality of life impacted?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'drugsUsage',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.drugsUsage,
    },
    groupId: 'signup',
  },
  {
    id: 'drugsUsageDetails',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.drugsUsageDetails,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What type of drugs or narcotics did you use?\n\n• How often did you consume them?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'hiv',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.hiv,
    },
    groupId: 'signup',
  },
  {
    id: 'hivDetails',
    type: 'TEXT',
    props: {
      placeholder: 'Begin typing here...',
    },
    screen: {
      question: questionsMapping.hivDetails,
      additionalInfo: {
        title: "Don't know what to say? Try answering the following:",
        description:
          '• What type of treatment are you receiving?\n\n• When did this occur?',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'hasReviewedMedicalHistory',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: 'Medication in the last 5 years?',
          value: { type: 'Boolean', key: 'medicationUse' },
          path: 'medicationUse',
        },
        {
          title: 'Use of medication - completely healed?',
          value: { type: 'Boolean', key: 'medicationUseHealthStatus' },
          path: 'medicationUseHealthStatus',
        },
        {
          title: 'Details on medication',
          value: { type: 'String', key: 'medicationUseDetail' },
          path: 'medicationUseDetail',
        },
        {
          title: 'Recently unable to work?',
          value: { type: 'Boolean', key: 'inabilityToWork' },
          path: 'inabilityToWork',
        },
        {
          title: 'Illness, pain or health disorders?',
          value: { type: 'Boolean', key: 'inabilityToWorkReason' },
          path: 'inabilityToWorkReason',
        },
        {
          title: 'Inability to work - completely healed?',
          value: { type: 'Boolean', key: 'inabilityToWorkHealthStatus' },
          path: 'inabilityToWorkHealthStatus',
        },
        {
          title: 'Details on inability to work',
          value: { type: 'String', key: 'inabilityToWorkDetail' },
          path: 'inabilityToWorkDetail',
        },
        {
          title: 'Medical conditions',
          value: {
            type: 'Array',
            key: 'healthConditions',
            valueMap: healthConditionsMapping,
          },
          path: 'healthConditions',
        },
        {
          title: 'Recent high blood pressure?',
          value: { type: 'Boolean', key: 'bloodPressureTreatment' },
          path: 'bloodPressureTreatment',
        },
        {
          title: 'Normal range blood pressure?',
          value: { type: 'Boolean', key: 'bloodPressureMeasures' },
          path: 'bloodPressureMeasures',
        },
        {
          title: 'Blood pressure',
          value: { type: 'Object', key: 'bloodPressure' },
          path: 'bloodPressure',
        },
        {
          title: 'Blood pressure measurement date',
          value: { type: 'String', key: 'bloodPressureDate' },
          path: 'bloodPressureDate',
        },
        {
          title: 'Overnight stay at hospital?',
          value: { type: 'Boolean', key: 'hospitalStay' },
          path: 'hospitalStay',
        },
        {
          title: 'Overnight stay at hospital - completed healed?',
          value: { type: 'Boolean', key: 'hospitalStayHealthStatus' },
          path: 'hospitalStayHealthStatus',
        },
        {
          title: 'Details on overnight stay at hospital',
          value: { type: 'String', key: 'hospitalStayDetails' },
          path: 'hospitalStayDetails',
        },
        {
          title: 'Recent medical results',
          value: { type: 'Boolean', key: 'medicalCheckups' },
          path: 'medicalCheckups',
        },
        {
          title: 'Details on recent medical results',
          value: { type: 'String', key: 'medicalCheckupsDetails' },
          path: 'medicalCheckupsDetails',
        },
        {
          title: 'Recent doctor visit about alcohol or drug use',
          value: { type: 'Boolean', key: 'alcoholDrugsDoctor' },
          path: 'alcoholDrugsDoctor',
        },
        {
          title: 'Details on alcohol or drug doctor visit',
          value: { type: 'String', key: 'alcoholDrugsDoctorDetails' },
          path: 'alcoholDrugsDoctorDetails',
        },
        {
          title: 'Drug or narcotics recent usage',
          value: { type: 'Boolean', key: 'drugsUsage' },
          path: 'drugsUsage',
        },
        {
          title: 'Details recent on drug or narcotics usage',
          value: { type: 'String', key: 'drugsUsageDetails' },
          path: 'drugsUsageDetails',
        },
        {
          title: 'Diagnosed with HIV/AIDS',
          value: { type: 'Boolean', key: 'hiv' },
          path: 'hiv',
        },
        {
          title: 'Details on HIV/AIDS',
          value: { type: 'String', key: 'hivDetails' },
          path: 'hivDetails',
        },
      ],
      verticalId: 'life',
      insuranceType: 'LIFE_V2',
      createPostQuote: reviewAction,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  // lifestyle
  {
    id: 'sportsParticipation',
    required: true,
    type: 'CHECKBOX',
    props: {
      mapValue: riskySportsMapping,
    },
    screen: {
      question: questionsMapping.sportsParticipation,
    },
    groupId: 'signup',
  },
  {
    id: 'climbing',
    type: 'CHECKBOX',
    props: {
      mapValue: climbingSituationsMap,
    },
    screen: {
      question: questionsMapping.climbing,
    },
    groupId: 'signup',
  },
  {
    id: 'bouldering',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.bouldering,
    },
    groupId: 'signup',
  },
  {
    id: 'martialArts',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.martialArts,
    },
    groupId: 'signup',
  },
  {
    id: 'extremeSports',
    type: 'TEXT',
    props: {},
    screen: {
      question: questionsMapping.extremeSports,
    },
    groupId: 'signup',
  },
  {
    id: 'scubaDiving',
    type: 'CHECKBOX',
    props: {
      mapValue: scubaDivingSituationsMap,
    },
    screen: {
      question: questionsMapping.scubaDiving,
    },
    groupId: 'signup',
  },
  {
    id: 'weapons',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.weapons,
    },
    groupId: 'signup',
  },
  {
    id: 'fireArmsUse',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.fireArmsUse,
    },
    groupId: 'signup',
  },
  {
    id: 'dangerousActivities',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: questionsMapping.dangerousActivities,
      additionalInfo: {
        title: 'Defining dangerous activities',
        description:
          'Examples may include practicing any air sports or working in the security services, traveling to crisis areas, etc.',
      },
    },
    groupId: 'signup',
  },
  {
    id: 'dangerousActivitiesDetails',
    type: 'TEXT',
    props: {},
    screen: {
      question: questionsMapping.dangerousActivitiesDetails,
    },
    groupId: 'signup',
  },
  {
    id: 'hasReviewedLifestyle',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: 'Risky sports',
          value: {
            type: 'Array',
            key: 'sportsParticipation',
            valueMap: {
              RACING: 'Competitive racing',
              CLIMBING: 'Mountain or rock climbing',
              BOULDERING: 'Bouldering',
              MARTIAL_ARTS: 'Martial arts',
              EXTREME_SPORTS: 'Extreme sports',
              SCUBA_DIVING: 'Scuba diving',
              NONE: 'None',
            },
          },
          path: 'sportsParticipation',
        },
        {
          title: 'Mountain or rock climbing situations',
          value: {
            type: 'Array',
            key: 'climbing',
            valueMap: climbingSituationsMap,
          },
          path: 'climbing',
        },
        {
          title: 'Martial arts as a job?',
          value: { type: 'Boolean', key: 'martialArts' },
          path: 'martialArts',
        },
        {
          title: 'Extreme sports',
          value: { type: 'String', key: 'extremeSports' },
          path: 'extremeSports',
        },
        {
          title: 'Scuba diving situations',
          value: {
            type: 'Array',
            key: 'scubaDiving',
            valueMap: scubaDivingSituationsMap,
          },
          path: 'scubaDiving',
        },
        {
          title: 'Outdoor bouldering?',
          value: { type: 'Boolean', key: 'bouldering' },
          path: 'bouldering',
        },
        {
          title: 'Use of explosives or weapons?',
          value: { type: 'Boolean', key: 'weapons' },
          path: 'weapons',
        },
        {
          title: 'Hunting, shooting or forester?',
          value: { type: 'Boolean', key: 'fireArmsUse' },
          path: 'fireArmsUse',
        },
        {
          title: 'Other dangerous activities?',
          value: { type: 'Boolean', key: 'dangerousActivities' },
          path: 'dangerousActivities',
        },
        {
          title: 'Details on dangerous activities',
          value: { type: 'String', key: 'dangerousActivitiesDetails' },
          path: 'dangerousActivitiesDetails',
        },
      ],
      verticalId: 'life',
      insuranceType: 'LIFE_V2',
      createPostQuote: reviewAction,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'hasReviewedEligibility',
    required: true,
    type: 'ELIGIBILITY_CHECK',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'eligibilityBlocker',
    type: 'BLOCKER',
    props: {
      title: "We can't sign you up...",
      iconType: 'SHIELD',
      description:
        "We’re working hard to make our policies cover all use cases, but still have certain limitations. We won't be able to sign you up for our life insurance plan.\n\nStill, we might have some other options for you. You can speak to one of our insurance experts, and learn more about why you didn't qualify for life insurance or what aspects are used to determine eligibility.",
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Contact us',
          variant: 'PRIMARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  // account creation
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'life',
    },
    screen: {
      layout: 'Standalone',
      question: questionsMapping.email,
      description: 'This policy will be accessible in your Feather account.',
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: questionsMapping.name,
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: 'Email',
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: "Policy holder's name",
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
      ],
      verticalId: 'life',
      insuranceType: 'LIFE_V2',
      createPostQuote: () => createPostQuote(t),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'GENERIC_CHECKOUT',
    props: {
      type: 'LIFE_V2',
      icon: images.verticalIcons.lifeIcon,
      policyInfoKind: 'LifePolicy',
      hasCheckoutDocuments: true,
      region: 'de',
      policyDetailsConfiguration: {
        policyDetails: [
          {
            title: t(
              'life.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'termStartDate',
            additionalInfo: {
              title: t(
                'life.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'life.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
          {
            title: t('life.qnr.checkout.policyDetails.term', 'Term'),
            questionId: 'duration',
            dataType: 'YEARS',
          },
          {
            title: t(
              'life.qnr.checkout.policyDetails.termStartDate',
              'Term start date'
            ),
            questionId: 'termStartDate',
          },
          {
            title: t(
              'life.qnr.checkout.policyDetails.termEndDate',
              'Term end date'
            ),
            questionId: 'termEndDate',
          },
          {
            title: t('life.qnr.checkout.policyDetails.coverage', 'Coverage'),
            questionId: 'coverage',
            dataType: 'CURRENCY',
          },
          {
            title: t(
              'life.qnr.checkout.policyDetails.insuredPerson',
              'Insured Person'
            ),
            questionId: 'name',
          },
        ],
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
