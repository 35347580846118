import {
  ACTIVE_TARIFFS,
  ActiveTariff,
  AddOn,
  DeductibleForTariff,
  EMPLOYMENT_STATUSES,
  EmploymentStatus,
  LegacyEmploymentStatus,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { EmploymentStatus as PublicEmploymentStatus } from '@getpopsure/public-health-insurance-pricing-engine';
import { ContributionPrices } from '@getpopsure/public-health-insurance-pricing-engine/dist/esm/models';
import { allCountryCodes, Country } from '@getpopsure/public-models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { ZEmailSchema, ZNameSchema } from 'shared/models/validations';
import { z } from 'zod';

export const shortTermPlanOptions: Tariff[] = ['HiMedical', 'HiMedicalPlus'];
export const longTermPlanOptions: Tariff[] = [
  'NKSelectS',
  'NKSelectL',
  'NKSelectXL',
];

export type Tariff = ActiveTariff;
export type TariffsWithPublic = Tariff | 'Public';

export type Modal = {
  title: string;
  subtitle?: string;
  wide?: boolean;
  children: string | React.ReactNode;
};

export interface ModalWithTracking extends Modal {
  tariff: TariffsWithPublic | 'header';
  fieldKey: string;
}

export const RESIDENCY_PERMIT_TYPES = [
  'LIMITED_RESIDENCE_PERMIT',
  'UNLIMITED_RESIDENCE_PERMIT',
] as const;
export type ResidencePermitType = typeof RESIDENCY_PERMIT_TYPES[number];

export const employmentStatusToLegacyEmploymentStatusMapping: Record<
  EmploymentStatus,
  LegacyEmploymentStatus
> = {
  EMPLOYED_IN_GERMANY: 'EMPLOYED',
  EMPLOYED_OUTSIDE_OF_GERMANY: 'EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  OTHER: 'OTHER',
};

export const privateToPublicEmploymentStatusMapping: Record<
  EmploymentStatus,
  PublicEmploymentStatus
> = {
  EMPLOYED_IN_GERMANY: 'EMPLOYED',
  EMPLOYED_OUTSIDE_OF_GERMANY: 'SELF_EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  // The public health pricing engine returns undefined for "OTHER", so in order to be able to show an approximate price, use the "SELF_EMPLOYED" value instead
  OTHER: 'SELF_EMPLOYED',
};

export const HEALTH_CONDITIONS = [
  'ADHD',
  'ARTHRITIS',
  'ASTHMA',
  'AUTOIMMUNE_DISORDER',
  'CANCER',
  'DIABETES',
  'DRUG_ADDICTION',
  'EPILEPSY',
  'HEPATITIS',
  'HIV_AIDS',
  'MENTAL_ILLNESS',
  'MULTIPLE_SCLEROSIS',
  'PREP',
  'PSYCHOTHERAPY',
  'NONE',
] as const;
export type HealthCondition = typeof HEALTH_CONDITIONS[number];

export const NUMBER_OF_PSYCHOTHERAPY_SESSIONS_OPTIONS = [
  'LESS_THAN_5',
  'BETWEEN_5_AND_25',
  'MORE_THAN_25',
] as const;
export type NumberOfPsychotherapySessions =
  typeof NUMBER_OF_PSYCHOTHERAPY_SESSIONS_OPTIONS[number];

export const PSYCHOTHERAPY_STATUSES = [
  'ENDED_MORE_THAN_5_YEARS_AGO',
  'ENDED_LESS_THAN_5_YEARS_AGO',
  'ONGOING',
] as const;
export type PsychotherapyStatus = typeof PSYCHOTHERAPY_STATUSES[number];

export const FAMILY_COVERAGE_OPTIONS = ['YES', 'NO', 'NOT_NOW'] as const;
export type FamilyCoverage = typeof FAMILY_COVERAGE_OPTIONS[number];

export const DEPENDENT_TYPES = ['SPOUSE', 'CHILDREN'] as const;
export type DependentType = typeof DEPENDENT_TYPES[number];

export type PrivatePreSignup = {
  welcome: boolean;
  birthYear: number;
  citizenship: Country[];
  residencyPermitType?: ResidencePermitType;
  relocatedMoreThan4YearsAgo?: boolean;
  eligibleForShortTerm: boolean;
  employmentStatus: EmploymentStatus;
  income: number;
  hanseMerkurRedirect: null;
  freelancerNotEligibleBlocker: null;
  incomeUnderEmployeeThresholdBlocker: null;
  familyCoverage: FamilyCoverage;
  dependentTypes?: DependentType[];
  spouseHasOwnIncome?: boolean;
  spouseHasOwnIncomeInfo: boolean;
  experiencedConditions: HealthCondition[];
  experiencedConditionsBlocker: null;
  prepOrAsthmaOnlyBlocker: null;
  numberOfPsychotherapySessions?: NumberOfPsychotherapySessions;
  psychotherapyStatus?: PsychotherapyStatus;
  calculatePrice: boolean;
  dependentSignupBlocker: null;
  hashOfQuestionnaire: string;
  questionnaireId: string;
  showLongTerm: boolean;
  quote: {
    tariff: Tariff;
    deductible: DeductibleForTariff<Tariff>;
    selectedAddOns: AddOn[];
    sickDayPayout: number;
  };
  planReview: boolean;
  redirectToPrivateSignup: null;
};

export type PrivatePreSignupQuestionnaireGroupId = 'signup';

export type PrivatePreSignupQuestionnaire = PartialQuestionnaire<
  PrivatePreSignup,
  PrivatePreSignupQuestionnaireGroupId
>;

const CURRENT_ADDONS = ['NKSFLEX', 'FLEX', 'KT', 'URZ', 'HD', 'HDL'] as const;

const CURRENT_DEDUCTIBLES = ['0', '500'] as const;

const ZCountrySchema = z.object({
  code: z.enum(allCountryCodes),
  name: z.string(),
});

export const ZPrivatePreSignupRequiredAnswersForQuestionnaireSubmissionSchema =
  z.object({
    email: z.string().optional(),
    birthYear: z.number(),
    citizenship: z.array(ZCountrySchema),
    residencyPermitType: z.enum(RESIDENCY_PERMIT_TYPES).optional(),
    relocatedMoreThan4YearsAgo: z.boolean().optional(),
    eligibleForShortTerm: z.boolean(),
    employmentStatus: z.enum(EMPLOYMENT_STATUSES),
    income: z.number(),
    familyCoverage: z.enum(FAMILY_COVERAGE_OPTIONS),
    dependentTypes: z.array(z.enum(DEPENDENT_TYPES)).optional(),
    spouseHasOwnIncome: z.boolean().optional(),
    experiencedConditions: z.array(z.enum(HEALTH_CONDITIONS)),
    numberOfPsychotherapySessions: z
      .enum(NUMBER_OF_PSYCHOTHERAPY_SESSIONS_OPTIONS)
      .optional(),
    psychotherapyStatus: z.enum(PSYCHOTHERAPY_STATUSES).optional(),
    quote: z
      .object({
        tariff: z.enum(ACTIVE_TARIFFS),
        deductible: z.enum(CURRENT_DEDUCTIBLES),
        selectedAddOns: z.array(z.enum(CURRENT_ADDONS)),
        sickDayPayout: z.number(),
      })
      .optional(),
    showLongTerm: z.boolean().optional(),
  });

export const ZPrivatePreSignupRequiredAnswersForRedirectSchema = z.object({
  birthYear: z.number(),
  employmentStatus: z.enum(EMPLOYMENT_STATUSES),
  income: z.number(),
  quote: z.object({
    tariff: z.enum(ACTIVE_TARIFFS),
    deductible: z.enum(CURRENT_DEDUCTIBLES),
    selectedAddOns: z.array(z.enum(CURRENT_ADDONS)),
    sickDayPayout: z.number(),
  }),
  name: ZNameSchema.optional(),
  email: ZEmailSchema.optional(),
  citizenship: z.array(ZCountrySchema).optional(),
  residencyPermitType: z.enum(RESIDENCY_PERMIT_TYPES).optional(),
  employedOutsideGermany: z.boolean().optional(),
});

export type ValueWithDescription = {
  value: boolean | string;
  description?: string;
};

export type TableData = {
  // birthYear and sickDayPayout are needed for cashback calculations
  birthYear?: number;
  sickDayPayout?: number;

  id: number;
  name: string;
  tariff?: Tariff;
  tariffKey: TariffsWithPublic;
  contribution?: number | string;
  cashback?: Tariff | boolean;
  deductible: string | boolean;
  deductibleInfo?: string;
  dependents: boolean | string;
  dependentsInfo?: string;

  /** general */
  generalDoctor: string | boolean;
  specialist: string | boolean;
  specialistInfo?: string;
  medication: string | boolean;
  medicationInfo?: string;
  vaccination: string | boolean;
  vaccinationInfo?: string;
  transports: string | boolean;
  transportsInfo?: string;
  sickPayCoverage: string;
  sickPayCoverageInfo?: string;
  digitalHealthApps: string | boolean;
  digitalHealthAppsInfo?: string;
  nonMedicalPrescriptions: boolean;
  purelyCosmeticProcedures: boolean;

  /** dental */
  annualCoverage: string;
  annualCoverageInfo?: string;
  dentalCheckups: string | boolean;
  dentalCleanings: ValueWithDescription;
  dentist: boolean | string;
  inlays: boolean | string;
  inlaysInfo?: string;
  dentures: boolean | string;
  denturesInfo?: string;
  orthodonticsChildren: boolean | string;
  orthodonticsChildrenInfo?: string;

  /** treatment and therapies */
  preventiveCare: boolean | string;
  preventiveCareInfo?: string;
  therapeutic: string | boolean;
  therapeuticInfo?: string;
  mentalHealth: string | boolean;
  mentalHealthInfo?: string;
  radioTherapy: string | boolean;
  natural: string | boolean;
  naturalInfo?: string;
  medicalAids: string;
  medicalAidsInfo?: string;

  /** vision */
  visionAids: string | boolean;
  visionAidsInfo?: string;
  refractiveSurgery: string | boolean;
  refractiveSurgeryInfo?: string;

  /** pregnancy */
  pregnancy: string | boolean;
  pregnancyInfo?: string;
  fertilityTreatment: string | boolean;
  fertilityTreatmentInfo?: string;
  midwife: string | boolean;
  midwifeInfo?: string;
  nonMedicalPregnancyServices: string | boolean;
  nonMedicalPregnancyServicesInfo?: string;

  /** hospital */
  boardAndRoom: string;
  privateHospitals: boolean | string;
  privateHospitalsInfo?: string;
  headPhysician: string | boolean;
  headPhysicianInfo?: string;

  /** abroad */
  euWideCover: string | boolean;
  euWideCoverInfo?: string | boolean;
  repatriation: boolean | string;
  worldwideCover: boolean;
  worldwideCoverInfo?: string;
};

export type PublicTariffWithEmployer = ContributionPrices & {
  employerContribution: number;
};

export const deductibleLabelsMapping: Record<TariffsWithPublic, string> = {
  NKSelectS: 'with €0 deductible',
  NKSelectL: 'with €0 deductible',
  NKSelectXL: 'with €0 deductible',
  Public: 'with €0 deductible',
  HiMedical: '10% deductible',
  HiMedicalPlus: '10% deductible',
};

export const deductibleInfoMapping: Record<Tariff, string> = {
  NKSelectS:
    'A deductible or co-pay is the amount you pay for health care services before your health insurance begins to pay.\n\n With a €0 deductible, you will only use the guaranteed cashback to pay for the medical expenses.',
  NKSelectL:
    'A deductible or co-pay is the amount you pay for health care services before your health insurance begins to pay.\n\n With a €0 deductible, you will only use the guaranteed cashback to pay for the medical expenses.',
  NKSelectXL:
    'A deductible or co-pay is the amount you pay for health care services before your health insurance begins to pay.\n\n With a €0 deductible, you will only use the guaranteed cashback to pay for the medical expenses.',
  HiMedical:
    'You’ll pay 10% of each bill out of pocket. Once you’ve paid €500 out of pocket per year, the deductible no longer applies. You’ll be covered as foreseen by your insurance plan.',
  HiMedicalPlus:
    'You’ll pay 10% of each bill out of pocket. Once you’ve paid €500 out of pocket per year, the deductible no longer applies. You’ll be covered as foreseen by your insurance plan.',
};
