import {
  ExpatDependentQuestionnaire,
  ExpatQuestionnaire,
} from 'features/expat/models';
import { noop } from 'lodash';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';
import { GenericCheckout } from 'SignupQuestionnaire/components/GenericCheckout';
import { PolicyDetailsConfiguration } from 'SignupQuestionnaire/components/GenericCheckout/utils';

const isDependentQuestionnaire = (
  qnr: Partial<ExpatQuestionnaire | ExpatDependentQuestionnaire>
): qnr is Partial<ExpatDependentQuestionnaire> => {
  return 'mainPolicyId' in qnr;
};

const getMainPolicyId = (
  questionnaireAnswers: Partial<
    ExpatQuestionnaire | ExpatDependentQuestionnaire
  >
) => {
  if (isDependentQuestionnaire(questionnaireAnswers)) {
    return questionnaireAnswers.mainPolicyId;
  }
  return undefined;
};

export const ReviewCheckout = ({
  questionnaireAnswers,
  routes,
}: CustomComponentProps<
  Partial<ExpatQuestionnaire> & Partial<ExpatDependentQuestionnaire>
>) => {
  const { t } = useSafeTranslation();

  const isDependent = isDependentQuestionnaire(questionnaireAnswers);
  const mainPolicyId = getMainPolicyId(questionnaireAnswers);

  const unionQuestionnaireAnswers: Partial<
    ExpatQuestionnaire & { isMainPolicyTheLegalGuardian: boolean }
  > = {
    ...questionnaireAnswers,
    quote: {
      ...questionnaireAnswers.quote?.selectedQuote,
      options: questionnaireAnswers.quote?.options ?? [],
    },
    quoteId: questionnaireAnswers.quote?.selectedQuote?.id,
    isMainPolicyTheLegalGuardian:
      questionnaireAnswers.isMainPolicyTheLegalGuardian,
  };

  const policyDetailsConfiguration: PolicyDetailsConfiguration = {
    policyDetails: [
      {
        title: t('expat.qnr.checkout.policyDetails.name', 'Name'),
        questionId: 'name',
      },
      {
        title: t(
          'expat.qnr.checkout.policyDetails.requestedStartDate',
          'Requested start date'
        ),
        questionId: 'startDate',
        additionalInfo: {
          title: t(
            'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'expat.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
            'Your coverage should begin on the selected date, but only after you have received the official confirmation.'
          ),
        },
      },
      {
        title: t('expat.qnr.checkout.policyDetails.plan', 'Plan'),
        questionId: 'planId',
      },
      {
        title: t(
          'expat.qnr.checkout.policyDetails.dateOfBirth',
          'Date of Birth'
        ),
        questionId: 'dateOfBirth',
      },
      ...(!isDependent
        ? [
            {
              title: t('expat.qnr.checkout.policyDetails.email', 'Email'),
              questionId: 'email',
            },
          ]
        : []),
    ],
    otherSections: [
      {
        title: t(
          'expat.qnr.checkout.policyDetails.subTitle',
          'Parent / Legal guardian'
        ),
        rows: [
          {
            title: t(
              'expat.qnr.checkout.policyDetails.legalGuardian.isMainPolicyHolder',
              'Legal guardian is main policy holder'
            ),
            questionId: 'isMainPolicyTheLegalGuardian',
          },
          {
            title: t('expat.qnr.checkout.policyDetails.name', 'Name'),
            questionId: 'parentGuardianName',
          },
          {
            title: t(
              'expat.qnr.checkout.policyDetails.dateOfBirth',
              'Date of Birth'
            ),
            questionId: 'parentGuardianDateOfBirth',
          },
          {
            title: t('expat.qnr.checkout.policyDetails.email', 'Email'),
            questionId: 'parentGuardianEmail',
          },
        ],
      },
    ],
  };

  return (
    <GenericCheckout
      mainPolicyId={mainPolicyId}
      changeStartDatePath={routes.startDate?.path}
      onSubmitValue={noop}
      setValidForSubmission={noop}
      featureName="questionnaire"
      question="reviewCheckout"
      questionnaireAnswers={unionQuestionnaireAnswers}
      type="EXPAT_V2"
      region="de"
      policyInfoKind={
        isDependent ? 'ExpatV2DependentPolicy' : 'ExpatV2MainPolicy'
      }
      hasCheckoutDocuments={true}
      redirectAddDependent={!isDependent ? 'addDependents' : true}
      policyDetailsConfiguration={policyDetailsConfiguration}
    />
  );
};
