import { Region, regions } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PolicyDetailsConfig } from 'features/paymentScreen/components/PolicyDetails/utils';
import { regionToNameMapping } from 'features/signInV2/models';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';
import { isAddress, isName } from 'shared/validators';
import { z } from 'zod';

dayjs.extend(customParseFormat);

const DATE_FORMAT = 'DD MMM YYYY';
type DataType =
  | 'CURRENCY'
  | 'YEARS'
  | 'ADDONS'
  | 'NEGATIVE_CURRENCY'
  | 'REGION';

export type PolicyDetailsData = {
  title: string;
  questionId: string;
  dataType?: DataType;
  additionalInfo?: { title: string; description: string };
  noBreak?: boolean;
};

export type PolicyDetailsConfiguration = {
  policyDetails: PolicyDetailsData[];
  otherSections?: {
    title: string;
    rows: PolicyDetailsData[];
  }[];
};

const isDate = (answer: unknown): answer is Date => {
  return (
    typeof answer === 'string' && dayjs(answer, 'YYYY-MM-DD', true).isValid()
  );
};

const isAddonsObject = (answer: unknown): answer is Record<string, number> => {
  if (!answer) return false;
  if (typeof answer !== 'object') return false;
  for (const value of Object.values(answer)) {
    if (!value) return false;
    if (typeof value !== 'number') return false;
  }
  return true;
};

const isOthersCoveredArray = (
  answer: unknown
): answer is {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}[] => {
  const OthersCoveredSchema = z.array(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      dateOfBirth: z.string(),
    })
  );

  const { success } = OthersCoveredSchema.safeParse(answer);
  if (!success) return false;
  return true;
};

const processPolicyDetailsQuestionValue = (
  answer: unknown,
  dataType?: DataType
) => {
  if (!answer) return '';

  if (isName(answer)) {
    return `${answer.firstName} ${answer.lastName}`;
  }

  if (dataType === 'ADDONS' && isAddonsObject(answer)) {
    const addons = Object.keys(answer).map((key) => {
      return `${capitalizeSingleWord(key)}: ${englishFormattedEuroCurrency(
        answer[key]
      )}`;
    });

    return addons.join(', ');
  }

  if (isDate(answer)) {
    return dayjs(answer).format(DATE_FORMAT);
  }

  if (Array.isArray(answer) && isOthersCoveredArray(answer)) {
    return answer.map(
      ({ firstName, lastName, dateOfBirth }) =>
        `${firstName} ${lastName} / ${dayjs(dateOfBirth).format(DATE_FORMAT)}`
    );
  }

  if (Array.isArray(answer) && answer.length !== 0) {
    return answer.map(capitalizeSingleWord).join(', ');
  }

  if (isAddress(answer)) {
    return [
      `${answer.street} ${answer.houseNumber},`,
      `${answer.postcode} ${answer.city}`,
    ];
  }

  if (typeof answer === 'number' && dataType === 'CURRENCY') {
    return formatCurrencyWithSpace(answer, 2);
  }

  if (typeof answer === 'number' && dataType === 'NEGATIVE_CURRENCY') {
    return `-${formatCurrencyWithSpace(answer, 2)}`;
  }

  if (typeof answer === 'string' && dataType === 'CURRENCY') {
    return formatCurrencyWithSpace(Number(answer), 2);
  }

  if (typeof answer === 'number' && dataType === 'YEARS') {
    return `${answer} years`;
  }

  if (
    typeof answer === 'string' &&
    dataType === 'REGION' &&
    regions.includes(answer as Region)
  ) {
    return regionToNameMapping[answer as Region];
  }

  if (typeof answer === 'string') {
    return capitalizeSingleWord(answer);
  }

  if (typeof answer === 'boolean') {
    return answer ? 'Yes' : 'No';
  }

  return answer ? String(answer) : '';
};

export const processPolicyDetailsData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaireAnswers: Record<string, any>,
  policyDetailsConfiguration: PolicyDetailsConfiguration
): PolicyDetailsConfig => {
  return {
    totalPrice: questionnaireAnswers.quote.price,
    policyDetails: policyDetailsConfiguration.policyDetails.map((config) => ({
      ...config,
      value: processPolicyDetailsQuestionValue(
        questionnaireAnswers[config.questionId] ??
          questionnaireAnswers.quote?.[config.questionId],
        config.dataType
      ),
    })),
    otherSections: policyDetailsConfiguration.otherSections?.map((config) => ({
      ...config,
      cells: config.rows.map((rowConfig) => ({
        ...rowConfig,
        value: processPolicyDetailsQuestionValue(
          questionnaireAnswers[rowConfig.questionId] ??
            questionnaireAnswers.quote?.[rowConfig.questionId],
          rowConfig.dataType
        ),
      })),
    })),
  };
};

export const getStartDate = (questionnaireAnswers: Record<string, unknown>) => {
  if (questionnaireAnswers && questionnaireAnswers.startDate) {
    return String(questionnaireAnswers.startDate);
  }

  if (questionnaireAnswers && questionnaireAnswers.quote) {
    const quote = Object(questionnaireAnswers.quote);
    if (quote.termStartDate) {
      return String(quote.termStartDate);
    }
  }
};
