import { InsuranceTypes } from 'models/insurances/types';
import endpoint from 'shared/api';

export const getPolicyRecommendations = async (
  insuranceType?: InsuranceTypes
) => {
  if (!insuranceType) {
    return undefined;
  }

  const { data: policies } = await endpoint.network.get<{
    recommendations: InsuranceTypes[];
  }>(`/me/policies/recommendations/${insuranceType}`);

  return policies.recommendations;
};
