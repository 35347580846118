import { insurance } from '@getpopsure/private-constants';
import routes from 'constants/routes';
import {
  bannerColorMapping,
  imageSrcSetTypeMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';

import { CategoryLookup, Section } from '../models/lookups';

export const travelHealthCategory: CategoryLookup = {
  shortTitle: 'Travel',
  title: 'Travel Insurance',
  excerpt: 'Health and trip cancellation coverage while traveling abroad',
  description:
    'Travel insurance covers medical expenses worldwide for an unlimited number of trips, each up to 56 days in duration. You can upgrade the coverage to include cancellations and luggage. It is visa-compliant and valid for travel to any country outside of Germany.',
  icon: imageTypeMapping.TRAVEL,
  iconSrcSet: imageSrcSetTypeMapping.TRAVEL,
  bannerColor: bannerColorMapping.TRAVEL_HEALTH_V1,
  enabled: false,
  landingPage: insurance.travelHealth.landingPage,
  quotePage: routes.policies.travelHealth.path,
};

export const travelHealthSection: Section = {
  title: '',
  description: 'travel insurance description',
  icon: '',
};
