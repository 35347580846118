import { trackBookACallClicked } from '@getpopsure/analytics';
import { Region } from '@getpopsure/public-models';
import { Button, illustrations, Link } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { Footer } from 'components/QuotePage/Quote.models';
import featherLogoIcon from 'icons/feather-icon.svg';
import { useSafeTranslation } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './Footer.module.scss';

interface QuoteFooterProps {
  onSubmit: () => void;
  price: number;
  links: Footer;
  featureName: string;
  region?: Region;
}

export const QuoteFooter = ({
  links,
  onSubmit,
  price,
  region,
  featureName,
}: QuoteFooterProps) => {
  const { t } = useSafeTranslation();

  const handleBookACall = () => {
    window.Calendly.initPopupWidget({
      url: links.bookACallUrl,
    });

    if (region) {
      trackBookACallClicked({
        user_id: null,
        insurance_type: featureName,
        region,
        medium: 'quote_screen',
      });
    }
  };

  return (
    <>
      <section className="py40 bg-primary-50 mb56">
        <div className="p-body d-flex fd-column ai-center">
          <img
            src={featherLogoIcon}
            alt="logo"
            className={classNames(styles.logoIcon, 'mx-auto')}
          />

          <div className="p--serif p-h1 ta-center mt24">
            {t('components.quote.footer.title', 'Have more questions?')}
          </div>

          <div className="p-p ta-center wmx8 mt24">
            {t(
              'components.quote.footer.description',
              'Reach out to us for more information or check out our website to get answers to frequently asked questions.'
            )}
          </div>

          <div className="mt24 wmn3 d-flex jc-between ai-center">
            <button
              type="button"
              className="p-a ds-interactive-component"
              onClick={handleBookACall}
              data-testid="book-call"
            >
              {t('components.quote.footer.bookCall', 'Book a call')}
            </button>

            <div className={styles.divider} />

            <Link
              href={links.secondaryActionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {links.secondaryAction}
            </Link>
          </div>
        </div>
      </section>

      <footer
        className={classNames(
          'w100 d-flex jc-center ai-center',
          styles.fixedPriceBar
        )}
      >
        <div
          className={classNames(
            'p-body jc-between ai-center ws12',
            styles.desktopSticky
          )}
        >
          <div className="d-flex ai-end gap8">
            <img src={illustrations.shield1} alt="" />
            <h3 className="p-h3">
              {t('components.quote.footer.plan', 'Your coverage')}
            </h3>
          </div>

          <div className="d-flex ai-center gap16">
            <p className="p--serif p-p--small tc-primary-500">
              <span className="p-h2 tc-primary-500">
                {englishFormattedEuroCurrency(price)}
              </span>
              {t('components.quote.stickyFooter.price.perMonth', '/mo')}
            </p>

            <div className={`d-flex ai-center gap16 ${styles.buttonContainer}`}>
              <Button
                onClick={onSubmit}
                type="button"
                className="ws3"
                data-testid="quote-continue"
                data-cy="quote-continue"
              >
                {t('components.quote.footer.continueButton', 'Continue')}
              </Button>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'p-body ai-center jc-between gap16 w100',
            styles.mobileSticky
          )}
        >
          <div className="d-flex ai-center gap16">
            <img src={illustrations.shield1} alt="" />
            <div>
              <p className="p--serif p-p--small tc-primary-500">
                <span className="p-h2 tc-primary-500">
                  {englishFormattedEuroCurrency(price)}
                </span>
                {t('components.quote.stickyFooter.price.perMonth', '/mo')}
              </p>
            </div>
          </div>

          <Button onClick={onSubmit} type="button" className="ws2">
            {t('components.quote.continueButton.label', 'Continue')}
          </Button>
        </div>
      </footer>
    </>
  );
};
