import { capitalizeName } from '@getpopsure/public-utility';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { getPoliciesListAction } from 'features/policiesDashboard/utils/getPoliciesListAction';
import { getPolicyStatus } from 'features/policiesDashboard/utils/getPolicyStatus';
import {
  bannerColorMapping,
  getTitleMapping,
} from 'models/insurances/types/mapping';
import { generatePath } from 'react-router';

import type { PolicySingleModal } from '../../components/modals';
import {
  CancelPolicyModal,
  DelinquencyBlockerCancelPolicyModal,
  DelinquencyBlockerCreateClaimModal,
} from '../../components/modals';
import { DATE_FORMAT } from '../../constants';
import {
  BillingType,
  GetPolicyConfigArgs,
  PolicyConfig,
  PolicySingleSection,
} from '../../PolicySingle.models';
import { getPolicyDocuments } from '../../sections/PolicyDocuments/utils/getPolicyDocuments';
import { getExpatEuButtons } from './getExpatEuButtons';
import { VideoDoctorAppointment } from './sections/VideoDoctorAppointment';

export const getExpatEuHealthConfig = (
  args: GetPolicyConfigArgs
): PolicyConfig => {
  const [policy, t] = args;
  const { policyNumber, insuredPerson, startDate, price, billingType, planId } =
    policy.attributes;

  const action = getPoliciesListAction(policy);
  const policyStatus =
    action && action !== 'DETAIL'
      ? 'ACTION_NEEDED'
      : getPolicyStatus(policy, true);
  const capitalizedName = insuredPerson?.name
    ? capitalizeName(insuredPerson.name)
    : '';
  const subtitle = capitalizedName || '';

  const header: PolicyConfig['header'] = {
    title: getTitleMapping(t).INCOMING_EU,
    subtitle,
    backgroundColor: bannerColorMapping.INCOMING_EU,
    insuranceType: policy.type,
    policyStatus,
    startDate,
  };

  if (price) {
    header.price = {
      amount: parseFloat(price),
      billingType: billingType as BillingType,
    };
  }

  const hasVideoDoctor = policy.attributes?.hasVideoDoctor;

  const isPremium = planId === 'PREMIUM';

  const sections: PolicySingleSection[] = [
    {
      id: 'POLICY_DETAILS',
      props: {
        policyType: policy.type,
        info: [
          {
            title: t('myPolicies.policyDetails.policyNumber', 'Policy number'),
            value: policyNumber,
          },
          {
            title: t(
              'myPolicies.policyDetails.insuredPerson',
              'Insured person'
            ),
            value: insuredPerson && capitalizeName(insuredPerson.name),
          },
          {
            title: t('myPolicies.policyDetails.startDate', 'Start date'),
            value: startDate && dayjs(startDate).format(DATE_FORMAT),
          },
          {
            title: t('myPolicies.policyDetails.expatEu.plan.title', 'Plan'),
            value: isPremium
              ? t('myPolicies.policyDetails.expatEu.plan.premium', 'Premium')
              : t('myPolicies.policyDetails.expatEu.plan.basic', 'Basic'),
          },
        ],
        buttons: getExpatEuButtons(policy),
      },
    },
    ...(hasVideoDoctor
      ? [
          {
            id: 'POLICY_GENERIC_SECTION' as const,
            component: VideoDoctorAppointment,
          },
        ]
      : []),
    {
      id: 'POLICY_COVERAGE',
      props: {
        entries: isPremium
          ? [
              {
                id: 'premium-doctor-visits',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.premiumDoctorVisits.title',
                  'Doctor visits & prescription drugs'
                ),
                covered: true,
              },
              {
                id: 'premium-dental-checkups',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.dentalCheckups.title',
                  'Dental check-ups & cleanings'
                ),
                covered: true,
              },
              {
                id: 'premium-pregnancy-delivery',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.pregnancyDelivery.title',
                  'Pregnancy exams and delivery'
                ),
                covered: true,
              },
              {
                id: 'premium-eu-coverage',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.euCoverage.title',
                  'Coverage across the EU & Schengen area'
                ),
                covered: true,
              },
              {
                id: 'premium-physical-therapy',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.physicalTherapy.title',
                  'Physical therapy'
                ),
                covered: true,
              },
              {
                id: 'premium-pre-existing',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.preExisting.title',
                  'Pre-existing conditions'
                ),
                covered: false,
              },
              {
                id: 'premium-routine-checkups',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.premiumRoutineCheckups.title',
                  'Routine medical check-ups'
                ),
                covered: false,
              },
              {
                id: 'premium-regular-vaccinations',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.regularVaccinations.title',
                  'Regular vaccinations'
                ),
                covered: false,
              },
            ]
          : [
              {
                id: 'basic-doctor-visits',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.doctorVisits.title',
                  'Doctor visits & hospitalizations'
                ),
                covered: true,
              },
              {
                id: 'basic-prescription-drugs',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.prescriptionDrugs.title',
                  'Prescription drugs'
                ),
                covered: true,
              },
              {
                id: 'basic-eu-coverage',
                title: t(
                  'myPolicies.policyDetails.expatEu.covered.euCoverage.title',
                  'Coverage across the EU & Schengen area'
                ),
                covered: true,
              },
              {
                id: 'basic-pre-existing',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.preExisting.title',
                  'Pre-existing conditions'
                ),
                covered: false,
              },
              {
                id: 'basic-routine-checkups',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.routineCheckups.title',
                  'Routine check-ups'
                ),
                covered: false,
              },
              {
                id: 'basic-regular-vaccinations',
                title: t(
                  'myPolicies.policyDetails.expatEu.notCovered.regularVaccinations.title',
                  'Regular vaccinations'
                ),
                covered: false,
              },
            ],
      },
    },
    {
      id: 'POLICY_CLAIMS',
      props: {
        claims: policy.claims,
        route: generatePath(routes.claims.expatEu.path, {
          policyId: policy.id,
        }),
        insuranceType: 'INCOMING_EU',
        activeUntil:
          policy.status === 'ACTIVE'
            ? undefined
            : policy.attributes.activeUntil,
        policyStatus: policy.status,
        screen: {
          button: {
            title: t(
              'myPolicies.policyDetails.claimsButton.expatEu.caption',
              'New claim or cost plan'
            ),
            dataCy: 'expat-eu-policy-detail-make-a-claim',
          },
        },
      },
    },
    {
      id: 'POLICY_DOCUMENTS',
      props: {
        documents: getPolicyDocuments(policy),
      },
    },
  ];

  const modals: PolicySingleModal[] = [
    {
      id: 'CANCEL_POLICY',
      component: CancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CANCEL_POLICY',
      component: DelinquencyBlockerCancelPolicyModal,
    },
    {
      id: 'DELINQUENT_BLOCKER_CREATE_CLAIM',
      component: DelinquencyBlockerCreateClaimModal,
    },
  ];

  return { header, modals, sections };
};
