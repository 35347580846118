import { faq } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { illustrations } from '@popsure/dirty-swan';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { IncomeProtection, IncomeProtectionGroupIds } from '../models';

const travelImg = imageTypeMapping.LIABILITY;

export const IncomeProtectionComponents = {} as const;

export type IncomeProtectionQuestionnaire = SignupQuestionnaireType<
  IncomeProtection,
  IncomeProtectionGroupIds,
  typeof IncomeProtectionComponents
>;

export const getQuestionnaire = (): IncomeProtectionQuestionnaire => {
  return [
    {
      id: 'quote',
      required: true,
      type: 'QUOTEPAGE',
      props: {
        config: {
          title: 'Here is your quote',
          headerImage: {
            src: travelImg,
            alt: 'Travel',
          },
          priceHeader: {
            title: '{{value}}',
            subTitle: 'per year',
          },
          actions: {
            items: [
              {
                type: 'Cards',
                items: [
                  {
                    title: 'Basic',
                    subTitle:
                      'Basic cover for 5 years and then upgrades into full cover after 5 years',
                    perText: 'per year',
                    price: 32,
                  },
                  {
                    title: 'Advanced',
                    subTitle: 'Full cover right away',
                    perText: 'per year',
                    price: 81,
                  },
                ],
              },
              {
                type: 'Toggle',
                mapValues: {
                  0: 'Basic',
                  1: 'Advanced',
                },
              },
            ],
            valuesMap: {
              '0': '32',
              '1': '81',
            },
            submitMap: [
              {
                id: '1',
                plan: 'BASIC',
                price: 32,
              },
              {
                id: '2',
                plan: 'ADVANCED',
                price: 81,
              },
            ],
            button: { text: 'Select' },
          },
          addons: [
            {
              id: 'increase-age',
              title: 'End age 67',
              description:
                'Increase the time the policy pays out from 65 to 67 years. The policy will also be active for 2 years longer',
              price: 10,
            },
            {
              id: 'reduced-waiting-time',
              title: 'Reduced income protection waiting time',
              description:
                'Reduce the time for income protection to kick in from 12 months to 6 months of being unable to work. Unless you can afford to have no income for a year, we recommend selecting this one.',
              price: 20,
            },
          ],
          coveredSection: {
            title: "What's included?",
            layout: {
              cards: true,
              compact: false,
            },
            items: [
              {
                title: 'Physical disabilities',
                description:
                  'Occupational disabilities that interrupt your work performance by more than 50% due to an illness, bodily injury or deterioration.',
                icon: {
                  src: illustrations.restrictions,
                  alt: 'restrictions',
                },
              },
              {
                title: 'Mental disabilities',
                description:
                  'Occupational disabilities that interrupt your work performance by more than 50% due to a mental or psychosomatic illness.',

                icon: { src: illustrations.mentalHealth, alt: 'mental health' },
              },
              {
                title: 'No abstract referral',
                description:
                  'We only check whether you can perform a similar job that corresponds to your skills, experience and previous salary.',
                icon: { src: illustrations.document1, alt: 'document' },
              },
              {
                title: 'Adjustable sum',
                description:
                  'Depending on your age and health, you’re able to raise your monthly payout sum with a few additional documents.',
                icon: { src: illustrations.calculating, alt: 'calculating' },
              },
              {
                title: 'Coverage pause',
                description:
                  "If you have been insured for more than 1 year, in the event of unemployment, you can pause your coverage until you're back on your feet.",
                icon: {
                  src: illustrations.location,
                  alt: 'location',
                },
              },
            ],
          },
          notCoveredSection: {
            title: 'What’s not covered?',
            items: [
              {
                title: 'Performance-based unemployment',
                description:
                  'If you get fired or quit your job and are physically and mentally fit, then insurance will not cover this. It covers you if you are _incapable of working to a certain extend_ and not because you wish to remain unemployed.',
                icon: {
                  src: illustrations.documentCross,
                  alt: 'document cross',
                },
              },
              {
                title: 'Short-term illnesses',
                description:
                  'Short-term illnesses like the flu or food poisoning that last about a week or a broken wrist that lasts 6 weeks are almost always covered under health insurance and your employer’s sick leave.',
                icon: {
                  src: illustrations.medicine,
                  alt: 'medicine',
                },
              },
              {
                title: 'Occupations that aren’t covered',
                description:
                  'Certain dangerous occupations aren’t covered, like working with hazardous substances, in crisis areas, at a height of more than 10 meters, in aviation, in diving, in mountain sports, in martial arts, in motor sports, and in other high-risk fields',
                icon: {
                  src: illustrations.radioactive,
                  alt: 'radio active',
                },
              },
              {
                title: 'Intentionally caused illnesses',
                description:
                  'Intentionally caused occupational disabilities are not covered (including self-harm and suicide). **Our mental health add-on** will cover these situations if it’s proven that these acts were committed in a state of pathological and mental disturbance.',
                icon: {
                  src: illustrations.mentalIllness,
                  alt: 'mental illness',
                },
              },
            ],
          },
          reviewBadge: true,
          ctaBox: {
            header: 'Income insurance',
            title: '{{value}}',
            subTitle: 'per year',
            buttonText: 'Get covered',
          },
          faq: {
            title: 'Frequently asked questions',
            data: [
              {
                questionId: 1,
                question:
                  'How much will disability insurance provide in compensation?',
                answer: `If you are eligible, insurance will cover up to a monthly payout sum up to ${englishFormattedEuroCurrency(
                  2000,
                  true
                )} or 70% your net salary at the time of application (whichever is lower) without additional paperwork. If you’d like a higher monthly payout sum, you’ll need to contact our support team, so we can help you through the extended application process.`,
              },
              {
                questionId: 2,
                question: 'How are benefits paid out?',
                answer:
                  'Once we’ve received confirmation that you have an occupational disability that is expected to impact your ability to work for at least 6 months, with more than 50% reduction in working capacity, we’ll send a monthly payout sum to the bank account of your choice.',
              },
              {
                questionId: 3,
                question: 'Am I eligible?',
                answer:
                  'If you’ve already been diagnosed with an occupational disability, then it’s unfortunately too late to sign up for insurance. If you have a pre-existing condition like diabetes or have been treated for depression, it still might be possible to sign up for disability insurance with a higher monthly premium or exclusions for disabilities related to these conditions.',
              },
              {
                questionId: 4,
                question: 'Can I raise my compensation limit at another time?',
                answer:
                  'Yes, you’re able to raise your monthly payout sum. Depending on your age and health, when you request the increase, we might just need you to answer a few questions, or we might also send you to get a check-up.',
              },
              {
                questionId: 5,
                question: 'What happens if I lose my job and am unable to pay?',
                answer:
                  'If you lose your job while on disability insurance and are not able to pay, we do offer relief plans provided that you have been insured for more than 1 year. Please contact us for more details to see if you qualify.',
              },
              {
                questionId: 6,
                question: 'Will I be denied disability insurance if I smoke?',
                answer:
                  'Nope, you won’t be denied for just smoking (you might be denied for smoking-related illnesses, though, or other pre-existing conditions).',
              },
              {
                questionId: 7,
                question: 'How can I claim?',
                answer:
                  'The first step will always be to reach out to Feather, and we can help you with the claims process. Keep in mind that you’ll only be able to claim disability insurance once we receive a medical confirmation that you have an occupational disability that is expected to impact your work performance for at least 6 months, with more than a 50% decrease in your working capacity.',
              },
              {
                questionId: 8,
                question:
                  'What is the difference between a concrete and abstract referral?',
                answer:
                  '**Concrete referral:** The insurance can only refer you to another occupation if it is a similar job that corresponds to your skills, experience, and previous salary with a comparative social standing.\n\nAn example of this would be someone  who works as a sales representative, due to a steadily worsening back condition, and is no longer able to drive for hours to visit customers. Their employer offers them a job in the office service at the same salary, which they accept.\n\n**Abstract referral:** If you can work in any capacity at all, it would be considered an “abstract referral.”\n\nDisability insurance will not force someone to find a new position in a different field, or if they find a similar position with a pay cut, this also won’t be counted as it is an abstract referral.',
              },
            ],
          },
          moreQuestionsSection: {
            showBookCallAction: false,
            title: 'Have more questions?',
            secondaryActionLink: faq.base,
            secondaryAction: 'Visit our FAQ',
          },
        },
      },
      screen: {
        question: '',
        layout: 'Standalone',
      },
      groupId: 'preQuote',
    },
  ];
};
