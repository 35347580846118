import { Button } from '@popsure/dirty-swan';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { Policy } from 'models/policies';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import styles from './FinalStep.module.scss';
import checkIcon from './icons/check.svg';

type FinalStepProps = {
  mainPolicyId: string;
};

export const getPolicy = async (policyId: string): Promise<Policy> => {
  const { data } = await endpoint.getPolicyDetail(policyId);
  return data;
};

export const FinalStep = ({ mainPolicyId }: FinalStepProps) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(
    100
  );

  const { data: policy } = useQuery<Policy, AxiosError>({
    queryKey: ['getPolicy'],
    queryFn: () => getPolicy(mainPolicyId),
    refetchInterval,
    onSuccess: () => setRefetchInterval(undefined),
  });

  const onContinue = () => {
    history.push(
      generatePath(routes.policies.expatEu.dependents.path, {
        mainPolicyId,
        localeId: policy?.region,
      })
    );
  };

  const onCancel = () => {
    history.push(
      paramsSetUrl(routes.me.policies.path, [
        { key: 'signupSuccess', value: 'expatEu' },
      ])
    );
  };

  if (!policy) {
    return <LoadingSpinner />;
  }

  return (
    <div
      className={`p-container d-flex fd-column ai-center jc-center wmx6 ${styles.container}`}
    >
      <img src={checkIcon} alt="check icon" />
      <h1 className="p-h1 mt16 mb8 ta-center">
        {t(
          'expatEu.qnr.finalStep.title',
          'Great, ready to cover someone else?'
        )}
      </h1>
      <div className="p-p ta-center">
        {t(
          'expatEu.qnr.finalStep.description',
          'You can also do it later in your Feather account.'
        )}
      </div>
      <div className="d-flex fd-column gap16 mt24 ai-center jc-center">
        <Button
          onClick={onContinue}
          className="wmn3"
          data-cy="final-step-add-policy"
        >
          {t('expatEu.qnr.finalStep.primaryButton.cta', 'Add another policy')}
        </Button>
        <Button
          onClick={onCancel}
          variant="filledWhite"
          className="wmn3"
          data-cy="final-step-done"
        >
          {t('expatEu.qnr.finalStep.secondaryButton.cta', "I'm done")}
        </Button>
      </div>
    </div>
  );
};
