import endpoint from 'shared/api';

export async function getPolicyStartDate(policyId: string) {
  const {
    data: {
      attributes: { startDate },
    },
  } = await endpoint.getPolicyDetail(policyId);

  return startDate ?? null;
}
