import { TFunction } from '@getpopsure/i18n-react';
import { MAX_COVERAGE_RECOMMENDATION_NUM } from 'features/policiesDashboard/constants';
import {
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { Policy } from 'models/policies';

import { isPolicyExpired } from '../isPolicyExpired';

export type AdditionalCoverageInsuranceTypes =
  | 'PRIVATE_HEALTH'
  | 'DENTAL'
  | 'LIABILITY'
  | 'LEGAL'
  | 'HOUSEHOLD'
  | 'BIKE'
  | 'TRAVEL'
  | 'LIFE'
  | 'DOG_LIABILITY'
  | 'PET_HEALTH'
  | 'PENSION'
  | 'DISABILITY'
  | 'BASIS_PENSION';

const getDescriptionTypes = (
  type: AdditionalCoverageInsuranceTypes,
  t: TFunction
) => {
  switch (type) {
    case 'PRIVATE_HEALTH':
      return t(
        'myPolicies.additionalCoverage.privateHealth.info',
        'Comprehensive coverage for those who want a little extra.'
      );
    case 'DENTAL':
      return t(
        'myPolicies.additionalCoverage.dental.info',
        'Keep smiling with coverage for cleanings, tooth replacements, tooth whitening, and other treatments.'
      );
    case 'HOUSEHOLD':
      return t(
        'myPolicies.additionalCoverage.household.info',
        'Protects the items on your property in case of damage.'
      );

    case 'LEGAL':
      return t(
        'myPolicies.additionalCoverage.legal.info',
        'Covers the cost of disputes over employment, contracts, and more.'
      );

    case 'LIABILITY':
      return t(
        'myPolicies.additionalCoverage.liability.info',
        'Protects you against the damage that you accidentally cause against another person or thing.'
      );
    case 'BIKE':
      return t(
        'myPolicies.additionalCoverage.bike.info',
        'Coverage for bikes in case of theft, damages, and more.'
      );
    case 'TRAVEL':
      return t(
        'myPolicies.additionalCoverage.travel.info',
        'Health and trip cancellation coverage while traveling abroad.'
      );
    case 'LIFE':
      return t(
        'myPolicies.additionalCoverage.life.info',
        'Support your loved ones in the event of your passing.'
      );
    case 'DOG_LIABILITY':
      return t(
        'myPolicies.additionalCoverage.dogLiability.info',
        'Covers damage caused by dogs to others or their belongings.'
      );
    case 'PET_HEALTH':
      return t(
        'myPolicies.additionalCoverage.petHealth.info',
        'Coverage for vet appointments and treatments for pets.'
      );
    case 'PENSION':
      return t(
        'myPolicies.additionalCoverage.pension.info',
        'Personal pension plan for a secured retirement.'
      );
    case 'DISABILITY':
      return t(
        'myPolicies.additionalCoverage.disability.info',
        "Replaces lost income if you're unable to work."
      );
    case 'BASIS_PENSION':
      return t(
        'myPolicies.additionalCoverage.pension.info',
        'Personal pension plan for a secured retirement.'
      );
    default:
      return '';
  }
};

export interface AdditionalCoverageItem {
  description?: string;
  href?: string;
  to?: string;
  image: string;
  title: string;
  type: AdditionalCoverageInsuranceTypes;
}

export const getInsuranceKeys = (
  policies: Policy[] = []
): AdditionalCoverageInsuranceTypes[] => {
  const policiesByTypes = policies.flatMap((policy) =>
    !isPolicyExpired(policy) ? [policy.type] : []
  );

  const hasNoExpatOrPrivatePolicy =
    !policiesByTypes.includes('INCOMING') &&
    !policiesByTypes.includes('PRIVATE_HEALTH');

  const activeExpatOrPrivatePolicies = policies.filter(
    ({ type, status }) =>
      (type === 'INCOMING' || type === 'PRIVATE_HEALTH') && status === 'ACTIVE'
  );

  const additionalCoverage: AdditionalCoverageInsuranceTypes[] = [];

  if (!policiesByTypes.includes('LIABILITY')) {
    additionalCoverage.push('LIABILITY');
  }

  if (
    !policiesByTypes.includes('DENTAL') &&
    (hasNoExpatOrPrivatePolicy || activeExpatOrPrivatePolicies.length === 0)
  ) {
    additionalCoverage.push('DENTAL');
  }

  if (!policiesByTypes.includes('LEGAL')) {
    additionalCoverage.push('LEGAL');
  }

  if (!policiesByTypes.includes('HOUSEHOLD')) {
    additionalCoverage.push('HOUSEHOLD');
  }

  return additionalCoverage;
};

export type AdditionalCoverageList = Pick<AdditionalCoverageItem, 'type'>[];

export const getAdditionalCoverage = (
  policies: Policy[] = [],
  t: TFunction
): AdditionalCoverageItem[] =>
  getInsuranceKeys(policies).map((type) => ({
    description: getDescriptionTypes(type, t),
    image: imageTypeMapping?.[type] || '',
    title: getTitleMapping(t)?.[type],
    type,
  }));

export const getAdditionalCoverageMetadata = (
  additionalCoverageItems: AdditionalCoverageList = [],
  t: TFunction
): AdditionalCoverageItem[] =>
  additionalCoverageItems
    .slice(0, MAX_COVERAGE_RECOMMENDATION_NUM)
    .map(({ type }) => ({
      description: getDescriptionTypes(type, t),
      image: imageTypeMapping?.[type] || '',
      title: getTitleMapping(t)?.[type],
      type,
    }));
