import { changeLanguage, language, Locale } from '@getpopsure/i18n-react';
import { Region, regions } from '@getpopsure/public-models';
import { WorldGlobeEarthIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  setTranslationSource,
  setUntranslatedFallback,
} from 'features/i18n/actions';
import type { FallbackOption, TranslationSource } from 'features/i18n/reducers';
import { selectI18nOptions } from 'features/i18n/selectors';
import { OVERRIDE_REGION_COOKIE_KEY, useRegion } from 'hooks/useRegion';
import Cookies from 'js-cookie';
import { cookieDomainOptions } from 'locales';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocales } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import styles from './DebugLanguageWidget.module.scss';

const localeMapping: Record<Locale | 'xx' | 'cimode', string> = {
  en: 'EN',
  de: 'DE',
  es: 'ES',
  fr: 'FR',
  xx: 'XX',
  cimode: 'KEY',
};

const isValidRegion = (region: string): region is Region =>
  regions.includes(region as Region);

interface DebugLanguageWidgetProps {
  className?: string;
}

const setInContextEditorStatus = (enabled: boolean) => {
  if (enabled) {
    Cookies.set('enableInContextEditor', 'true');
  } else {
    Cookies.remove('enableInContextEditor');
  }
};

export const DebugLanguageWidget = ({
  className,
}: DebugLanguageWidgetProps) => {
  const { allowedLocales, hasNonEnglishLocale } = useLocales();
  const { region } = useRegion();

  const dispatch = useDispatch();
  const { translationSource = 'CODE', fallbackOption = 'PLACEHOLDER' } =
    useSelector(selectI18nOptions);

  const [selectedItem, setSelectedItem] = useState<string>('');
  const editorIsEnabled = !!Cookies.get('enableInContextEditor');
  const overrideRegion = Cookies.get(OVERRIDE_REGION_COOKIE_KEY);

  const shownLocales = [
    ...allowedLocales,
    ...(isStagingOrDev ? ['xx', 'cimode'] : []),
  ] as Locale[];

  const [show, setShow] = useState(false);

  /**
   * Wrap the language() function inside a useEffect to allow cleaning up
   * when the component returns null
   */
  useEffect(() => {
    if (!editorIsEnabled) {
      setSelectedItem(language());
    } else {
      setSelectedItem('EDITOR');
    }
    return () => {
      setSelectedItem('');
    };
  }, [editorIsEnabled]);

  const handleChangeLanguage = (item: Locale | 'EDITOR') => () => {
    if (item === 'EDITOR') {
      if (!editorIsEnabled) {
        // Set language to 'en' before switching to editor to prevent bugs
        changeLanguage('en', { ...cookieDomainOptions });

        setInContextEditorStatus(true);
        window.location.reload();
      }
    } else {
      changeLanguage(item, { ...cookieDomainOptions });

      if (editorIsEnabled) {
        setInContextEditorStatus(false);
        window.location.reload();
      }
    }
    setSelectedItem(item);
  };

  const handleChangeRegion = (e: ChangeEvent<HTMLSelectElement>) => {
    const newRegion: string = e.target.value;

    if (!isValidRegion(newRegion)) {
      throw new Error(
        `[DEBUG_LANGUAGE_WIDGET]: trying to change to an invalid region ${newRegion}`
      );
    }

    Cookies.set(OVERRIDE_REGION_COOKIE_KEY, newRegion);

    window.location.reload();
  };

  const handleSourceChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTranslationSource(e.target.value as TranslationSource));
    // Force rerender of translated components by setting language to currently selected language
    changeLanguage(language() as Locale, {
      ...cookieDomainOptions,
    });
    const isOtaEnabled = Cookies.get('otaEnabled') === 'true';

    if (e.target.value === 'OTA') {
      Cookies.set('otaEnabled', 'true');
      window.location.reload();
    }

    if (e.target.value !== 'OTA' && isOtaEnabled) {
      Cookies.remove('otaEnabled');
      window.location.reload();
    }
  };

  const handleFallbackChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setUntranslatedFallback(e.target.value as FallbackOption));
    // Force rerender of translated components by setting language to currently selected language
    changeLanguage(language() as Locale, { ...cookieDomainOptions });
  };

  const clearRegionOverride = () => {
    Cookies.remove(OVERRIDE_REGION_COOKIE_KEY);
    window.location.reload();
  };

  if (!isStagingOrDev) {
    return null;
  }

  return (
    <div className="p-container d-flex jc-end bg-red-300">
      <div
        className={classNames(
          `d-flex fd-column bg-white opacity  wmn3 ${styles.container} ${
            className ?? ''
          }`,
          { p16: show, p8: !show }
        )}
      >
        <button
          className={classNames(
            'p-p--small c-pointer d-flex ai-center bg-white pb8',
            styles.toggleButton
          )}
          type="button"
          onClick={() => setShow(!show)}
        >
          <WorldGlobeEarthIcon />
          Locale {language()}-{region.toUpperCase()}
        </button>
        {show && hasNonEnglishLocale && (
          <div className="mb16">
            <h1 className="p-h4 mb8 tc-grey-900">Language</h1>
            {shownLocales.map((locale, index) => (
              <Fragment key={locale}>
                <button
                  className={`ds-interactive-component ${
                    styles[
                      language() === locale ? 'selectorToggled' : 'selector'
                    ]
                  }`}
                  onClick={handleChangeLanguage(locale)}
                  type="button"
                >
                  {localeMapping[locale]}
                </button>
                {index < Object.entries(shownLocales).length - 1 ? (
                  <span className={styles.spacer}>/</span>
                ) : (
                  ''
                )}
              </Fragment>
            ))}
            {isStagingOrDev && (
              <>
                <span className={styles.spacer}>/</span>
                <button
                  className={`ds-interactive-component ${
                    styles[
                      selectedItem === 'EDITOR' ? 'selectorToggled' : 'selector'
                    ]
                  }`}
                  onClick={handleChangeLanguage('EDITOR')}
                  type="button"
                >
                  EDIT
                </button>
              </>
            )}
            <div className="tc-grey-500 mt8">
              <span>Language source:</span>
              <div className="d-flex gap8 mt8">
                <label htmlFor="choice-code">
                  <input
                    name="language-source"
                    id="choice-code"
                    type="radio"
                    value="CODE"
                    className="mr8"
                    checked={translationSource === 'CODE'}
                    onChange={handleSourceChange}
                    disabled={editorIsEnabled}
                  />
                  Code
                </label>
                <label htmlFor="choice-phrase">
                  <input
                    name="language-source"
                    id="choice-phrase"
                    type="radio"
                    value="PHRASE"
                    className="mr8"
                    checked={translationSource === 'PHRASE'}
                    onChange={handleSourceChange}
                    disabled={editorIsEnabled}
                  />
                  Phrase
                </label>
                <label htmlFor="choice-ota">
                  <input
                    name="language-source"
                    id="choice-ota"
                    type="radio"
                    value="OTA"
                    className="mr8"
                    checked={translationSource === 'OTA'}
                    onChange={handleSourceChange}
                    disabled={editorIsEnabled}
                  />
                  OTA
                </label>
              </div>
            </div>
            {translationSource !== 'OTA' && (
              <div className="tc-grey-500 mt16">
                <span>Language fallback:</span>
                <div className="d-flex gap8 mt8">
                  <label htmlFor="choice-english">
                    <input
                      id="choice-english"
                      type="radio"
                      value="ENGLISH"
                      className="mr8"
                      checked={fallbackOption === 'ENGLISH'}
                      onChange={handleFallbackChange}
                      disabled={editorIsEnabled}
                    />
                    English
                  </label>
                  <label htmlFor="choice-placeholder">
                    <input
                      id="choice-placeholder"
                      type="radio"
                      value="PLACEHOLDER"
                      className="mr8"
                      checked={fallbackOption === 'PLACEHOLDER'}
                      onChange={handleFallbackChange}
                      disabled={editorIsEnabled}
                    />
                    Placeholder
                  </label>
                  <label htmlFor="choice-key">
                    <input
                      id="choice-key"
                      type="radio"
                      value="KEY"
                      className="mr8"
                      checked={fallbackOption === 'KEY'}
                      onChange={handleFallbackChange}
                      disabled={editorIsEnabled}
                    />
                    Key
                  </label>
                </div>
              </div>
            )}
          </div>
        )}

        {show && (
          <div className="d-flex ai-center mb8 pl8">
            <h1 className="p-h4 grey-900 mr16">Selected region</h1>
            <select
              name="regions"
              onChange={handleChangeRegion}
              value={region}
              className="p-p tc-grey-900 fw-bold"
            >
              {regions.map((validRegion) => (
                <option key={validRegion} value={validRegion}>
                  {validRegion.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}

        {overrideRegion && (
          <button className="p-btn" type="button" onClick={clearRegionOverride}>
            Reset region
          </button>
        )}
      </div>
    </div>
  );
};
