import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { useSafeTranslation } from 'shared/i18n';
import { Processing as ProcessingView } from 'shared/pages/Processing';
import { ProcessingProps } from 'SignupQuestionnaire/models';

import { RequestProcessing } from './RequestProcessing/RequestProcessing';

export const Processing = <
  Questionnaire extends QuestionnaireQuestions,
  TResponseData
>({
  onSubmitValue,
  textList,
  description,
  requestFn,
  ...more
}: ProcessingProps<TResponseData> & QuestionnaireFormProps<Questionnaire>) => {
  const { t } = useSafeTranslation();

  if (requestFn) {
    return (
      <RequestProcessing
        onSubmitValue={onSubmitValue}
        requestFn={requestFn}
        textList={textList}
        {...more}
      />
    );
  }

  const list: string[] = textList ?? [
    t('generic.qnr.processing.loadingText', 'Processing claim...'),
  ];

  return (
    <ProcessingView
      onLoadComplete={onSubmitValue}
      textList={list}
      description={description}
    />
  );
};
